<app-header></app-header>
<app-lockers-menu [id]="id"></app-lockers-menu>
<div #myDiv [appViewController]="{deliveryPoints:'showDowntime'}">
  <br>
  <div class="d-flex justify-content-center align-items-center p-3">
     <div>{{'Downtime - Last 30 days '|translate}}</div>
  </div>
  <div class="container">
    <div class="col-12">
      <div class="table-responsive">
        <table datatable title="" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
          class="table table-sm table-bordered table-hover row-border hover text-center ">
          <caption></caption>
          <div *ngIf="!displayArr" class="text-center">
            <div class="text-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">{{'Loading...'|translate}}</span>
              </div>
            </div>
          </div>
          <thead *ngIf="displayArr" class="table-dark">
            <tr>
              <th scope="col">{{'Start Downtime'|translate}}</th>
              <th scope="col">{{'End Downtime'|translate}}</th>
              <th scope="col">{{'Duration'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of displayArr;let i=index">
              <td>{{item.startDown}}</td>
              <td>{{item.endDown}}</td>
              <td>{{item.duration}}</td>
            </tr>
          </tbody>
          <tbody *ngIf="displayArr?.length == 0">
            <tr>
              <td colspan="7" class="no-data-available">{{'No Record Found!'|translate}}</td>
            </tr>
          </tbody>
        </table>
        <br>
      </div>
    </div>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>