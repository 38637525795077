<div class="mx-5 mt-5">
    <h2   class="text-danger">{{'Customers'| translate}}</h2>
    <form [formGroup]="uploadForm" (ngSubmit)="onUpload()">
    <div class="mt-5 d-flex flex-row align-items-baseline">
    <label>{{'Customer import Choose an excel file and click on import'| translate}}</label>

      <input type="file" name="choosefile" class="d-none" id="choosefile" formControlName="file"
      [(ngModel)]="selectedFilePath" (change)="onFileChange($event)" accept=".csv,.xlsx" (click)="onInputClick($event)"/>
      <label for="choosefile" class="btn btn-success ms-3">{{'Choose file'| translate}}</label>

      <button type="submit" class="btn btn-danger ms-3" [disabled]="fileError || !uploadForm.value.file || invalid" >{{'Upload'| translate}}</button>
      </div>

    </form>
    <small *ngIf="invalid && errorTxt === false && fileSizeExceeded == false"
    class="form-text text-danger">{{'Please select a valid file type'|translate}}</small>

    <small *ngIf="uploadForm.get('file').hasError('required') && uploadForm.get('file').touched"
    class="form-text text-danger">{{'Please select file'|translate}}</small>


    <small *ngIf="invalid && errorTxt === false && fileSizeExceeded == true"
    class="form-text text-danger">{{'File size Exceeded! Please select another
    file'|translate}}</small>

    <small *ngIf="uploadForm.get('file').value" class="text-danger">{{this.fileName}}</small>
    <div *ngIf="fileError?.length > 0">
      <div>
        <p class="text-danger">
          {{'File upload failed! Please resolve mentioned issues and upload again.' | translate}}
        </p>
        <p  class="text-danger" >{{this.fileError.join(' AND ') | translate}}</p>
      </div>
    </div>

    <div class="mt-5">
      <div class="table-responsive">
        <table class="table table-bordered">
          <caption></caption>
            <thead>
                <tr>
                    <th scope="" colspan="8">{{'Customers'| translate}}</th>
                </tr>
            </thead>
            <thead>
                <tr class="table-info">
                    <th scope="">{{'Client Number'| translate}}</th>
                    <th scope="">{{'Email'| translate}}</th>
                    <th scope="">{{'First name'| translate}}</th>
                    <th scope="">{{'Last name'| translate}}</th>
                    <th scope="">{{'Mobile number'| translate}}</th>
                    <th scope="">{{'Notification'| translate}}</th>
                    <th scope="">{{'Language'| translate}}</th>
                    <th scope="">{{'Active'| translate}}</th>

                    </tr>
            </thead>
            <tbody *ngIf="(customerDetails && customerDetails.length) else noCustomer">
              <tr *ngFor = "let item of customerDetails">
                <td>{{displayValue(item.clientNumber)}}</td>

                <td>{{displayValue(item.Email)}}</td>

                <td>{{displayValue(item.firstName)}}</td>

                <td>{{displayValue(item.lastName)}}</td>
                
                <td>{{displayValue(item.phoneNumber)}}</td>
                
                <td>{{displayValue(item.Notification)}}</td>
               
                <td>{{displayValue(item.Language)}}</td>
                
                <td>{{displayValue(item.active)}}</td>
               </tr>
          </tbody>
          <ng-template #noCustomer>
            <tr *ngIf = "!orderFound">
              <td colspan="8" class="text-center">{{'No customers were found.'|translate}}</td>
          </tr>
          </ng-template>
            </table>
          </div>
        </div>

</div>



