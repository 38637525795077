<app-header></app-header>
<app-lockers-menu [id]="id" ></app-lockers-menu>
<div class="container" class="container mat-card mb-6 mt-4 shadow bg-white rounded">
  <div class="row">
    <div class="col-12">
        <p class="h4 mt-2 text-center">{{'Download Camera Images'|translate}}</p>
    </div>
      <form [formGroup]="cameraOperationForm" >
        <div class="row justify-content-center">
          <div class="form-group col-3 px-0 date-picker" >
            <label for="dateFrom">{{'Date From' | translate}}</label>
            <div class="row mt-1">
              <div class="input-group p-0">
                <input
                  type="text"
                  placeholder="dd-mm-yyyy"
                  class="form-control"
                  formControlName="dateFrom"
                  [maxDate]="maxDate"
                  #dateFromInput
                  bsDatepicker
                  [bsConfig]="{ isAnimated: true, showWeekNumbers: false, dateInputFormat: 'DD-MM-YYYY' }">
                    <button class="btn btn-outline-secondary">
                      <i class="fas fa-calendar-alt" (click)="openDatePicker('dateFromInput')"></i>
                    </button>
              </div>
            </div>
          </div>
            <div class="col-3 text-left px-5 my-4">
                <button class="btn btn-outline-danger mb-3 me-3" id="downloadData" type="submit" (click)="onSubmit()">{{'Download'| translate}}</button>
                <button class="btn btn-outline-danger mb-3" (click)="onReset()">{{'Reset'| translate}}</button>
          </div>
        </div>
      </form>
    </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-12 mt-3">
      <div class="table-responsive" id="cameraTable">
        <table
          datatable
          [dtOptions]="dtOptions"
          class="table table-sm mb-3 table-bordered table-hover row-border hover text-center"
          aria-describedby="cameraTable">
          <thead class="table-dark">
            <!-- added this line to solve sonar bug  -->
            <tr *ngIf="false">
              <th></th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
</div>
