import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from './Service/authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private readonly _authorizationService: AuthorizationService,
    private readonly router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const t = window.localStorage.getItem('token');
    let flag = false;
    if (t) {
      flag = true;
    }

    if (this._authorizationService.isLoggedIn || flag) {
      let getUserRole: any;
      const user = this._authorizationService.currentUser;
      if (user) {
        getUserRole = user.groups.map(element => element.name)
      } else {
        this._authorizationService.loadDdsUser()
      }

      if (getUserRole && getUserRole.includes("partnertoolgroup") && getUserRole.length === 1) {
        this.router.navigate(['user/forbidden'])
        return false
      }

      return true;
    }
    this.router.navigate([''])
    return false;
  }
}
