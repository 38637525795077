<img src="/assets/img/bg2.jpg" class="bg2" alt="background image">
<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mx-auto">
  <div class="card shadow p-3 mb-5 bg-white rounded mt-2">
      <div class="card-body p-3">
        
        <form  [formGroup]="barcodeForm">
          <p class="text-center h4 mt-3">{{'Add Barcode' | translate}}</p>
          <div class="container">
            <div class="form-group mt-2">
              <label class="mb-2">{{'Barcode' | translate}}</label>
              <input type="barcode" class="form-control"  [placeholder]="'Enter Barcode' | translate" formControlName="barcode">
              <small class="form-text text-muted" *ngIf="(formValidators.barcode.errors?.pattern||formValidators.barcode.errors?.required) && submitted">{{'Please fill Input field with format.' | translate}}</small>
            </div>
          </div>
          <div class="center-container">
            <button type="submit" class="btn btn-outline-danger mt-3" id="submitBtn" (click)="submit()">{{'Submit' | translate}}</button>
          </div>
          </form></div>
