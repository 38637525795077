import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from 'src/app/Service/authorization.service';
import { UserService } from 'src/app/Service/user.service';

@Component({
  selector: 'app-partner-tools',
  templateUrl: './partner-tools.component.html',
  styleUrls: ['./partner-tools.component.scss']
})
export class PartnerToolsComponent implements OnInit {
  allowCustomers: boolean;

  constructor(

    private readonly _authorizationService: AuthorizationService,
    private readonly _userService: UserService
  ) { }

  async ngOnInit() {
  
    this._userService.me().subscribe((res)=>{
      this._userService.partnerData$.next(res);
      this.allowCustomers = res['partner']['allowOwnCustomers'];
    });
  }

}
