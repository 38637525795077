import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../Service/partner.service';
import { AppService } from '../Service/app.service';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from '../Service/spinner.service';
import { LangtransService } from '../Service/langtrans.service';
import { AuthorizationService } from '../Service/authorization.service';
@Component({
  selector: 'app-maintenance-staff',
  templateUrl: './maintenance-staff.component.html',
  styleUrls: ['./maintenance-staff.component.scss']
})
export class MaintenanceStaffComponent implements OnInit {
  maintenance = true;
  addUserForm: FormGroup;
  userService: any;
  submitted: boolean;
  list: { name: string; checked: boolean; }[];
  languageList = [
    {value:'en',label:'english'},
    {value:'fr',label:'french'},
    {value:'nl',label:'dutch'}
  ];
  id: any;
  languagechar: any;
  header: string;
  editPartnerObj=null;
  showPasswordOnPress:boolean;
  partnerList: any;
  loginIdValidateError = false;
  loginIdCopabilityMatched: any;
  showMissmatchError = false;
  userInfo: any;

  constructor(private readonly _fb: FormBuilder,
    private readonly _router: Router,
    private readonly _partnerService: PartnerService,
    private readonly _route: ActivatedRoute,
    private readonly _appService: AppService,
    private readonly _toastr: ToastrService,
    private readonly _spinner: SpinnerService,
    private readonly _langtransService: LangtransService,
    private readonly _authorizationService: AuthorizationService
  ) {
    this.list = [
      { name: 'CAN_SWEEP', checked: false },
      { name: 'CAN_CLEAN', checked: false }
    ];
  }
  ngOnInit(): void {
    this.addUserForm = this._fb.group({
      name: ['', Validators.required],
      loginId: ['', Validators.required],
      capabilities: ['', Validators.required],
      language: ['', Validators.required],
      password: ['', [Validators.pattern(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/),
        Validators.required,Validators.minLength(8)]],
    });

    this._route.params.subscribe((data) => {
      this.id = data?.id;
      if (this.id) {
        this.header = 'Edit Maintenance User';
        this.editDataFetch(this.id);
      } else {
        this.header = 'Add Maintenance User';
      }
    });

    this._appService.languagetoggle$.subscribe((data: any) => {
      this.languagechar = data;
    });
  }
  get formValidators() {
    return this.addUserForm.controls;
  }
  editDataFetch(id){
    this._partnerService.fetchMaintenanceUserById(id).subscribe((res:any) => {
      this.addUserForm.setValue({
        name: res.name,
        loginId: res.loginId,
        capabilities: res.capabilities,
        language: res.language,
        password: res.password
      });
    });
  }
  submit() {
    this.submitted = true;
    if (!this.addUserForm.valid) {
      return
    }

    this._authorizationService.currentDdsUser.subscribe(user => {
      this.userInfo = user;
      const loginID = this.addUserForm.controls.loginId.value.toUpperCase();
      if (loginID.endsWith('SWEEP') || loginID.endsWith('CLEAN')) {
        this.loginIdValidateError = false;
        // checking in loginId ends with SWEEP and capabilities=CAN_SWEEP or 
        //  loginId ends with CLEAN and capabilities=CAN_CLEAN
        if (loginID.endsWith('SWEEP') && this.addUserForm.controls.capabilities.value.includes('CAN_SWEEP')) {
          this.loginIdCopabilityMatched = true;
        }
        else if (loginID.endsWith('CLEAN') && this.addUserForm.controls.capabilities.value.includes('CAN_CLEAN')) {
          this.loginIdCopabilityMatched = true;
        }
        else {
          this.loginIdCopabilityMatched = false;
          this.showMissmatchError = true;
        }
      } else {
        this.loginIdValidateError = true;
      }
      //if loginId and capabilities are Matched then adding details.
      if (this.loginIdCopabilityMatched) {

        const obj = {
          name: this.addUserForm.controls.name.value,
          loginId: this.addUserForm.controls.loginId.value.toUpperCase(),
          capabilities: this.addUserForm.controls.capabilities.value,
          password: this.addUserForm.controls.password.value.toUpperCase(),
          language: this.addUserForm.controls.language.value.toLowerCase(),
          id: this.id || '',
          createdBy: this.userInfo.uid
        };

        this._spinner.show();
        this._partnerService.createMaintenanceUser(obj).subscribe({next:(res: any) => {
          this._spinner.hide();
          if (res.code) {
            this.feedback('info', 'Userid already taken !!');
            return;
          }

          if(this.id){
            this.feedback('success', 'UserAddedSuccessfully');
          }else{
            this.feedback('success', 'UserEditedSuccessfully');
          }

          this._router.navigate(['/maintenanceUser']);
        }, error:(err: any) => {
          this._spinner.hide();
          this.feedback('info', 'Something bad happened, please try again later.');
        }
      });
      }
    });  
  }

  feedback(style, key) {
    this._langtransService.TranslateData(this.languagechar, key).subscribe(
      res => {
        if (style === 'info') {
          this._toastr.info(res || key);
        } else if (style === 'error') {
          this._toastr.error(res || key);
        } else {
          this._toastr.success(res || key);
        }
      }
    );
   }
  cancel = () => {
    this._router.navigate(['maintenanceUser']);
  };

}
