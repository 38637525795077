<div class="mx-5 mt-5" [appViewController]="{partnerTool:'viewPartnerTool'}">
  <h2 class="text-danger">{{'Orders Places in Vault'|translate}}</h2>

  <div class="mt-5">
    <div class="table-responsive">
      <table class="table table-bordered">
        <caption></caption>
        <thead>
          <tr>
            <th scope="" colspan="6">{{'Overview of Orders Placed in Vault'|translate}}</th>

          </tr>
        </thead>
        <thead>
          <tr class="table-info">
            <th scope="col">{{'Name'|translate}}</th>
            <th scope="col">{{'Barcode'|translate}}</th>
            <th scope="col">{{'Status'|translate}}</th>
            <th scope="col">{{'whenToPickUp'|translate}}</th>
            <th scope="col">{{'Time filled'|translate}}</th>

            <th scope="col">{{'Options'|translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let value of vaultResponse; let i = index">
           
            <td>
              <div class="d-flex">
                {{value.customers | fullName | translate }},
                <span class="ml-2">
                  {{value.customers?.email ||'-'}}
                </span>
              </div>
            </td>
            <td>{{value.barcode}}</td>
            <td>{{'Parcel delivered to the safe'|translate}}</td>
            <td>{{value.reservePeriodFrom | date:'dd-MM-yyyy'}} - {{value.reservePeriodTo | date:'dd-MM-yyyy'}}</td>
            <td>{{value.loadedAt}}</td>

            <td><button class="btn btn-danger" (click)="viewPage(value)">{{'View'|translate}}</button></td>

            <!-- api here -->

          </tr>
          <tr *ngIf="!orderFound">
            <td class="text-center" colspan="6">
              {{'No Records Found'|translate}}
            </td>
          </tr>


        </tbody>
      </table>
    </div>


  </div>

</div>