import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ddsEnvironment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { promise } from 'protractor';
@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(
    private readonly _http: HttpClient
  ) { }

  fetchLogs(id: any,queryString?: any): Observable <any>  {
    Object.keys(queryString).forEach(key => queryString[key] === undefined ? 
      delete queryString[key] : {});
    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/logs`, {params: queryString,observe: 'response'});
  }
  filterLogs(id: any, queryString?: any) {
    Object.keys(queryString).forEach(key => queryString[key] === undefined ? 
      delete queryString[key] : {});
    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/logs`, {
        params: queryString
      });
  }
  filterLogsWithPagination(id: any, queryString?: any) : Observable <any>{
    Object.keys(queryString).forEach(key => queryString[key] === undefined ? 
      delete queryString[key] : {});
    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/logs`, {
        params: queryString,
         observe: 'response'
      });
  }
  lockerDown(id: any, body: any) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/logs`, body);
  }
}
