 <app-header></app-header>
<app-lockers-menu [id]="id"></app-lockers-menu>
<div #myDiv [appViewController]="{deliveryPoints:'showTerminals'}">
  <div class="box collapse" id="collapseLayout" [appViewController]="{deliveryPoints:'displayLayout'}">
    <div class="container-fluid px-2">
      <div class="row d-flex justify-content-center">
        <div class="col-12">
          <app-lockers-layout
            *ngIf="dpdetails?.type == 'Lean Locker' &&  dpdetails?.terminals"
            [terminals]="nestedArr">
          </app-lockers-layout>
        </div>
      </div>
    </div>
  </div>
  <div [appViewController]="{deliveryPoints:'displayLayout'}" *ngIf="dpdetails && dpdetails.type !='Cubee Locker'" class="bg-info">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <div class="details btn center mx-auto text-light" type="button" data-toggle="collapse"
          data-target="#collapseLayout" aria-expanded="false" aria-controls="collapseLayout">
          <span *ngIf="!layoutAvailable">
            {{'Layout not available'|translate}}</span>
          <span *ngIf="layoutAvailable">
            {{'Show layout'|translate}}</span>
          <div class="fa fa-chevron-down fa-lg text-white"></div>
        </div>
      </div>
    </nav>
  </div>
 
  <div *ngIf="dpdetails" class="container-fluid mt-3 px-5">
    <div class="row mx-0">
      <div class="col-12">
        <div class="row mb-3">
          <div class="col-md-4">
            <div class="h4">{{dpdetails?.lockerName}} </div>
          </div>
          <div class="col-md-8 col-sm-4 d-flex justify-content-end">
            <div class="h4">
              <button class="btn btn-danger" (click)="reloadCurrentPage()">{{'Reload'|translate}}</button>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <div _ngcontent-wai-c50="" class="head-name">{{dpdetails?.provider}} </div>
            <div _ngcontent-wai-c50="" class="head-name">{{dpdetails?.type}}</div>
            <div _ngcontent-wai-c50="" class="head-name">
              <label class="mb-0" >Address:</label>
              {{dpdetails?.address.streetNumber}} {{dpdetails?.address.street}} {{dpdetails?.address.municipality}}
              {{dpdetails?.address.postalCode}}
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="text-center col">
                <div class="stats"> {{dpdetails?.stats.compartments}}
                  <span class="d-block small">{{'Compartments'|translate}}</span>
                </div>
              </div>
              <div class="text-center col">
                <div class="stats">{{reservationsCount}}
                  <span class="d-block small">{{'Reservation'|translate}}</span>
                </div>
              </div>
              
              <div class="text-center col">
                <div class="stats">{{pickupCount}}
                  <span class="d-block small">{{'pickup_locker_filled'|translate}}</span>
                </div>
              </div>
              <div class="text-center col">
                <div class="stats">{{deliveryCount}}
                  <span class="d-block small">{{'delivery_locker_filled'|translate}}</span>
                </div>
              </div>
              <div class="text-center col">
                <div class="stats">{{dpdetails?.stats.disabled}}
                  <span class="d-block small">{{'Disabled'|translate}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div *ngFor="let ter of terminalArr" class="table-responsive">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
              class="table table-sm table-bordered table-hover row-border hover text-center">
              <caption></caption>
              <thead class="table-dark">
                <tr>
                  <th (click)="sort('number')" scope="col">{{'Compartment Id'|translate}}<i class="fa fa-sort"></i></th>
                  <th scope="col">{{'Dimension'|translate}}</th>
                  <th (click)="sort('size')" scope="col">{{'Size'|translate}}<i class="fa fa-sort"></i></th>
                  <th scope="col">{{'Status'|translate}}</th>
                  <th scope="col">{{'Barcode'|translate}}</th>
                  <th scope="col">{{'Status At'|translate}}</th>
                  <th scope="col">{{'Command'|translate}}</th>
                  <th scope="col">{{'Action'|translate}}</th>
                  <th scope="col">{{'Remarks'|translate}}</th>
                </tr>
              </thead>
              <tbody *ngFor="let com of ter?.compartments | orderBy:key:reverse">
                <tr>
                  <td>{{com.number}}</td>
                  <td>{{com.height}} x {{com.width}} x {{com.depth}}</td>
                  <td>{{com.size}}</td>
                  <td>{{com.status || ''}}</td>
                 
                  <!--custom impl for lean lockers-->
                  <td>
                    <a *ngIf="com?.parcel?.id" [routerLink]="['/parcels/', com?.parcel?.id]">{{ com.itemBarcode || ''}}</a> 
                  </td>
                  <td>
                    <span *ngIf="com.statusAt">{{com.statusAt | utctolocaldate:'DD-MM-YYYY HH:mm:ss' || ''}}</span>
                  </td>
                  <td>
                    <div [appViewController]="{deliveryPoints:'allowCommand'}">
                      <div class="col d-flex" *ngIf="dpdetails.type == 'Lean Locker'">
                        <button type="button" 
                          class="btn btn-success btn-sm"
                          [disabled]="dpdetails.type == 'Lean Locker'"
                          (click)="updateStatus(id,1,ter.id,com.number)">{{'OPEN'|translate}}</button>
                      </div>
                      <div class="col d-flex justify-content-center" *ngIf="dpdetails.type != 'Lean Locker'">
                        <button type="button" 
                           class="btn btn-success btn-sm me-2"
                          (click)="updateStatus(id,1,ter.id,com.number)"
                          [disabled]="com.isOpen">{{'OPEN'|translate}}</button>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div [appViewController]="{deliveryPoints:'allowAction'}">
                      <div class="col d-flex justify-content-center" >
                        <button type="button"
                          class="btn btn-success btn-sm me-2"
                          (click)="updateStatus(id,3,ter.id,com.number,ter.externalId)"
                          [disabled]="com.active">{{'Enable'|translate}}</button>
                        <button type="button"
                           class="btn btn-danger btn-sm"
                          (click)="disabledValues(id,ter.id,com.number,ter.externalId)" data-target="#disablepopup"
                          data-toggle="modal" [disabled]="!com.active">{{'Disable'|translate}}</button>
                      </div>
                    </div>
                  </td>
                  <td *ngIf="com.remarks != null && com.remarks != '' ">{{com.remarks}}</td>
                  <td *ngIf="com.remarks == null && (com.isOpen == true && com.active == true)">Open</td>
                  <td *ngIf="(com.remarks == '' || com.remarks == null) && (com.isOpen == false 
                    && com.active == false) || (com.isOpen == true && com.active == false)">Non-Active</td>
                  <td *ngIf="com.remarks == null && (com.isOpen == false && com.active == true)"></td>

                  <!--Disable popup-->

                  <div class="modal" id="disablepopup">
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
                            </button>
                        </div>

                        <div class="modal-body">
                          <p class="text-left">{{'Change the status of vault below and enter a reason when you
                            Deactivate them' | translate}}</p>
                          <textarea class="textreason" autofocus id="disable-reason" rows="2" cols="50"
                            [(ngModel)]="disableComment" class="me-5" maxlength="50"></textarea>
                        </div>

                        <div class="modal-footer">
                          <button type="button" class="activebtn" data-dismiss="modal"
                            (click)="updateStatus(disabledCompartment.dp,4,disabledCompartment.term,disabledCompartment.comp,disabledCompartment.ext_id)">{{'Deactivate'|translate}}</button>
                          <button type="button" class="activebtn" data-dismiss="modal">{{'Cancel'| translate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>


                </tr>
              </tbody>
            </table>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
