import * as CryptoJS from 'crypto-js';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  RedirectRequestHandler,
  Requestor,
  FetchRequestor,
  TokenResponse,
} from '@openid/appauth';

import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { jwtDecode }  from 'jwt-decode';
import { UserInfo } from '../userinfo';
import { AuthorizationConfig, AuthorizationConfigExternal, GeneralEnvironmentInfo } from './authorization_config';
import { UserService } from '../Service/user.service';

const LS_ISSUER_URI = 'authorization.service.issuer_uri';
const LS_USER_INFO = 'authorization.service.user_info';
const LS_OPENID_CONFIG = 'authorization.service.parsed_openid_configuration';
const LS_TOKEN_RESPONSE = 'authorization.service.token_response';

@Injectable()
export class AuthorizationService {

  private readonly _authorizationHandler = new RedirectRequestHandler();
  public _status: string;

  private readonly _tokenResponses: BehaviorSubject<TokenResponse>;
  private readonly _userInfos: BehaviorSubject<UserInfo>;
  private readonly _serviceConfigs: BehaviorSubject<AuthorizationServiceConfiguration>;
  currentDdsUser = new BehaviorSubject(null);
  userSelectionAction$ = this.currentDdsUser.asObservable();

  requestInfo: any = null;
  responseInfo: any = null;
  partnerStatus: any;

  constructor(
    private readonly _http: HttpClient,
    private readonly _userService: UserService,
    public readonly _router: Router,
    private readonly _requestor: Requestor,
    @Inject('EnvironmentConfig') private readonly _envConfig: GeneralEnvironmentInfo,
    @Inject('AuthorizationConfig') private readonly _authConfig: AuthorizationConfig,
    @Inject('AuthorizationConfigExternal') private readonly _authConfigExternal: AuthorizationConfigExternal,
  ) {
    setInterval(() => {
      this.isAccessTokenExpired();
    }, 2000);

    //this.authorizationHandler.setAuthorizationNotifier(this.notifier);
    // attempt to restore previous values of the metadata config, token response, and user info
    let authorizationServiceConfiguration: AuthorizationServiceConfiguration | null = null;
    let tokenResponse: TokenResponse | null = null;
    let userInfo: UserInfo | null = null;
    const codeVerifier: string = null;

    // verify that we are still working with the same IDP, since a reload may
    // have been due to an underlying configuration change
    if (this._authConfig.issuer_uri === window.localStorage.getItem(LS_ISSUER_URI)) {
      const serviceConfigJSON = JSON.parse(
        window.localStorage.getItem(LS_OPENID_CONFIG));
      authorizationServiceConfiguration = serviceConfigJSON &&
        new AuthorizationServiceConfiguration(serviceConfigJSON);

      const tokenResponseJSON = JSON.parse(window.localStorage.getItem(LS_TOKEN_RESPONSE));
      tokenResponse = tokenResponseJSON && new TokenResponse(tokenResponseJSON);
      userInfo = JSON.parse(window.localStorage.getItem(LS_USER_INFO));

      if (localStorage.getItem('idToken') && !localStorage.getItem('ddsLoaded')) {
        this._router.navigate(['user/forbidden']);
      }
      //this.loadDdsUser()
    } else {
      // new issuer (or first run, or cleared session)
      // make sure we store the issuer, and have no other state
      window.localStorage.setItem(LS_ISSUER_URI, this._authConfig.issuer_uri);
      window.localStorage.removeItem(LS_OPENID_CONFIG);
      window.localStorage.removeItem(LS_USER_INFO);
      window.localStorage.removeItem(LS_TOKEN_RESPONSE);
    }
    // create subjects with the current values (or null)
    this._tokenResponses = new BehaviorSubject(tokenResponse);
    this._serviceConfigs = new BehaviorSubject(authorizationServiceConfiguration);
    this._userInfos = new BehaviorSubject(userInfo);
    // update local storage on changes
    this._serviceConfigs.subscribe((config: AuthorizationServiceConfiguration) => {
      window.localStorage.setItem(LS_OPENID_CONFIG, config && JSON.stringify(config.toJson()));
    });
    this._tokenResponses.subscribe((token: TokenResponse) => {
      window.localStorage.setItem(LS_TOKEN_RESPONSE, token && JSON.stringify(token.toJson()));
    });
    this._userInfos.subscribe((info: UserInfo) => {
      window.localStorage.setItem(LS_USER_INFO, info && JSON.stringify(info));
    });
    // monitor changes in metadata/tokens to possibly clear dependent values,
    // and to fetch userInfo.
    combineLatest(this._serviceConfigs, this._tokenResponses)
      .subscribe(
        ([configuration, token]: [AuthorizationServiceConfiguration, TokenResponse]) => {

          // if the service config is cleared, we need to invalidate any TokenResponse/userInfo
          if (configuration == null) {
            if (token != null) {
              this._tokenResponses.next(null);
            }
            this._userInfos.next(null);
            return;
          }

          // if the token is cleared, assume userinfo is invalidated too
          if (token == null) {
            this._userInfos.next(null);
            return;
          }

          // if we don't have a user info endpoint, we can't fetch user info
          if (configuration.userInfoEndpoint == null) {
            this._userInfos.next(null);
            return;
          }

          // fetch user info, if none
          if (this._userInfos.value == null) {
            const accessToken = token.accessToken;
            this._requestor.xhr<UserInfo>({
              url: configuration.userInfoEndpoint,
              method: 'GET',
              dataType: 'json',
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            }).then((userinfo) => {
              this._userInfos.next(userinfo);
            });
          }
        });

    // start fetching metadata
    if (authorizationServiceConfiguration == null) {
      this.fetchServiceConfiguration(this._authConfig);
    }
  }

  setCurrentDdsUser(user) {
    this.currentDdsUser.next(user);
  }

  async loadDdsUser(): Promise<any> {
    this._userService.me()
      .subscribe({
        next:(res:any) => {
          this.setCurrentDdsUser(res);
          localStorage.setItem('ddsLoaded', 'yes');
        },
        error:err => {
          if (err.status === 401) {
            this.setCurrentDdsUser(false);
            this._router.navigate(['user/forbidden']);
            return;
          } else {
            this.setCurrentDdsUser(null);
          }
        },
        complete:()=>{}
  });
  }

  get issuerUri(): string {
    return this._authConfig.issuer_uri;
  }

  public get isLoggedIn(): any {
    return this.currentDdsUser.value ? true : false;
  }

  public get currentUser(): any{
    return this.currentDdsUser.value;
  }

  public serviceConfiguration(): Observable<AuthorizationServiceConfiguration> {
    return this._serviceConfigs.asObservable().pipe(distinctUntilChanged());
  }

  public tokenResponse(): Observable<TokenResponse> {
    return this._tokenResponses.asObservable().pipe(distinctUntilChanged());
  }

  public userInfos(): Observable<UserInfo> {
    return this._userInfos.asObservable().pipe(distinctUntilChanged());
  }

  authorize(): void {
    this._serviceConfigs
      .pipe(filter((value: any) => value != null))
      .pipe(take(1))
      .subscribe((configuration: AuthorizationServiceConfiguration) => {
        const scope = this._authConfig.scope || 'openid';

        // create a request
        const request = new AuthorizationRequest({
          client_id: this._authConfig.client_id,
          redirect_uri: this._authConfig.redirect_uri,
          scope: scope,
          response_type: 'token',
          extras: this._authConfig.extras
        });
        this._authorizationHandler.performAuthorizationRequest(configuration, request);
      });
  }

  authorizeExternal(): void {
    this._serviceConfigs
      .pipe(filter((value: any) => value != null))
      .pipe(take(1))
      .subscribe((configuration: AuthorizationServiceConfiguration) => {
        AuthorizationServiceConfiguration.fetchFromIssuer(this._authConfigExternal.issuer_uri, new FetchRequestor())
          .then((response: any) => {
            const authRequest = new AuthorizationRequest({
              client_id: this._authConfigExternal.client_id,
              redirect_uri: this._authConfigExternal.redirect_uri,
              scope: this._authConfigExternal.scope,
              response_type: 'code',
              state: undefined,
              extras: this._authConfigExternal.extras
            });
            this._authorizationHandler.performAuthorizationRequest(response, authRequest);
          })
          .catch(error => {
            console.error('error', error);
          });
      });

  }

  generateLargeNumber(minDigits, maxDigits) {
    // Vérifier que les arguments sont valides
    if (minDigits < 1 || maxDigits < minDigits) {
      throw new Error('Les valeurs des arguments sont incorrectes');
    }
    // Générer une longueur aléatoire entre minDigits et maxDigits
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const randomNo = array[0]/0xFFFFFFFF;
    const length = Math.floor(randomNo * (maxDigits - minDigits + 1)) + minDigits;
    // Générer la chaîne de chiffres aléatoires
    let numberStr = '';
    for (let i = 0; i < length; i++) {
      const array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      const randomNoS = array[0]/0xFFFFFFFF;
      // Ajouter un chiffre aléatoire (de 0 à 9) à la chaîne
      numberStr += Math.floor(randomNoS * 10);
    }
    // just return the number as a string
    return numberStr;
  }

  async loginReuseService() {
    let code_challenge = '';
    let state = '';
    /** To Generate the Random Numbers  */
    // challenge number (as code verifier too) need to be between 43 and 128 digits !
    code_challenge = this.generateLargeNumber(43,128);
 
    for (let i = 0; i < 43; i++) {
      const array = new Uint32Array(1);
      window.crypto.getRandomValues(array);
      const randomNo = array[0]/0xFFFFFFFF;
      state += Math.floor(randomNo * 9);
    }
    /** Post data Params */
    const objdata = {
      'client_id': this._authConfig.client_id,
      'response_type': 'code',
      'scope': 'openid',
      'redirect_uri': this._authConfig.redirect_uri,
      'code_challenge': code_challenge,
      'state': state
    };

    window.localStorage.removeItem('code_challenge');
    window.localStorage.setItem('code_challenge', code_challenge);

    const url = `${this._authConfig.pingAuthorizationUrl  }?${  this.serializeData(objdata)}`;
    window.open(url, '_self', 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400');
    window.localStorage.setItem('windowOpen', 'true');
  }

  private async fetchServiceConfiguration(configuration: AuthorizationConfig) {
    const response = await AuthorizationServiceConfiguration.fetchFromIssuer(configuration.issuer_uri, this._requestor);
    this._serviceConfigs.next(response);
  }

  signOut(): void {
    const token = window.localStorage.getItem('token');

    this.setCurrentDdsUser(null);
    this._tokenResponses.next(null); 
    this._userInfos.next(null);
    this._serviceConfigs.next(null);
    const tokenInfo: any = jwtDecode(token)

    window.localStorage.clear();
    this.removeCookie();
    
    console.log(JSON.stringify({tokenInfo}, null, 2))

    let targetUrl = '';

    if (tokenInfo) {
      if (tokenInfo.client_id === 'nlp_web_ciam_np') {
        targetUrl =  `${this._authConfigExternal.pingLogout  }?id_token=${  token  }&TargetResource=${ this._envConfig.baseUrl }&client_id=nlp_web_ciam_np`;
      } else {
        targetUrl = `${this._authConfig.pingLogout  }?id_token=${  token  }&TargetResource=${ this._envConfig.baseUrl }&client_id=lockerinternal`;
      }
    }

    console.log('targetUrl', targetUrl)
    window.location.href = targetUrl
  }

  signOutForbidden(): void {
    this.setCurrentDdsUser(null);
    this._tokenResponses.next(null); 
    this._userInfos.next(null);
    this._serviceConfigs.next(null);
    const token = window.localStorage.getItem('token');
    const tokenInfo: any = jwtDecode(token)
    window.localStorage.clear();
    if (tokenInfo) {
      if (
        tokenInfo.client_id === 'lockerexternal' ||
        tokenInfo.client_id === this._authConfigExternal.client_id
      ) {
        window.location.href = `${this._authConfigExternal.pingLogout   }id_token=${  token  }&TargetResource=${  this._authConfigExternal.pingLogout  }?&client_id=${  this._authConfigExternal.client_id}`;
      } else {
        window.location.href = `${this._authConfig.pingLogout  }?id_token=${  token  }&TargetResource=${  this._authConfig.redirect_uri  }&client_id=${  this._authConfig.client_id}`;
      }
    }
  }

  completeAuthorizationRequest(token) {
    const tokenInfo: any = jwtDecode(token)
    if (tokenInfo) {
      this.loadDdsUser();
    }
      return tokenInfo;
  
  }

  /** Serilaize */
  serializeData = function (obj) {
    const str = [];
    for (const p in obj){
      str.push(`${encodeURIComponent(p)  }=${  encodeURIComponent(obj[p])}`);
    }
    return str.join('&');
  };
  /*** Getting the redirect URL* */
  getParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${  name  }(=([^&#]*)|&|#|$)`),
      results = regex.exec(url);
    if (!results) {return null};
    if (!results[2]) {return ''};
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  /** Exp time */
  isAccessTokenExpired() {
    let isexpired = false;
    const accessToken = localStorage.getItem('token');
    if (!accessToken) {return true};
    const decodeToken = this.parseJwt(accessToken);
    const validTill = new Date(decodeToken.exp * 1000);
    const now = new Date();
    if (now > validTill) {
      isexpired = true;
      this.signOut();
    }
    return isexpired;
  }

  /** oauth Token */
  async oauthToken(obj) {
    const options = {
      withCredentials: false,
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    await this._http.post(this._authConfig.pingTokenUrl, this.serializeData(obj), options).toPromise()
      .then(res => {
        console.log('inside oauth token');
        const encodedAccessToken = this.encodeAccessToken(res['access_token']);
        window.localStorage.setItem('encodedAccessToken', encodedAccessToken);
        window.localStorage.setItem('accessToken', res['access_token']);
        window.localStorage.removeItem('code_challenge');
        this.userProfile();
        localStorage.setItem('token', res['access_token']);
        localStorage.setItem('refreshToken', res['refresh_token']);
        localStorage.setItem('idToken', res['id_token']);
        const decodeToken = this.parseJwt(res['access_token']);
        localStorage.setItem('expiryTime', decodeToken.exp);
      })
      .catch(error => {
        console.error('error', error);
      });
  }

  async getAccessToken() {
    const code_challenge = window.localStorage.getItem('code_challenge');

    /** Get the code Value from Redirect URL */
    const auth_code_val = this.getParameterByName('code', window.location.href);
    /** Post data Params */
    const obj = {
      'code': auth_code_val,
      'grant_type': 'authorization_code',
      'client_id': this._authConfig.client_id,
      'code_verifier': code_challenge,
      'redirect_uri': this._authConfig.redirect_uri,
    };
    this.oauthToken(obj);
  }

  parseJwt(token) {
    if (!token) {return null};
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  encodeAccessToken(accesstoken: string) {
    const encodedAccessToken = CryptoJS.AES.encrypt(accesstoken, 'locker key 123456').toString();
    localStorage.setItem('encodedAccessToken', encodedAccessToken);
    return encodedAccessToken;
  }

  getDecodedAccessToken() {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem('encodedAccessToken'), 'locker key 123456');
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  logout() {
    this.removeCookie();
    const url = `${this._authConfig.pingLogout  }?${  this.serializeData({
      'id_token': localStorage.getItem('idToken'),
      'TargetResource': this._envConfig.baseUrl,
      'client_id': this._authConfig.client_id,
    })}`;
    window.open(url, '_self');

  }
  removeCookie() {
    window.localStorage.removeItem('windowOpen');
    window.localStorage.removeItem('code_challenge');
    window.localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    localStorage.removeItem('refreshToken');
  }

  userProfile() {

    const options = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.getDecodedAccessToken())
    };
    this._http
      .get(this._authConfig.pingUserProfileUrl, options)
      .toPromise()
      .then(res => {
        this.loadDdsUser();
        this.setPrivilegeValue(res);
      });
  }


  async setPrivilegeValue(response: any) {
    let userEmail: any;

    if (response.email) {
      userEmail = response.email;
    }
    else if(response.Email ) {
      userEmail = response.Email;
    }
    else if (response.mail) {
      userEmail = response.mail;
    }
    
    const filterVal = {
      email: userEmail,
      name: undefined,
      groups: undefined,
    };

    const reqArray = [this._userService.fetchMany(filterVal).toPromise(), this._userService.getUsergroup().toPromise()];
    const [userRole, currentGroups]:any = await Promise.all(reqArray);
    
    const userDetails = (userRole as Array<any>).find(o => o.email.toLowerCase() === userEmail.toLowerCase());
    
    if (userDetails && userDetails.partner) {
      this.partnerStatus = userDetails.partner;
    }

    const currentUserRoles = userDetails.groups.map(element => element.name);
    ///  show partner tool tab as it is visible for partnertype DELIVERY and hide for type OWNER
    for (let i = 0; i < currentUserRoles.length; i++) {
      const filteredVal = currentGroups.filter((element) => {
        if (element.userGroups != null) {
          if (element.userGroups.title === currentUserRoles[i]) {
            return element;
          }
        }
  
      });
  
      if (filteredVal.length === 0) {
        continue;
      }
  
      const checklist = filteredVal[0].userGroups.checklistData;
      if (userDetails.partner?.type?.toUpperCase() === 'OWNER') {
        checklist.filter(x => x['partnerTool'])[0]['partnerTool'].viewPartnerTool = true;
  
      }
      if (userDetails.partner?.type?.toUpperCase() === 'DELIVERY') {
        checklist.filter(x => x['partnerTool'])[0]['partnerTool'].viewPartnerTool = false;
  
      }
    }
    ///End 
    if (currentUserRoles.includes('partnertoolgroup')) {
      if (currentUserRoles.length === 1) {
        this._router.navigate(['partnertools']);
      }
      else {
        this.setRoute(currentUserRoles, currentGroups);
      }

    }
    else {
      this.setRoute(currentUserRoles, currentGroups);
    }
  }

  setRoute(currentUserRoles: any, currentGroups: any) {
    const groupHeading = {};
    for (let i = 0; i < currentUserRoles.length; i++) {
      const filteredVal = currentGroups.filter((element) => {
        if (element.userGroups != null) {
          if (element.userGroups.title === currentUserRoles[i]) {
            return element;
          }
        }

      });

      if (filteredVal.length === 0) {
        continue;
      }

      const checklist = filteredVal[0].userGroups.checklistData;
      const titleKeys = checklist.map((obj) => {
        for (const i in obj) {
          return i;
        };
      });
      if (i === 0) {
        for (const element of titleKeys) {
          groupHeading[element] = false;
        }
      }

      checklist.forEach((object, index) => {
        let trueCount = 0;
        for (const key in object[titleKeys[index]]) {
          if (object[titleKeys[index]][key] === true) {
            trueCount = trueCount + 1;
          }

        }
        if (trueCount !== 0) {
          if (!groupHeading[titleKeys[index]]) {
            groupHeading[titleKeys[index]] = true;
          }
        }

      });

      // if(groupHeading['parcels'])
      // {
      //   return
      // }
    }


    if( this.currentUser && this.currentUser.partner?.type?.toUpperCase() === 'OWNER'){
      this._router.navigate(['partnertools']);
      return;
    }
    
    if (groupHeading['parcels']) {
      this._router.navigate(['parcels']);
      return;
    }
    else if (groupHeading['deliveryPoints']) {
      this._router.navigate(['lockerlist']);
      return;
    }
    else if (groupHeading['users']) {
      this._router.navigate(['user/search']);
      return;
    }
    else if (groupHeading['partner']) {
      this._router.navigate(['partners']);
      return;
    }
    else if (groupHeading['staff']) {
      this._router.navigate(['postman']);
      return;
    }
    else if (groupHeading['report']) {
      this._router.navigate(['lockerdownreport']);
      return;
    }
    else if (groupHeading['partnerTool']) {
      this._router.navigate(['partnertools']);
      return;
    }

  }

}
