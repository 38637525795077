import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../Service/user.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
@Component({
  selector: 'app-usergroup',
  templateUrl: './usergroup.component.html',
  styleUrls: ['./usergroup.component.scss']
})
export class UsergroupComponent implements OnInit, AfterViewInit {

  groupData: any;
  userGrop: any;
  titleKeys:any;
  deleteID:any;
  showUnauthorized = false;
  showLoader = false;
  @ViewChild('myDiv',{static:true}) myDiv : ElementRef;
  constructor(
    public _router: Router,
    private readonly _user: UserService,
    private readonly _spinner: SpinnerService
  ) { }


  ngOnInit(): void {
    this._spinner.show();
    this._user.getUsergroup().subscribe({
      next:(res: any) => {
      const data = res;
      this.groupData = data.reverse();
      this.setUserGroup();
    },
    error:err => {
      this._spinner.hide();
      console.error(err);
    },
    complete:()=>{}
  });
  }

  ngAfterViewInit()
  {
    const divElement = this.myDiv.nativeElement;
    const divstyle= window.getComputedStyle(divElement);
    const display= divstyle?.display;
    if(display==='none')
    {
      this.showUnauthorized=true;
    }
  }

  goTogroupadd() {
    this._router.navigate(['/user/usergroupaddedit/' + 'add']);
  }

  goTogroupedit(item)
  {
    this._router.navigate([`/user/usergroupaddedit/edit/${item}`]);
  }
  setUserGroup()
  {
    // Get default permissions from the user checklist data
    const defaultPermissions = this._user.checklistData;

    // Get the user groups from the API
    this.userGrop = this.groupData.map((element)=>element.userGroups);

    // Loop through the user groups and add the default permissions
    this.userGrop.forEach((element)=>{
      // Extend the user group with the default permissions
      element.checklistData = element.checklistData.map((item)=>{
        const key = Object.keys(item)[0];
        const defaultList = 
        defaultPermissions.find((obj)=>Object.keys(obj)[0]===key);
        // Merge the default permissions with the user group permissions
        item[key] = Object.assign({}, defaultList[key], item[key]);
        return item;
      });
    });

    this.setTitleKeys();
    this._spinner.hide();

  }

  setTitleKeys()
  {
    const titleset = this._user.checklistData;
    this.titleKeys=titleset.map((obj)=>{
      for(const i in obj)
      {
        return i;
      }
    });
  }

  savedelete(item:any)
  {
    const deleteobj=this.groupData.find((obj)=>{
    
      if(obj.userGroups!=null)
      {
     
        if(obj.userGroups.title===item)
        {
          return obj;
        }
      }
    });
    this.deleteID=deleteobj.id;
  }
  deleteUser()
  {
    this._user.deleteUsergroup(this.deleteID).subscribe({
      next:res=>{
      this.ngOnInit();
    },
    error:err=>{
      console.error(err);
    },
    complete:()=>{}
  });
  }

  getObjectKeys(item:any,index:any):any[]
  {
    return Object.keys(item[this.titleKeys[index]]);
  }

}
