import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../Service/partner.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-partner-settings',
  templateUrl: './partner-settings.component.html',
  styleUrls: ['./partner-settings.component.scss']
})
export class PartnerSettingsComponent implements OnInit {
  public partnerSettingForm;
  settingResult: any;
  constructor(
    private readonly _partnerService: PartnerService,
    public _router: Router,
    private readonly _toastr: ToastrService,
    private readonly _spinner: SpinnerService,
    private readonly _translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.partnerSettingForm = new FormGroup({
      minReservePeriod: new FormControl('', []),
      length: new FormControl(100, [Validators.pattern('^[0-9]*$')]),
      width: new FormControl(100, [Validators.pattern('^[0-9]*$')]),
      height: new FormControl(100, [Validators.pattern('^[0-9]*$')])
    });
    this.getSettingDetails();
  }

  onInputChange(event: any): void {
    const inputValue = event.target.value;
    event.target.value = inputValue.replace(/[^0-9]/g, '');
  }

  getSettingDetails() {
    this._spinner.show();
    this._partnerService.getsettingInfo().subscribe({
     next: (res: any) => {
      this._spinner.hide();
      if (res.length !== 0) {
        this.patchDefaultValues(res[0]);
      }
    },
    error: (err) => {
      console.error(err);
      this._spinner.hide();
    },
    complete:()=>{}
  });
  }
  patchDefaultValues(result: any) {
    this.partnerSettingForm.patchValue({
      minReservePeriod: result.minReservePeriod ? result.minReservePeriod : '',
      length: result.length ? result.length : '',
      width: result.width ? result.width : '',
      height: result.height ? result.height : '',
    });
  }
  saveSettingDetails() {
    console.log('valuee',this.partnerSettingForm.value);
    const body = {
      minReservePeriod: this.partnerSettingForm.value.minReservePeriod ? 
        this.partnerSettingForm.value.minReservePeriod : null,
      length: this.partnerSettingForm.value.length ? 
        this.partnerSettingForm.value.length : null,
      width: this.partnerSettingForm.value.width ? 
        this.partnerSettingForm.value.width : null,
      height: this.partnerSettingForm.value.height ? 
        this.partnerSettingForm.value.height : null
    };
    this._spinner.show();
    this._partnerService.saveSettingInfo(body).subscribe({next:(res) => {
      this._spinner.hide();
      this._toastr.success(this._translate.instant(
        'Partner setting saved !!'), this._translate.instant('Success'));
      this._router.navigate(['/partnertools']);
    }, error:err => {
      console.error(err);
      this._spinner.hide();
    },
    complete:()=>{}
    });
  }

}
