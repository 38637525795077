import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { UserService } from '../../../Service/user.service';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-details-preview',
  templateUrl: './user-details-preview.component.html',
  styleUrls: ['./user-details-preview.component.scss']
})
export class UserDetailsPreviewComponent implements OnInit {
  userId:any;
  userdetails:any;
  deleteId:any;
  id:any;
  constructor(
    private readonly _location: Location,
    public _activatedRoute: ActivatedRoute,
    private readonly _userService: UserService,
    public _router: Router,
    private readonly _toastr: ToastrService,
    private readonly _translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.userId = this._activatedRoute.snapshot.paramMap.get('id');
    this.fetchUserDetails(this.userId);
  }
  fetchUserDetails(userId) {
    this._userService.fetchById(userId).subscribe({
      next:res => {
        this.userdetails = res;
      },
      error:err => {
        console.error(err);
      },
      complete:()=>{}
  });
  }
  onDeleteConfirm(){
    this._userService.deleteUser(this.userId).subscribe({
      next:(res) => {
        this._location.back();
      },
      error:err => {
        this._toastr.error(this._translate.instant('Deleting User Failed !!'),
          this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
  });
  }
  onEdit(){
    this._router.navigate(['/updateuser/' + this.userId]);
  }
  goToUsersList() {
    this._router.navigate(['/user/search']);
  }
}



