import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerService } from '../../Service/partner.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import { TranslateService } from '@ngx-translate/core';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from '../../Service/app.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { UserService } from 'src/app/Service/user.service';
import { AuthorizationService } from 'src/app/Service/authorization.service';

@Component({
  selector: 'app-partner-order',
  templateUrl: './partner-order.component.html',
  styleUrls: ['./partner-order.component.scss']
})
export class PartnerOrderComponent implements OnInit {

  showSpinner = false;
  noPackage: any;
  amount: any;
  reference: any;
  location: any;
  queue: any;
  reservePeriodFrom: any;
  reserPeriodFromTime: any;
  reservePeriodTo: any;
  reserPeriodToTime: any;
  barcode: any;
  customerBarcode: any;
  storedValue: any;
  public partenOrderForm;
  action: any;
  btnclick = false;
  isShow = true;
  todos: any;
  locationData: any = [];
  getEmail: any;
  todayDate: any;
  overviewDate: any;
  tomorrowDate: any;
  editResponse: any;
  submitted = false;
  newOrderRoute = '/partnertools/newOrder';
  dateFormatWithHr='DD-MM-YYYY HH:mm';
  targetDate='YYYY-MM-DD';
  formattedTargetDate='DD-MM-YYYY'
  @ViewChild('contentToConvert') contentToConvert!: ElementRef;
  @ViewChild('reserveDateToInput') reserveDateToInput: ElementRef;
  @ViewChild('reserveDateFromInput') reserveDateFromInput: ElementRef;
  selectedLocationname: any;
  id: any;
  orderToProcess: any;
  reservationCheck = true;
  editForm = false;
  disablePickupCode = false;
  editBarcode = false;
  lockerNotFree = false;
  whentocollectvalue = '';
  partnerId: any = 1;
  email: any;
  translateData: any;
  language: any;
  customerObj: any;
  customerData: any;
  maxDate: Date;
  userInfo: any;
  disablePartner=false;

  constructor(
    public _activatedRoute: ActivatedRoute,
    private readonly _partnerService: PartnerService,
    public _router: Router,
    public _datepipe: DatePipe,
    private readonly _toastr: ToastrService,
    private readonly _route: ActivatedRoute,
    private readonly _translate: TranslateService,
    private readonly _deliverypointservice: DeliverypointsService,
    private readonly _renderer: Renderer2,
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
    private readonly _spinner: SpinnerService,
    private readonly _userservice:UserService,
    public _authorizationService: AuthorizationService
    
  ) {
    this.action = this._activatedRoute.snapshot.paramMap.get('action');
    this.todayDate = new Date();
      const todate = new Date();
      todate.setDate(todate.getDate() + 5);
      this.maxDate = todate;
      const tomDate = new Date();
      tomDate.setDate(tomDate.getDate() + 1);
   
      // let reserveTodateDefault = tomDate
      this.tomorrowDate = tomDate;
      this.partenOrderForm = new FormGroup({
        noPackage: new FormControl(1, []),
        // amount: new FormControl('', []),
        reference: new FormControl('', []),
        location: new FormControl('', [Validators.required]),
        // queue: new FormControl('', []),
        reservePeriodFrom: new FormControl( {value:this.action === "add" ? this.todayDate : '',disabled:true}, [Validators.required]),
        // reservePeriodFromTime: new FormControl('', []),
        reservePeriodTo: new FormControl(this.action === "add" ? this.maxDate : '', [Validators.required]),
        // reservePeriodToTime: new FormControl('', []),
        barcode: new FormControl('', []),
        customerBarcode: new FormControl('', [Validators.pattern(/^[A-Z0-9]{6}$/)]),
        // customerBarcode: new FormControl('',[]),
        length: new FormControl(100, []),
        width: new FormControl(100, []),
        height: new FormControl(100, [])

        // add: this.FB.array([
        //   // new FormControl(null),
        // ])

      });
      
    this._route.params.subscribe((data) => {
      this.id = data;
      this.disablePickupCode=this.action ==='edit'? true:false;
      if (Object.keys(this.id).length !== 0 && data.id) {
        this.editForm=true;
        if (this.id.action === 'process') {
          this.editForm = true;
         
          this.editBarcode = false;
          this.getpartnerOrderToProcess(this.id);
        }
        if (this.id.action === 'vault') {
          this.editForm = true;
         
          this.editBarcode = false;
          this.getOrderPlacedInVault(this.id);
        }

        if (this.id.action === 'history') {
          this.editForm = true;
         
          this.editBarcode = false;
          this.getOrderInHistory(this.id);
        }
        if (this.id.action === 'ironing') {

          this.editForm = false;
          this.editBarcode = true;
        }
      }
    });
    if(this.action==='edit'){
      this.editResponse = this._router.getCurrentNavigation()?.extras?.state?.data;
     
      if(!this.editResponse){
        this._router.navigate([this.newOrderRoute]);
      }
      this.getEmail= this.editResponse?.email;
      this.customerObj={
        firstName:this.editResponse?.customers?.firstName,
        lastName:this.editResponse?.customers?.lastName ,
        Email:this.editResponse?.customers?.email ,
        phoneNumber:this.editResponse?.customers?.phoneNumber
      };
    }
  }


  ngOnInit(): void {
    this.meApiCall();
    document.getElementById('contentToConvert').hidden = true;
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });
    this._partnerService.editSubject.subscribe(res => {
      if(this.action !== 'edit'){
        this.editResponse = res;
      }
    });

    this._authorizationService.currentDdsUser.subscribe(user => {
      this.initializeUserInfo(user);
      this.setupOverviewDate();
      this.setupReservationDates();
      this.handleEmailSubscription();
      this.initializeForm();
      this.handleAction();
    });
  }

  private initializeUserInfo(user: any): void {
    this.userInfo = user;
    this.partnerId = this.userInfo?.partner?.id || 1;
   }
   private setupOverviewDate(): void {
    if (this.editResponse?.orderDate && this.action !== 'add') {
      const orderDateval = this.editResponse.orderDate;
      this.overviewDate = moment.utc(orderDateval).local().format(this.dateFormatWithHr);
    } else {
      const currentTime = moment.utc().local().format(this.dateFormatWithHr);
      this.overviewDate = currentTime;
    }
   }
   private setupReservationDates(): void {
    this.todayDate = new Date();
    const todate = new Date();
    todate.setDate(todate.getDate() + 5);
    this.maxDate = todate;
    const tomDate = new Date();
    tomDate.setDate(tomDate.getDate() + 1);
    this.tomorrowDate = tomDate;
    if (this.editResponse?.reservePeriodFrom && this.editResponse?.reservePeriodTo && ['process', 'vault', 'edit'].includes(this.action)) {
      const resFromMoment = moment(this.editResponse.reservePeriodFrom.split('T')[0], this.targetDate);
      const resToMoment = moment(this.editResponse.reservePeriodTo.split('T')[0], this.targetDate);
      if (resFromMoment && resToMoment) {
        this.whentocollectvalue = `${moment(resFromMoment).format(this.formattedTargetDate)} - ${moment(resToMoment).format(this.formattedTargetDate)}`;
      } else {
        this.whentocollectvalue = `${this.editResponse.reservePeriodFrom} - ${this.editResponse.reservePeriodTo}`;
      }
    }
   }
   private handleEmailSubscription(): void {
    this._partnerService.emailSubject.subscribe(res => {
      if (!res && this.action === 'add') {
        this._router.navigate([this.newOrderRoute]);
      }
      this.getEmail = res;
    });
   }
   private initializeForm(): void {
    this.partenOrderForm = new FormGroup({
      noPackage: new FormControl(1, []),
      reference: new FormControl('', []),
      location: new FormControl('', [Validators.required]),
      reservePeriodFrom: new FormControl({ value: this.todayDate, disabled: true }, [Validators.required]),
      reservePeriodTo: new FormControl(this.maxDate, [Validators.required]),
      barcode: new FormControl('', []),
      customerBarcode: new FormControl('', [Validators.pattern(/^[A-Z0-9]{6}$/)]),
      length: new FormControl(100, []),
      width: new FormControl(100, []),
      height: new FormControl(100, [])
    });
    this.setLocation();
   }
   private handleAction(): void {
    if (this.action === 'add') {
      if (this.id?.id) {
        this.editForm = false;
        this.editBarcode = false;
        this.partenOrderForm.get('barcode').setValue(this.id.id);
        this.extractValue(this.id.id);
      }
    }
    if (this.action === 'edit') {
      this.editBarcode = false;
      this.patchEditValue();
    }
    if (this.id?.action === 'ironing') {
      this.partenOrderForm.get('barcode').setValue(this.id.id);
    }
   }
  meApiCall(){
    this._userservice.me().subscribe((res) => {
      this.disablePartner = 
      res['partner']['allowOwnCustomers']===true ?true:false;
      if(this.action === 'add'){
        this.getSettingDetails(res['partner']['allowOwnCustomers']);
      }
    });
  };
  onInputChange(event: any): void {
    const target = event.target as HTMLInputElement;
    if(target && target.value){
      target.value = target.value.replace(/\D/g, '')
    }
  }

  getSettingDetails(allowOwnCustomers) {
    let dimension={} ;
    if (allowOwnCustomers){
      this._spinner.show();
      this._partnerService.getsettingInfo().subscribe({next:(res: any) => {
        this._spinner.hide();
        const result = res[0];
        if (result?.length > 0) {
          dimension={ l:result.length ? result.length : '', w:result.width ? result.width : '', h:result.height ? result.height : ''};
          this.patchDefaultValues(dimension);
        } else {
          dimension={ l:100, w:100, h:100};
        }
      }, error:err => {
        console.error(err);
        this._spinner.hide();
      },
      complete:()=>{}
      });
    } else {
      const parcelSize = this.id.id.match(/(MD|HG|LG)U\d+/);

const parcelSizeDimension :Record<string,{l:number;w:number,h:number} | null>={
  MD:{l:405, w:300, h:610},
  HG:{l:405, w:1000, h:610},
  LG:{l:405, w:400, h:610},
}
dimension = parcelSizeDimension[parcelSize[1]] || null
      
    }

    this.patchDefaultValues(dimension);

    
  }

  patchDefaultValues(result: any) {
    this.partenOrderForm.patchValue({
      length: result.l,
      width: result.w,
      height: result.h,
    });
  }

  patchEditValue() {
    this.partenOrderForm.controls['reservePeriodFrom'].enable();
    this.partenOrderForm.patchValue({
      reference: this.editResponse.reference,
      location: this.editResponse.locationId,
      barcode: this.editResponse.barcode,
      customerBarcode: this.editResponse.pickupCode,
      length: this.editResponse['length'],
      width: this.editResponse.width,
      height: this.editResponse.height,
      reservePeriodFrom: moment.utc(this.editResponse.reservePeriodFrom,'YYYY-MM-DD hh:mm:ss').local().format(this.dateFormatWithHr),
      reservePeriodTo: moment.utc(this.editResponse.reservePeriodTo,'YYYY-MM-DD hh:mm:ss').local().format(this.dateFormatWithHr)
    });
    this.partenOrderForm.get('reservePeriodFrom').disable({onlySelf: true});

  }

  setLocation() {
    if(!this.userInfo.partner.id){
      return;
    }
    this._partnerService.fetchPartnerDeliverypointDetails(this.userInfo.partner.id).subscribe({
      next:(res: any) => {
        const data = [];
        
        for (const element of res.dpdsByAcCode.list) {
          this.locationData.push({ id: element.deliveryPointId, name: element.lockerName,lockerCode:element.deliveryPointId});
        }
        
        if (this.action === 'edit') {
          this.setLocationName(this.editResponse.locationId);
        }
        console.log('res-->', res);
      },
      error:err => {

      },
      complete:()=>{}
  });
  }
  setLocationName(val: any) {
    const selectedlocation = this.locationData.find((element) =>

      element.lockerCode = val
    );

    this.checkLockerSpace(selectedlocation.lockerCode);
    this.selectedLocationname = selectedlocation.name;
  }

  checkLockerSpace(val: any) {
    this._deliverypointservice.fetchwithId(val, {
      includeTerminals: true,
      includeCompartments: true
    }).then((res: any) => {
      const terminals = res.terminals;
      let count = 0;
      for (const element of terminals) {
        for (const obj of element.compartments) {
          if (obj.active) {
            if (obj.status === null || obj.status === 'free' || obj.status === '') {
              count = count + 1;
              break;
            }
          }
        }
        if (count !== 0) {
          break;
        }
      }
      if (count === 0) {
        this.lockerNotFree = true;
      }
      else {
        this.lockerNotFree = false;
      }
    })
      .catch(ex => {
        console.error(ex);
        this.lockerNotFree = false;
      });
  }


  saveDetails(orderType: string): void {
    this.submitted = true;
    if (!this.partenOrderForm.valid) {
      return;
    }
    this._spinner.show();
    const formData = this.partenOrderForm.getRawValue();
    const baseBody = {
      pickupCode: formData.customerBarcode,
      noPackage: formData.noPackage,
      reference: formData.reference,
      locationId: parseInt(formData.location, 10),
      reservePeriodFrom: this.getDateUtc(formData.reservePeriodFrom.toUTCString()),
      reservePeriodTo: this.getDateUtc(formData.reservePeriodTo.toUTCString()),
      barcode: formData.barcode,
      email: this.getEmail,
      length: parseInt(formData.length, 10),
      width: parseInt(formData.width, 10),
      height: parseInt(formData.height, 10),
      language: this.language?.toLowerCase() || window.localStorage.getItem('currentLang'),
    };
    const additionalFields = this.action === 'edit' ? {
      partnerId: this.partnerId,
      orderId: this.editResponse?.id,
    } : {};
    const body = { ...baseBody, ...additionalFields };
    if (moment(formData.reservePeriodTo).isBefore(moment())) {
      this._spinner.hide();
      this._toastr.error(
        this._translate.instant('Reservation Expiry date cannot be in the past'),
        this._translate.instant('Sorry')
      );
      return;
    }
    if (orderType === 'placeorder') {
      this.executePlaceOrderWorkFlow(body);
      return;
    }
    this._partnerService.saveNewpartnerOrder(body, orderType).subscribe({
      next: (res: any) => {
        this._spinner.hide();
        if (res?.error) {
          const errorMessages: Record<string, string> = {
            barcodeexists: 'barcodeexists',
            pickupcodeexists: 'Pick up code already used by another active order',
          };
          const errorMessageKey = errorMessages[res.error];
          if (errorMessageKey) {
            this._toastr.error(
              this._translate.instant(errorMessageKey),
              this._translate.instant('Sorry')
            );
            return;
          }
        }
        this._toastr.success(
          this._translate.instant('Partner order saved !!'),
          this._translate.instant('Success')
        );
        this._router.navigate([this.newOrderRoute]);
      },
      error: (err) => {
        this._spinner.hide();
        console.error(err);
        this._toastr.error(
          this._translate.instant('Failed to save partner order !!'),
          this._translate.instant('Failed')
        );
      },
    });
   }

  getDateUtc(date:string){
    return moment(date).utc().format();
  }

  todateChange(event){
      this.partenOrderForm.controls['reservePeriodTo'].setValue(event);
      this.partenOrderForm.updateValueAndValidity();
    }
 fromdateChange(event){
      this.partenOrderForm.controls['reservePeriodFrom'].setValue(event);
      this.partenOrderForm.updateValueAndValidity();
}
  get add() {
    return this.partenOrderForm.controls['add'] as FormArray;
  }

  get formValidators() {
    return this.partenOrderForm.controls;
  }

 
  featureHide() {
    this.isShow = false;
  }

  generatePdf() {
    document.getElementById('contentToConvert').hidden = false;
    if (this.selectedLocationname === undefined || this.partenOrderForm.value.barcode === '') {
      this._toastr.warning(this._translate.instant('please enter the location and barcode'), this._translate.instant('sorry'));
    }
    else {
      const element = document.getElementById('contentToConvert');
      const pdf = new jsPDF('l');
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        pdf.addImage(imgData,'PNG', 10, 10, 400, 50);
        pdf.setProperties({
          title: 'barcode'
        });

        const fileurl = URL.createObjectURL(pdf.output('blob'));
        window.open(fileurl, '_blank');
        const link = document.createElement('a');
        link.href = fileurl;
        link.download = 'barcode.pdf';
        link.click();
        document.getElementById('contentToConvert').hidden = true;
      });
    }
  }
 
  getpartnerOrderToProcess(id) {
    this._partnerService.getPartnerOrderToProcess().subscribe((res: any) => {
      this.commonObject(res, id);
    });
  }
  commonObject(res, id) {
    const matchedObject = res.find(obj => obj.id === id.id);
    if (matchedObject) {
      this.partenOrderForm.patchValue({
        noPackage: matchedObject.noPackage,
        reference: matchedObject.reference,
        location: matchedObject.locationId,
        reservePeriodFrom: moment.utc(this.editResponse.reservePeriodFrom).local().format('DD-MM-YYYY hh:mm a'),
        reservePeriodTo: moment.utc(this.editResponse.reservePeriodTo).local().format('DD-MM-YYYY hh:mm a'),
        barcode: matchedObject.barcode,
        customerBarcode: matchedObject.pickupCode,
        length: matchedObject.length,
        width: matchedObject.width,
        height: matchedObject.height
      });
     
      this.customerObj={
        firstName:matchedObject?.customers?.firstName,
        lastName:matchedObject?.customers?.lastName,
        Email:matchedObject?.customers?.email,
        phoneNumber:matchedObject?.customers?.phoneNumber
       }
      this.todayDate = new Date (matchedObject.reservePeriodFrom);
    
       
    }
    if (matchedObject && this.action && (this.action === 'process' || this.action === 'vault' || this.action === 'history' )) {
      let resFromMoment = null;
      let resToMoment = null;
      const resFrom = matchedObject.reservePeriodFrom ? matchedObject.reservePeriodFrom.split('T')[0] : '';
      resFromMoment = resFrom ? moment(resFrom, this.targetDate) : '';
      const resTo = matchedObject.reservePeriodTo ? matchedObject.reservePeriodTo.split('T')[0] : '';
      resToMoment = resTo ? moment(resTo, this.targetDate) : '';
      if (resFromMoment && resToMoment) {
        this.whentocollectvalue = `${moment(resFromMoment).format(this.formattedTargetDate)  } - ${  moment(resToMoment).format(this.formattedTargetDate)}`;
      } else {
        this.whentocollectvalue = `${resFrom  } - ${  resTo}`;
      }
    }
  }

   getOrderPlacedInVault(id) {
     this._partnerService.getPartnerVault().subscribe((res: any) => {
      this.orderToProcess = res;
      this.commonObject(res, id);
    });
  }

   getOrderInHistory(id) {
     this._partnerService.getPartnerOrderHistory().subscribe((res: any) => {
      this.orderToProcess = res;
      this.commonObject(res, id);
    });
  }

  executePlaceOrderWorkFlow(body: any){
    if (!body.locationId || body.locationId === '') {
      this._spinner.hide();
      return this._toastr.warning(this._translate.instant('selectlocation'), this._translate.instant('sorry'));
    }
    const announceBarcode = { ...body, action: 'announce', email: this.getEmail };
    this.attemptSaveOrder(announceBarcode,body);
  }
  attemptSaveOrder(announceBarcode,body){
    this._partnerService.saveNewpartnerOrder(announceBarcode, 'placeorder').subscribe({next:(res: any) => {
      if (res && res.error){
        this._spinner.hide();
        if(res.error === 'barcodeexists'){
          return this._toastr.error(this._translate.instant('barcodeexists'), this._translate.instant('sorry'));
        }
        if(res.error === 'pickupcodeexists'){
          return this._toastr.error(this._translate.instant('Pick up code already used by another active order'), this._translate.instant('sorry'));
        }
        this._toastr.error(this._translate.instant('System error'), this._translate.instant('sorry'));
      }
      else{
        this.reserveOrder(body,res);
      }
    },
    error:(err) => {
      this._spinner.hide();
      this._toastr.error(this._translate.instant('Failed to announce order !!'), this._translate.instant('Failed'));
      console.error(err);
    },
    complete:()=>{}
  });
  }
  reserveOrder(body,res){
    const reserveBarcode = { ...body, action: 'reserve', barcode: res?.barcode };
    this._partnerService.saveNewpartnerOrder(reserveBarcode, 'placeorder').subscribe({next:(result: any) => {
      this._spinner.hide();
      if (result && result.reservationFail) {
        this._toastr.warning(this._translate.instant('Announcement success, reservation failed'), this._translate.instant('Success'));
      } else {
        this._toastr.success(this._translate.instant('Partner order saved and reserved!!'), this._translate.instant('Success'));
        this._router.navigate([this.newOrderRoute]);
      }
    },error:(err) => {
      this._spinner.hide();
      console.error(err);
      this._toastr.success(this._translate.instant('Place order initiated !!'), this._translate.instant('Success'));
    },
    complete:()=>{}
    });
  }
  extractValue(barcode) {
    this._partnerService.getPartnerCustomer(barcode).subscribe((res: any) => {
      if (res.length === 0) {
        this.langaugeFetch(this.translateData, 'No customers were found');
        this._router.navigate(['partnertools/createBarcode']);
      }
      else {
        this.getEmail = res[0].Email;
        this.language = res[0].Language;
        this.customerObj= res[0];
      }
    });
  }


  openDatePicker(v) {
    if (v === 'reserveDateToInput'){
      this._renderer.selectRootElement(this.reserveDateToInput.nativeElement).click();
    }
    if (v === 'reserveDateFromInput'){
      this._renderer.selectRootElement(this.reserveDateFromInput.nativeElement).click();
    }
  }

  langaugeFetch(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        this._toastr.error(res);
      }
    );
  }
}
