import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../Service/partner.service';
import { AppService } from '../Service/app.service';
import { SpinnerService } from '../Service/spinner.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import * as moment from 'moment';
import { DataTableDirective } from 'angular-datatables';
import { Config } from 'protractor';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-maintenance-staff-list',
  templateUrl: './maintenance-staff-list.component.html',
  styleUrls: ['./maintenance-staff-list.component.scss']
})
export class MaintenanceStaffListComponent implements OnInit {
  addUserForm: any;
  submitted: boolean;
  partnerList: any=[];
  deleteId: string;
  langKey: any;
  partnerName: any;
  showSpinner = true;
  dtOptions:Config ={};
  // thus we ensure the data is fetched before rendering
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  languageList = [{language:'en',description:'english'},
    {language:'fr',description:'french'},
    {language:'nl',description:'dutch'}];
  constructor(private readonly _fb: FormBuilder,
     private readonly _router: Router,
     private readonly _partnerService: PartnerService,
     private readonly _langtransService: LangtransService,
     private readonly _appService: AppService,
     private readonly _toastr: ToastrService, 
     private readonly _spinner: SpinnerService) {
  }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.langKey = data;
    });
   
    this.fetchRecords();
  }
  fetchRecords(){
    let lastPage=0;  
    this._spinner.show();
    this.dtOptions = {
      retrieve: true,
      destroy: true,
      pagingType: 'full_numbers',
      pageLength: 50,
      displayStart: lastPage,
      search: false,
      serverSide: true,
      order: [[1, 'desc']],
      ordering: false,
      ajax: (dataTablesParameters: any, callback) => {
        lastPage=dataTablesParameters.start;
        this._partnerService.fetchMaintenanceUser( dataTablesParameters.length, dataTablesParameters.start)
          .subscribe((resp:any)=>{
            const header = resp.headers.get('X-Total-Count');
       
            this._spinner.hide();
      this.partnerList = resp.body;
      this.partnerList.forEach(postman => {
        this.languageList.forEach(lang => {
          if (postman.language === lang.language){
            postman.langDesc = lang.description;
          }
        });
      });
      for (let i = 0; i < this.partnerList.length; i++) {
        this.partnerList[i].lastLoginTime = this.partnerList[i].lastLoginTime ? moment.utc(this.partnerList[i].lastLoginTime).local().format('YYYY-MM-DD HH:mm:ss') : '';
      }
            callback({
              recordsTotal: header,
              recordsFiltered: header,
              data:[]
            });
          }, (err: any) => {
            console.error(err);
            this._spinner.hide();
          },
        );
      }
         
    };
  }
  getModalId(id, name) {
    this.deleteId = id;
    this.partnerName = name;
  }
  onDelete() {
    const obj = {
      id: this.deleteId
    };
    this._partnerService.deleteMaintenanceUser(obj).subscribe((res) => {
      if (res === 'Deleted successfully') {
        this.translator(this.langKey, 'User deleted successfully', true);
      }
      this.partnerList = 
      this.partnerList.filter(item => item.id !== this.deleteId);
    });
  }
  onEdit(partner) {
    this._router.navigate(['/maintenanceEditUser/', partner.id]);
  }
  addMaintenanceUser() {
    this._router.navigate(['/maintenanceAddUser']);
  }

  langaugeFetch(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        this._toastr.error(res);
      }
    );
  }
  translator(lang, key, status) {
    this._langtransService.TranslateData(lang, key).subscribe((res) => {
      if (status) {
        this._toastr.success(res);
      } else {
        this._toastr.error(res);
      }
    });
  }
}
