import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { ParcelService } from 'src/app/Service/parcel.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { DeliverypointsService } from 'src/app/Service/deliverypoints.service';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-postman-code',
  templateUrl: './postman-code.component.html',
  styleUrls: ['./postman-code.component.scss']
})
export class PostmanCodeComponent implements OnInit {
  postmanLoginForm: FormGroup;
  list: any[];
  capabilityCheck = false;
  dropSuccess = true;
  languageList = [
    {value:'en',label:'english'},
    {value:'fr',label:'french'},
    {value:'nl',label:'dutch'}
  ];
  dropdownValue: any[];
  submitted: boolean;
  tableData: [];
  isMoreThanZero: boolean;
  language: any;
  errorCode: string;
  dpList: any = [];
  lockerDropdownSettings: IDropdownSettings;
  lockerSelectedItems: any = [];
  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _parcelService: ParcelService,
    private readonly _toastr: ToastrService,
    private readonly _appService: AppService,
    private readonly _langService:LangtransService,
    private readonly _spinner: SpinnerService,
    private readonly _dpService: DeliverypointsService,
    private readonly _translate:TranslateService,
    private readonly _http: HttpClient) {
    this.list =
      [
        { name: 'CAN_COLLECT', checked: false },
        { name: 'CAN_DELIVER', checked: false }
      ];
  }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.language=data;
      this._translate.use(this._translate.currentLang);
      this._http.get(`/assets/i18n/${data}.json`).subscribe((
        translations: any) => {
        this.lockerDropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'lockerName',
          selectAllText: translations['selectAll'],
          unSelectAllText: translations['UnSelectAll'],
          itemsShowLimit: 1,
          allowSearchFilter: true
        };
      });
      
    });
    
    this.postmanLoginForm = this._formBuilder.group({
      name: ['', Validators.required],
      matriculeID: 
      ['',[Validators.required, Validators.pattern('[A-Za-z0-9]*')]],
      language: ['', Validators.required],
      dpId: ['', Validators.required],
    });
    this._spinner.show();
    this._dpService.fetchMany({}).then((dpList) => {
      this.dpList = dpList;
      this._spinner.hide();
    });
  }
  translateData(key){
    this._langService.TranslateData(this.language,key).subscribe((
      translatedValues:string)=>{
      if(key === 'SuccessCode'){
        this._toastr.success(translatedValues);
      }
      if(key ==='LoginCodeError' || key ==='NonBpostCodeError'){
        this._toastr.error(translatedValues);
      }
      
    });
  }

  shareCheckedList(item: any[]) {
    if (item.length > 0) {
      this.capabilityCheck = true;
      this.dropSuccess = true;
    } else {
      this.capabilityCheck = false;
      this.dropSuccess = false;
    }
    this.dropdownValue = item;
  }

  get formValidators() {
    return this.postmanLoginForm.controls;
  }
  postcallData() {
    this._spinner.show();
    const obj = {
      postman: {
        matriculeId: 
        this.postmanLoginForm.controls.matriculeID.value.toUpperCase(),
        name: this.postmanLoginForm.controls.name.value,
        language: this.postmanLoginForm.controls.language.value,
        capabilities: this.dropdownValue
      },
      deliveryPoints: this.postmanLoginForm?.controls?.dpId?.value
    }
    this._parcelService.postmanLoginCode(obj).subscribe({next:(res: any) => {
      const nonBpostCode = res.deliveryPoints?.filter((d) => !d.code);
      this.tableData = res.deliveryPoints?.filter((d) => d.code);
      this.isMoreThanZero = res.deliveryPoints?.length > 0;
      if(nonBpostCode?.length > 0){
        this.translateData('NonBpostCodeError');
      }
      if(!this.isMoreThanZero){
        this.translateData('LoginCodeError');
      }else if(this.tableData.length > 0){
        this.translateData('SuccessCode');
      }
      this._spinner.hide();
    },error:error => {
      console.error(error);
      this._spinner.hide();
    },
    complete:()=>{}
    });

  }
  submitData() {
    this.submitted = true;
    if (this.submitted && this.capabilityCheck) {
      this.dropSuccess = true;
    } else {
      this.dropSuccess = false;
    }
    if (this.postmanLoginForm.valid && this.dropdownValue) {
      this.postcallData();
    }
  }
}
