import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './Service/user.service';
import { AuthorizationService } from './Service/authorization.service';
import { map } from 'rxjs/operators';
import { AppService } from './Service/app.service';

@Injectable({
  providedIn: 'root'
})
export class PartnertoolauthGuard implements CanActivate {
  userDetails: any;
  status: boolean;
  allowCustomer: boolean;

  constructor(
    private readonly _userService: UserService,
    private readonly _authorizationService: AuthorizationService,
    private readonly _router: Router,
    private readonly _appService: AppService,
    private readonly router: Router,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): 
    Observable<boolean|UrlTree> | boolean | UrlTree {

    const t = window.localStorage.getItem('token');
    let flag = false;
    if (t) {
      flag = true;
    }

    if (this._authorizationService.isLoggedIn || flag) {
      const user = this._authorizationService.currentUser;
      if (user) {
        const userEmail = user.email || user.Email;
        const filterVal = {
          email: userEmail.toLowerCase(),
        };

        return this._userService.fetchMany(filterVal).pipe(map((result: any[]) => {
          this._userService.me().subscribe((res) => {
            this.allowCustomer = res['partner']['allowOwnCustomers']
          })
          this.userDetails = result[0].disablePartner ? result[0].disablePartner : false;
          this._appService.partner = result[0].partner ? result[0].partner : null;
          const partnerToolRoute = state.url.startsWith('/partnertools');
          const partnerCustomerRoute = state.url.startsWith('/partnertools/customer');
          if (partnerToolRoute) {
            if (this.userDetails) {
              this._router.navigate(['user/forbidden']);
              return false;
            }
            if (partnerCustomerRoute && this.allowCustomer) {
              this._router.navigate(['user/forbidden']);
              return false;
            }

          }
          return true;
        }));
      } else {
        this._authorizationService.loadDdsUser()
      }
      return true;
    }
    this._router.navigate(['']);
    return false;
  }
}
