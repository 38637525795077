import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-partner-info-menu',
  templateUrl: './partner-info-menu.component.html',
  styleUrls: ['./partner-info-menu.component.scss'],
})
export class PartnerInfoMenu {
  @Input() partner;

}
