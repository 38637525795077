import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-partner-edit-postman-codes',
  templateUrl: './partner-edit-postman-codes.component.html',
  styleUrls: ['./partner-edit-postman-codes.component.scss']
})
export class PartnerEditPostmanCodesComponent implements OnInit {

  showSpinner = false;
  partnerDetails: any;
  partnerId: any;

  settingForm: FormGroup;

  settingName = 'postmans-codes-method';

  constructor(public _activatedRoute: ActivatedRoute,
    private readonly _toastr: ToastrService,
    private readonly _partnerService: PartnerService,
    private readonly _router: Router,
    private readonly _formBuilder: FormBuilder,
    private readonly _translate: TranslateService
  ) {
    this.settingForm = this._formBuilder.group({
      method: ['standard', Validators.required],
    });
  }

  ngOnInit(): void {
    const partnerId = this._activatedRoute.snapshot.params.id;
    this.partnerId = partnerId;
    this.getPartnerDetails(partnerId);
  }

  //Fech partner details
  getPartnerDetails(partnerId) {
    this.showSpinner = true;
    this._partnerService.fetchPartnerDetails(partnerId).subscribe({
      next:res => {
        this.partnerDetails = res;
        // Current setting value
        const currentSetting = this.partnerDetails.settings.find(
          s => s.key === this.settingName);
        if (currentSetting) {
          this.settingForm.patchValue({
            method: currentSetting.value
          });
        }

        this.showSpinner = false;
      },
      error:err => {
        this.showSpinner = false;
        this._toastr.error(this._translate.instant(
          'Failed to fetch partner details !!'), 
        this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
  });
  }

  onSubmit() {
    if (this.settingForm.valid) {
      this._partnerService.editPartnerSetting(
        this.partnerId, this.settingName, {
          method: this.settingForm.controls.method.value
        }).subscribe({next:(res) => {
        if (res) {
          this._toastr.success(this._translate.instant(
            'Partner Updated Successfully!'), 
          this._translate.instant('Success'));
        }
      }, error:(err: any) => {
        console.error(err);
        this._toastr.info(err.error.error.message, 
          this._translate.instant('Failed'));
      },
      complete:()=>{}
    });
    }
  }

  goToPartnerDetails() {
    this._router.navigate(['/partners/', this.partnerId]);
  }
}
