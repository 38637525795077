<app-header></app-header>
<div #myDiv [appViewController]="{users:'showGroup'}">
  <div class="container-fluid ">
    <div class="row my-4 float-end px-3" >
      <div class="col-12 ">
      <button class="btn btn-outline-danger"  (click)="goTogroupadd()"
        [appViewController]="{users:'createGroup'}">{{'Add rights group'|translate}}</button>
    </div>
  </div>
   </div> 
   <div class="clearfix"></div>
    <div class="container-fluid">
          <div class="table-responsive px-3" id="userGrpTable">
            <table class="table table-bordered" aria-describedby="userGrpTable">
              <thead class="table-dark">
                <tr class="text-center">
                  <th scope="col">{{'Group Name'|translate}}</th>
                  <th scope="col">{{'Functions'|translate}}</th>
                  <th scope="col">{{'Actions'|translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of userGrop">
                  <td class="text-center">
                    {{item.title}}
                  </td>
                  <td class="d-flex flex-row text-nowrap">
                <tr *ngFor="let element of item.checklistData; let i = index">
                  <div class="table-style">
                    {{titleKeys[i] | titlecase}}</div>
                  <div *ngFor="let key of getObjectKeys(element,i)">
                    <td class="d-block text-wrap border" >
                      <span class="text-adjustment" matTooltip="{{key}}">{{key | titlecase}} </span> <img class="float-end"
                        src="../../../../assets/img/greencheck.png" alt="" *ngIf="element[titleKeys[i]][key]" width="16"
                        height="16" >
                      <img src="../../../../assets/img/cancel.png" class="float-end" alt="" *ngIf="!element[titleKeys[i]][key]" width="16"
                        height="16" >
                    </td>
                  </div>
                </tr>
                </td>
                <td class="align-middle border">
                  <div class="text-center mx-2">
                    <button type="button"class="btn btn-success me-2 px-4"
                      *ngIf="item.title != 'Administrators'" (click)="goTogroupedit(item.title)"
                      [appViewController]="{users:'modifyGroup'}">{{'EDIT'| translate}}</button>
                    <button type="button" class="btn btn-danger delete1"
                      *ngIf="item.title != 'Administrators'" (click)="savedelete(item.title)" data-toggle="modal"
                      data-target="#deleteUserModal" type="button" data-toggle="modal" data-target="#deleteUserModal"
                      [appViewController]="{users:'deleteGroup'}">{{'DELETE'| translate}}</button>
                  </div>
                </td>
                </tr>
              </tbody>
            </table>
            <br>
          </div>
    </div>
  </div>
  <div class="container">
  <div class="modal fade" tabindex="-1" id="deleteUserModal" role="dialog" aria-labelledby="deleteUserModal"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title mb-0">{{'Delete'|translate}}</h3>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <p>{{'Are you sure you want to delete the user group'|translate}}?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Cancel'|translate}}</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteUser()">{{'Confirm'|translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
