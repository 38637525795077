import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { LogsService } from '../../../Service/logs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { map } from 'rxjs/operators';
import { LangtransService } from '../../../Service/langtrans.service';
import { AppService } from '../../../Service/app.service';
import * as moment from 'moment';
import { Config } from 'datatables.net';
declare const bootstrap:any;

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit, AfterViewInit {
  id: any;
  queryStringEvent: any;
  logdetails: any=[];
  details: any;
  selectedValue: any;
  public filterForm;

  dtOptions: Config ={};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;
  showUnauthorized = false;
  selectBothdates = false;
  lang: any;
  maxDate: Date;
  isReload=false;
  @ViewChild('orderDateFromInput') orderDateFromInput:ElementRef;
  @ViewChild('orderDateToInput') orderDateToInput:ElementRef;
  invalidDateRange: boolean;
  totalRecords: any;
  constructor(
    public _activatedRoute: ActivatedRoute,
    private readonly _logservice: LogsService,
    public _router: Router,
    private readonly _toastr: ToastrService,
    private readonly _appService: AppService,
    private readonly _langtransService: LangtransService,
    private readonly _spinner: SpinnerService,
    private readonly _renderer:Renderer2
  ) {
    this.maxDate=new Date();
  }

  ngOnInit() {
    this.fetchLogs();
    this.filterForm = new FormGroup({
      logEvent: new FormControl('', []),
      logDateFrom: new FormControl('', []),
      logDateTo: new FormControl('', []),
    });
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.lang = data;
    });
    this.id = this._activatedRoute.snapshot.paramMap.get('id');
   

  }
  fetchLogs(){
    let lastPage=0;
    this._spinner.show();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      retrieve: true,
      displayStart: lastPage,
      destroy: true,
      search: false,
      serverSide: true,
      order: [],
      ordering: false,

      ajax: (dataTablesParameters: any, callback) => {
        lastPage=dataTablesParameters.start;
        let queryParams;
        if(this.isReload){
           queryParams = {
            event: this.filterForm.value.logEvent || undefined,
            fromDate: moment(this.filterForm.value.logDateFrom).format(
              'YYYY-MM-DD') || undefined,
            toDate: moment(this.filterForm.value.logDateTo).format(
              'YYYY-MM-DD') || undefined,
              limit:dataTablesParameters.length,
              offset:dataTablesParameters.start
          };
        }
     else{
      queryParams = {
    
          limit:dataTablesParameters.length,
          offset:dataTablesParameters.start
      };
     }
        this._logservice.fetchLogs(this.id,queryParams)
          .pipe(map((resArray: any) => {
            this.totalRecords = resArray.headers.get('X-Total-Count');
            return resArray.body.map((resObj: any) => {
              const dateVal = resObj.date.replace('Z', '');
              const cloneObj = { ...resObj };
              cloneObj.date = dateVal;
              return cloneObj;
            });
    
          }))
          .subscribe({
            next:(res: any) => {
              if (res) {
               
                this.logdetails = res;
                if (this.logdetails.length && this.logdetails.length > 0) {
                  this.dtTrigger.next(null);
                } else {
                  this.logdetails = [];
                  this.dtTrigger.next(null);
                }
              } else {
                this.langaugeFetch(this.lang,'No Matching Data Found !!','info');
              }
              callback({
                recordsTotal:  this.totalRecords ,
                recordsFiltered:  this.totalRecords ,
                data:[]
              });
              this._spinner.hide();
            },
           error: err => {
              console.error(err);
              this._spinner.hide();
            },
            complete:()=>{}
      });
      
      }
    };
 
  }
  ngAfterViewInit() {
    const divElement = this.myDiv.nativeElement;
    const divstyle = window.getComputedStyle(divElement);
    const display = divstyle?.display;
    if (display === 'none') {
      this.showUnauthorized = true;
    }
    const tooltipTriggerList=document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach(function(tooltipTriggerEl){
      return new bootstrap.Tooltip(tooltipTriggerEl);
    })
  }

  onReset() {
    this.invalidDateRange = false;
    const currentUrl = this._router.url;
    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate([currentUrl]);
  }
  async onSubmit() {

    // Checking if both dates selected or not
    if (this.filterForm.value.logDateFrom === '' && 
      this.filterForm.value.logDateTo === '') {
      this.selectBothdates = true;
    }
    if (this.filterForm.value.logDateFrom !== '') {
      if (this.filterForm.value.logDateTo === '') {
        this.selectBothdates = true;
      }
      else {
        this.selectBothdates = false;
      }
    }

    if (this.filterForm.value.logDateTo !== '') {
      if (this.filterForm.value.logDateFrom === '') {
        this.selectBothdates = true;
      }
      else {
        this.selectBothdates = false;
      }
    }
    this.invalidDateRange = 
    (this.filterForm.value.logDateFrom > this.filterForm.value.logDateTo) && 
    this.filterForm.value.logDateTo !== '';
    if (!this.selectBothdates && !this.invalidDateRange) {
      this.logdetails=[];
      this.rerender();
      this.isReload=true;
      await this.fetchLogs();
      
    }
  }
  rerender(): void {
    this.dtElement?.dtInstance?.then(dtInstance => {
      dtInstance.ajax.reload();
      });
    }
  getToday(): string {
    return new Date().toISOString().split('T')[0];
  }
  getMeta = (meta: any) => {
    return JSON.stringify(meta);
  };

  langaugeFetch(lang, key, toastrstatus) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus === 'info') {
          this._toastr.info(res);
        }
      }
    );
  }
  openDatePicker(v){
    if(v==='orderDateToInput'){
      this._renderer.selectRootElement(
        this.orderDateToInput.nativeElement).click();
    }
    if(v==='orderDateFromInput'){
      this._renderer.selectRootElement(
        this.orderDateFromInput.nativeElement).click();
    }
  }

  
}
