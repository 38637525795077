<app-header></app-header>
<div [appViewController]="{deliveryPoints:'showList'}" #myDiv>
  <div class="bg-info">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <i class="details btn center mx-auto" type="button" data-toggle="collapse" data-target="#collapseDashBoard"
          aria-expanded="false" aria-controls="collapseDashBoard">
          <i class="fa fa-chevron-down fa-lg text-white"></i>
        </i>
      </div>
    </nav>
  </div>
  <div class="box collapse mt-4" id="collapseDashBoard">
    <div class="container">
      <div class="row">
        <div class="col-12 px-0">
          <div class="card bg-white mb-3">
            <div class="card-body">
              <div class="row">
                <div class="col-5">
                  <div class="row">
                    <div class="col">
                      <h3 class="font mb-0">{{'Commisioned'|translate}}</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="textSize bg-dark text-center text-light rounded">
                        <strong>
                          {{commissioned}} / {{notCommissioned}}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="row">
                    <div class="col">
                      <h3 class="font mb-0">{{'Online'|translate}}</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="textSize text-success bg-dark text-center rounded">
                        <strong>
                          {{online ? online : '-'}}
                        </strong>  
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="row">
                    <div class="col">
                      <h3 class="font mb-0">{{'Offline'|translate}}</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="textSize text-danger bg-dark text-center rounded">
                        <strong>
                          {{offline ? offline : '-'}}
                        </strong>  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <div class="container shadow p-3 mb-5 bg-body rounded" >
    <div *ngIf="showDownload">
      <div class="row float-end">
        <div class="col-12" >
          <input type="image" src="/assets/img/redIcon.png" id="exportCSV"
                style="margin-right:20px; margin-top:10px; margin-bottom: 10px;" name="submit" width="28" height="28"
                alt="submit" (click)="exportToCSV()" [appViewController]="{parcels:'exportList'}">
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
    <div class="row">
      <div class="col-12">
        <form [formGroup]="filterForm">
          <div class="row mb-2">
            <div class="col-4">
              <label for="lockername" class="form-label">{{'Locker Name' | translate}}</label>
              <input class="form-control" formControlName="lockername" name="lockername"
                placeholder="{{'Locker Name'|translate}}">
            </div>
            <div class="col-4">
              <label for="address" class="form-label">{{'Street'|translate}}</label>
              <input class="form-control" formControlName="street" name="street" placeholder="{{'Address'|translate}}">
            </div>
            <div class="col-4">
              <label for="lockername" class="form-label">{{'AC Code'|translate}}</label>
              <ng-multiselect-dropdown class="form-control" formControlName="acCode"
                [placeholder]="'-All-'|translate" [settings]="acCodedropdownSettings" [data]="acCodedropdownList"
                [(ngModel)]="acCodeselectedItems">
              </ng-multiselect-dropdown>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <label for="lockername" class="form-label">{{'Locker ID'|translate}}</label>
              <input
                class="form-control"
                formControlName="lockerid"
                name="lockerid"
                placeholder="{{'Locker ID'|translate}}">
            </div>
            <div class="form-group col-md-4">
              <label for="acCode" class="form-label">{{'Hardware Provider'|translate}}</label>
              <select
                class="form-select"
                formControlName="hardwareProvider"
                name="hardwareProvider">
                <option selected value="">{{'Choose Provider...'|translate}}</option>
                <option value="ByBox">{{'ByBox'|translate}}</option>
                <option value="Swipbox">{{'Swipbox'|translate}}</option>
                <option value="DBI">{{'DBI'|translate}}</option>
                <option value="KEBA">{{'KEBA'|translate}}</option>
              </select>
            </div>
            <div class="col-4">
              <label for="formGroupExampleInput2" class="form-label">{{'Locker Type'|translate}}</label>
              <select 
                class="form-select"
                formControlName="lockertype"
                name="lockertype">
                <option selected value="">{{'Choose Type...'|translate}}</option>
                <option value="Lean">{{'Lean Locker'|translate}}</option>
                <option value="Cubee">{{'Cubee Locker'|translate}}</option>
              </select>
            </div>
          </div>
          <div class="collapse" id="collapseAdvancedFilter">
            <div class="row my-2">
              <div class="col-4">
                <label for="online"  class="form-label">{{'Online'|translate}}</label>
                <select class="form-select" formControlName="online" name="online">
                  <option selected value="">{{'Choose Option...'|translate}}</option>
                  <option value="true">{{'Yes'|translate}}</option>
                  <option value="false">{{'No'|translate}}</option>
                </select>
              </div>
                <div class="col-4">
                  <label for="Active" class="form-label">{{'Active'|translate}}</label>
                  <select class="form-select" formControlName="active" name="active">
                    <option selected value="">{{'Choose Option...'|translate}}</option>
                    <option value="true">{{'Active'|translate}}</option>
                    <option value="false">{{'Inactive'|translate}}</option>
                  </select>
                </div>
              <div class="col-4">
                <label for="technology" class="form-label">{{'Technology'|translate}}</label>
                <select class="form-select" formControlName="technology" name="technology">
                  <option selected value="">{{'Choose Option...'|translate}}</option>
                  <option value="awsiot">{{'aws-iot'|translate}}</option>
                  <option value="others">{{'others'|translate}}</option>
                </select>
              </div>
              </div>
              <div class="row">
                <div class="col-md-4" *ngIf="partnerDropdown">
                  <label for="partnerId" class="form-label">{{'Partner'|translate}}</label>
                  <ng-multiselect-dropdown
                    class="form-control"
                    formControlName="partnerId"
                    [placeholder]="'Choose Partner...'|translate"
                    [settings]="partnerNamedropdownSettings"
                    [data]="partnerNamedropdownList"
                    [(ngModel)]="partnerNameselectedItems"
                    (onSelect)="onItemSelect($event)"
                    (onSelectAll)="onSelectAll($event)">
                  </ng-multiselect-dropdown>
                </div>
                <div class="form-group col-md-4">
                  <label for="Location Holiday" class="form-label"> {{'Holiday Date'|translate}}</label>
                    <div class="input-group">
                      <input
                        type="text"
                        placeholder="dd-mm-yyyy"
                        class="form-control"
                        formControlName="holidayDate"
                        #holidayDateInput
                        bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                          <span class="input-group-text">
                            <i class="fas fa-calendar-alt" id="holidayDate" (click)="openDatePicker('holidayDateInput')"></i>
                          </span>
                    </div>
                </div>
                <div class="form-group col-md-4">
                  <label for="online" class="form-label">{{'Soft Reservation'|translate}}</label>
                  <select
                    class="form-control form-select"
                    formControlName="acceptSoftReservations"
                    name="acceptSoftReservations">
                    <option selected value="">{{'Choose Option...'|translate}}</option>
                    <option value="true">{{'Yes'|translate}}</option>
                    <option value="false">{{'No'|translate}}</option>
                  </select>
                </div>
              </div>
          </div>
        </form>
        <div class="row mb-2 mt-3">
          <div class="col text-center">
            <button class="btn btn-danger me-2" (click)="onSubmit()" type="submit">{{'Filter'|translate}}</button>
            <button class="btn btn-danger me-2" type="button"
              data-toggle="collapse"
              data-target="#collapseAdvancedFilter"
              aria-expanded="false"
              aria-controls="collapseAdvancedFilter">
              {{ 'Advanced Filters'|translate}}
            </button>
            <button
            id="resetBtn"
              class="btn btn-outline-danger"
              type="submit"
              (click)="onReset()">{{'Reset'|translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 px-0">
        <div class="table-responsive" id="lockerListTable">
          <table aria-describedby="lockerListTable"
            datatable
            [dtOptions]="dtOptions"
            *ngIf="result"
            class="table table-bordered table-hover row-border hover">
            <thead class="table-dark text-center">
              <tr>
                <th scope="col" class="text-center align-middle">{{'AC Code'|translate}}</th>
                <th scope="col" class="text-center align-middle">{{'Locker ID'|translate}}</th>
                <th scope="col" class="text-center align-middle">{{'Name'|translate}}</th>
                <th scope="col" class="text-center align-middle">{{'Location'|translate}}</th>
                <th scope="col" class="text-center align-middle">{{'Locker Type'|translate}}</th>
                <th scope="col" class="text-center align-middle">{{'Last Ping Time'|translate}}</th>
                <th scope="col" class="text-center align-middle">{{'Operational Status'|translate}}</th>
              </tr>
            </thead>
            <tbody *ngIf="result?.length !== 0">
              <tr
                *ngFor="let res of result"
                [ngClass]="{'table-danger':(!res.connectivity.online || isAlert)&& res.provider !=null && res.provider.toLowerCase() != 'lean', 'table-success': (res.connectivity.online && !isAlert) && res.provider !=null && res.provider.toLowerCase() != 'lean', '': res.provider !=null && res.provider.toLowerCase() == 'lean'}">
                <td *ngIf="res.acCode">{{res.acCode}}</td>
                <td *ngIf="!res.acCode"></td>
                <td>{{res.lockerId}}</td>
                <td *ngIf="res.lockerName">
                  <a [routerLink]="['/deliverypoints/', res.id]" target="_blank"
                    rel="noreferrer noopener">{{res.lockerName}}</a></td>
                <td *ngIf="!res.lockerName">
                  <a [routerLink]="['/deliverypoints/', res.id]" target="_blank"
                    rel="noreferrer noopener">Unnamed</a></td>

                <td *ngIf="!res.address.street || !res.address.streetNumber || !res.address.postalCode"></td>
                <td *ngIf="res.address.street || res.address.streetNumber || res.address.postalCode">
                  {{res.address.street}},{{res.address.streetNumber}},{{res.address.postalCode}},{{res.address.municipality}}
                </td>

                <td *ngIf="!res.type"></td>
                <td *ngIf="res.type">{{res.type}}</td>

                <td>{{res.connectivity.updatedAt | utctolocaldate:'DD/MM/YYYY HH:mm:ss'}}</td>
                <td *ngIf="!res.operations.lockerOperationActive">false</td>
                <td *ngIf="res.operations.lockerOperationActive">{{res.operations.lockerOperationActive}}</td>
              </tr>
            </tbody>
              <tbody *ngIf="result?.length === 0">
            <tr>
              <td colspan="7" class="text-center align-middle no-data-available">{{'No data!' | translate}}</td>
            </tr>
            </tbody>
          </table>
          <br>
        </div>
      </div>
    </div>
  </div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
