import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { FormGroup } from '@angular/forms';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.scss']
})
export class PartnerDetailsComponent implements OnInit {

  partnerDetails: any;
  partnerDeliverypointDetails: any;
  dpdsByAcCodeData: [];
  dpdsByPostalcodeData: [];
  partnerForm: FormGroup;
  submitted: boolean;
  partnerId: any;
  constructor(public _activatedRoute: ActivatedRoute,
    private readonly _toastr: ToastrService,
    private readonly _partnerService: PartnerService,
    private readonly _router: Router,
    private readonly _spinner: SpinnerService,
    private readonly _translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    const partnerId = this._activatedRoute.snapshot.params.id;
    this.partnerId = partnerId;
    this.getPartnerDetails(partnerId);
  }

  //Fech partner details
  getPartnerDetails(partnerId) {
    this._spinner.show();
    this._partnerService.fetchPartnerDetails(partnerId).subscribe({
      next:(res) => {
        this.partnerDetails = res;
        this._spinner.hide();
        this.getPartnerDeliverypointDetails(partnerId);

        this.partnerForm?.patchValue({
          partnerName: this.partnerDetails?.name,
          country: this.partnerDetails?.country,
          active: this.partnerDetails?.active

        });
      },
      error:(err) => {
        this._spinner.hide();
        this._toastr.error(this._translate.instant(
          'Failed to fetch partner details !!'), 
        this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
    }
    );
  }

  //Fech partner delivery point details
  getPartnerDeliverypointDetails(partnerId) {
    this._spinner.show();
    this._partnerService.fetchPartnerDeliverypointDetails(partnerId).subscribe(
      {
      next:(res) => {
        this.partnerDeliverypointDetails = res;
        this.dpdsByAcCodeData = 
        this.partnerDeliverypointDetails.dpdsByAcCode.list;
        this.dpdsByPostalcodeData = 
        this.partnerDeliverypointDetails.dpdsByPostalcode.list;
        this._spinner.hide();
      },
      error:(err) => {
        this._spinner.hide();
        this._toastr.error(this._translate.instant(
          'Failed to fetch partner deliverypoint details !!'), 
        this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
  });
  }
  goToPartnerList() {
    this._router.navigate(['/partners']);
  }

}
