<div class="mx-5 mt-5" [appViewController]="{partnerTool:'viewPartnerTool'}">
    <h2 class="text-danger">{{'History'|translate}}</h2>

    <div class="mt-5">
        <div class="table-responsive">
            <table class="table table-bordered">
                <caption></caption>
                <thead>
                    <tr>
                        <th scope="col" colspan="5">{{'Overview History'|translate}}</th>
                    </tr>
                </thead>
                <thead>
                    <tr class="table-info">
                        <th scope="col" >{{'Name'|translate}}</th>
                        <th scope="col" >{{'Barcode'|translate}}</th>
                        <th scope="col" >{{'Status'|translate}}</th>
                        <th scope="col" >{{'Picked up'|translate}}</th>
                        <th scope="col" >{{'Options'|translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let value of orderHistoryResponse; let i = index">
                        
                        <td>
                            <div class="d-flex">
                                {{value.customers | fullName | translate }},
                                <span class="ml-2"> {{value.customers?.email ||'-'}}</span>
                            </div>
                        </td>
                        <td>{{value.barcode}}</td>
                        <td>{{'Package collected from the safe'|translate}}</td>
                        <td>{{value.pickedAt}}</td>
                        <td><button class="btn btn-danger" id="viewPage" (click)="viewPage(value)">{{'View'|translate}}</button></td>
                    </tr>
                    <tr *ngIf="!orderFound && orderHistoryResponse?.length == 0">
                        <td colspan="5" class="text-center">{{'No Orders Were found'|translate}}</td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
