import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DeliverypointsService } from '../../Service/deliverypoints.service';
import { LogsService } from '../../Service/logs.service';
import { AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../../Service/app.service';
import { ReportsService } from '../../Service/reports.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { ToastrService } from 'ngx-toastr';
import { LangtransService } from 'src/app/Service/langtrans.service';

@Component({
  selector: 'app-lockerdownreport',
  templateUrl: './lockerdownreport.component.html',
  styleUrls: ['./lockerdownreport.component.scss']
})
export class LockerdownreportComponent implements OnInit {
  lockerdownForm: any;
  filterCriteria: { id: number, location: string }[] = [];
  locationdropdownSettings: IDropdownSettings;
  logdetails: any;
  resultarray: any[];
  duration: any;
  locationdropdownList: any[];
  locationDropdownSetttings: IDropdownSettings;
  locationId: any;
  location: any;
  list: { id: number; location: string; }[];
  fromDate: any;
  toDate: any;
  Search: any;
  @ViewChild('logDateFromInput') logDateFromInput: ElementRef
  @ViewChild('logDateToInput') logDateToInput: ElementRef
  maxDate = new Date()
  selectFields: string;
  //new objs
  locationSelectedItems = [];
  startDownData: any[];
  endDownData: any[];
  startDown: any[];
  endDown: any[];
  differenceArray: number[] = [];
  isFromDate=false;
  isToDate=false;
  translateData: any;
  noFound: string;
  //end new objs
  constructor(
    private readonly _http: HttpClient,
    private readonly _appService: AppService,
    private readonly _reportsService: ReportsService,
    private readonly _deliverypointsService: DeliverypointsService,
    private readonly _logservice: LogsService,
    private readonly _datePipe: DatePipe,
    private readonly _renderer: Renderer2,
    private readonly _spinner: SpinnerService,
    private readonly _langtransService:LangtransService,
    private readonly _toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((resData: any) => {
      this.translateData = resData
      this._http.get(`/assets/i18n/${resData}.json`).subscribe((data: any) => {
       
        this.locationDropdownSetttings = {
          singleSelection: false,
          itemsShowLimit: 1,
          allowSearchFilter: true,
          searchPlaceholderText: data.Search,
          noDataAvailablePlaceholderText: data.nodataText,
          selectAllText: data.selectAll,  
        };
      });
    });
    this.lockerdownForm = new FormGroup({
      dropDown: new FormControl('',[]),
      logDates: new FormGroup({
        logDateFrom: new FormControl('', []),
        logDateTo: new FormControl('', []),
      }, [this.dateRangeValidator]),
    })
    this.fetchresponse();
  }
  fetchresponse() {
    this._spinner.show();
    this._deliverypointsService.fetchMany({
      provider: 'bpost'
    })
      .then(res => {
        this._spinner.hide();
        this.list = res.map(locker => {
          return { id: locker.id, text: locker.lockerName, checked: false };
        });
        this.locationdropdownList = this.list;
      })
      .catch(ex => {
        this._spinner.hide();
      });
  }
  onSelectAll(items: any) {
  }
  onItemSelect(item: any) {
  }
  onItemDeSelect(items: any) {
  }
  onExport() {
    this.selectFields = '';
    this.isFromDate = false;
    this.isToDate = false;
    const locationSelected = this.lockerdownForm.value.dropDown?.length > 0;
    const dateFrom = this._datePipe.transform(this.lockerdownForm.controls['logDates'].value.logDateFrom, 'yyyy-MM-dd');
    const dateTo = this._datePipe.transform(this.lockerdownForm.controls['logDates'].value.logDateTo, 'yyyy-MM-dd');
    const isValidLocation = locationSelected && (!dateFrom && !dateTo);
    const isValidDateRange = dateFrom && dateTo && !locationSelected;
    const isValidDateAndLocation = dateFrom && dateTo && locationSelected;
    if (isValidLocation || isValidDateRange || isValidDateAndLocation) {
      this.runExport();
      this.selectFields = '';
      return;
    }
    if (!dateFrom && !dateTo && !locationSelected) {
      this.selectFields = 'selectFieldLockerdownReport';
      this.langaugeFetching(this.translateData, this.selectFields);
    } else {
      this.isFromDate = !dateFrom;
      this.isToDate = !dateTo;
    }
   }
  langaugeFetching(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (this.selectFields !== 'selectFieldLockerdownReport') {
          this._toastr.warning(res)
          this.selectFields='';
        }
        if (this.selectFields === 'selectFieldLockerdownReport') {
          this.noFound ='';
          this._toastr.error(res)
        }
      }
    )
  }
  runExport() {
    if(this.lockerdownForm.invalid){
      return;
    }
    this._spinner.show();
    const lockerDown = this.lockerdownForm.value.dropDown
    console.log({lockerDown})

    const selectedIds = lockerDown?.map(selection => selection.id)

    if (this.lockerdownForm.controls['logDates'].value.logDateFrom) {
      const Date1 = new Date(this.lockerdownForm.controls['logDates'].value.logDateFrom).setHours(0, 0, 0)
      this.fromDate = this._datePipe.transform(Date1, 'yyyy-MM-dd HH:mm:ss');
    }
    else { this.fromDate = null }
    if (this.lockerdownForm.controls['logDates'].value.logDateTo) {
      const Date2 = new Date(this.lockerdownForm.controls['logDates'].value.logDateTo).setHours(23, 59, 59)
      this.toDate = this._datePipe.transform(Date2, 'yyyy-MM-dd HH:mm:ss');
    }
    else { this.toDate = null; }


    this._reportsService.lockerDown({
      ids: selectedIds,
      fromdate: this.fromDate,
      todate: this.toDate
    })
    .subscribe({
      next:(res: any) => {
      this._spinner.hide();
      if(res.length === 0){
        this.noFound = 'NorecordsFound'
        this.selectFields = ''
        this.langaugeFetching(this.translateData, this.noFound);
        return;
      }
     this.noFound = ''
      const fields = ['id', 'terminalid', 'startdate', 'enddate', 'reason', 'notified', 'duration', 'title']
      const Today = this._datePipe.transform(new Date(), 'dd-MM-yyyy')
      const downtimeReport = `Downtime_Report_${  Today  }.xls`
      this.exportToExcel(fields, res, downtimeReport)
    }, error:err => {
       this.selectFields = ''
      this._spinner.hide();
    },
    complete:()=>{}
  })
  }

  exportToExcel(heading, data: any[], filename: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const colwidth = heading.map(() => ({ wch: 25 }));
    ws['!cols'] = colwidth; // Adjust column widths if needed
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const textColor = { font: { color: { rgb: '0000FF' } } };
    Object.keys(ws).forEach((cell) => {
      if (cell.charAt(0) === 'A') {
        ws[cell].s = textColor;
      }
    });
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${filename}.xlsx`);
  }
  getToday(): string {
    return new Date().toISOString().split('T')[0];
  }
  convertSeconds(seconds: number): string {
    let days = null;
    if (seconds > 86400) {
      days = Math.floor(seconds / 86400);
      days = days + ' days,';
    }
    const date = new Date(null);
    date.setSeconds(seconds);
    if (days) {
      return `${days} ${date.toISOString().substring(11, 19)}`;
    } else {
      return date.toISOString().substring(11, 19);
    }
  }

  get formValidators() {
    return this.lockerdownForm.controls;
  }

  onReset() {
    this.lockerdownForm.reset();
    Object.keys(this.lockerdownForm.controls).forEach(key => {
      this.lockerdownForm.get(key).setErrors(null);
    });
    this.lockerdownForm.patchValue({
      dropDown:[]
    })
    this.isFromDate=false;
    this.isToDate=false;
    this.selectFields=''
  }
  openDatePicker(v) {
    if (v === 'logDateFromInput') {
      this._renderer.selectRootElement(this.logDateFromInput.nativeElement).click();
      this.isFromDate = false;
    }

    if (v === 'logDateToInput') {
      this._renderer.selectRootElement(this.logDateToInput.nativeElement).click();
      this.isToDate = false;
    }
  }
  private readonly dateRangeValidator: ValidatorFn = (control: AbstractControl): {
    [key: string]: any;
  } | null => {
  
    if(!this.lockerdownForm)
      {
        return null;
      }
    let invalid = false;
    const from =control.value.logDateFrom;
    const to =control.value.logDateTo;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
}
