<app-header></app-header>

<img src="/assets/img/bg2.jpg" class="bg2" alt="background image">
<div class="container" [appViewController]="{report:'userOpsReport'}">
  <form [formGroup]="userOperationForm" class="container mat-card mb-6 mt-4 shadow bg-white rounded">
    <br>
    <p class="h4 text-center mt-2 mb-4">{{'User Operation
      Report'|translate}}</p>
    <div class="row px-4">
      <div class="col-8" formGroupName="reportDate">
        <div class="row">
          <div class="col date-picker form-group">
            <label for="dateFrom" class="form-label">{{'Date From'|translate}}</label>
            <div class="card">
              <div>
                <div>
                  <div class="row">
                    <div class="input-group">
                      <input type="text" placeholder="dd-mm-yyyy" class="form-control form-control-md"
                        formControlName="dateFrom" [maxDate]="maxDate" #dateFromInput bsDatepicker
                        [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                      <!-- bsDaterangepicker -->
                      <div class="input-group-append">
                        <span class="input-group-text">
                          <i class="fas fa-calendar-alt" (click)="openDatePicker('dateFromInput')"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-center">
              <small *ngIf="isFromDate" class="form-text text-danger">{{'Please fill From Date'|translate}}</small>
            </div>
          </div>
          <div class="col date-picker">
            <label for="dateTo" class="form-label">{{'Date To'|translate}}</label>
            <div class="card">
              <div>
                <div class="row">
                  <div class="input-group">
                    <input type="text" placeholder="dd-mm-yyyy" class="form-control form-control-md"
                      formControlName="dateTo" [maxDate]="maxDate" #dateToInput bsDatepicker
                      [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
                    <!-- bsDaterangepicker -->
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i class="fas fa-calendar-alt" (click)="openDatePicker('dateToInput')"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-center">
              <small *ngIf="isToDate" class="form-text text-danger">{{'Please Fill To Date'|translate}}</small>
            </div>
            <div>
              <small *ngIf="formValidators.reportDate && formValidators.reportDate.invalid"
                class="form-text text-danger">{{'Please add a valid from and to date'|translate}}</small>

            </div>
          </div>
        </div>
      </div>
      <div class="col-4">
        <label class="text-center" class="form-label">{{'Event Type'|translate}}</label>
        <select class="form-select" formControlName="eventType" name="eventType" placeholder="Event Type">
          <option value="" hidden>{{'Select'|translate}}</option>
          <option value="LOCKER OPENED">{{'Open Locker '|translate}}</option>
          <option [appViewController]="{report:'cameraActions'}" value="CAMERA">{{'Camera Actions'|translate}}
          </option>
        </select>
      </div>

    </div>


  

    <div class="container">
      <div class="row">
        <div class="col text-center ">
          <button class="btn btn-danger mx-2 my-4" type="submit" (click)="onExport()">{{'Export'|translate}}</button>
          <button class="btn btn-outline-danger mx-2 my-4" (click)="onReset()">{{'Reset'|translate}}</button>
        </div>
      </div>
    </div>
  </form>
</div>