<div class="mx-5 mt-5">
    <h2 class="text-danger">{{'Settings'|translate}}</h2>
    <div class="w-75 ms-auto me-auto">
        <form [formGroup]="partnerSettingForm">
        <div class="table-responsive">
            <div class="mt-8">
                <table class="table table-bordered" >
                    <caption></caption>
                    <thead>
                        <tr>
                            <th scope=""  colspan="2">{{'standardSettingsPackages'|translate}}</th>
                        </tr>
                        <tr>
                            <th scope="" rowspan="2">{{'Packages'|translate}}</th>
                            <td>
                                <div class="container">
                                <div class="row">
                                    <div class="col-12 col-md-5 d-flex align-items-center">{{'minimumReservationPeriod'|translate}}</div>
                                <div class="9 col-md-7">
                                    <input type="text" class="ml-md-5 me-2 form-control phone_screen_width d-inline"  id="minReservePeriod"
                                    formControlName="minReservePeriod" (input)="onInputChange($event)">
                                    <span>{{'Hours'|translate}}</span>
                                </div>
                                </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 col-md-5 d-flex align-items-center">{{'Dimensions'|translate}}(LxWxH)</div>
                                        <div class="col-12 col-md-7">
                                        <input  class="ml-md-5  form-control phone_screen_width d-inline" id="length"
                                        formControlName="length" (input)="onInputChange($event)">
                                        <span>x</span>
                                        <input  class=" form-control phone_screen_width d-inline" id="width"
                                        formControlName="width" (input)="onInputChange($event)">
                                        <span>x</span>
                                        <input  class=" form-control phone_screen_width d-inline" id="height"
                                        formControlName="height" (input)="onInputChange($event)">
                                        <span>mm</span>
                                    </div>
                                    </div>
                                    </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2">
                                <button class="btn btn-danger float-end" type="submit" (click)="saveSettingDetails()">{{'Save'|translate}}</button>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
    </form>
    </div>

</div>
