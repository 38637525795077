import { HttpClient } from '@angular/common/http';
import { ddsEnvironment, losEnvironment } from 'src/environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeliverypointsService {
  constructor(
    private readonly _http: HttpClient
  ) {

  }

  downloadCctvJob(deliverypointId: string, jobId: string) {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/${deliverypointId}/jobs/${jobId}/download/cctv`);
  }

  generateVncToken(id: string) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/access`, {
        type: 'vnc_token'
      });
  }

  fetchMany(query: any): Promise<any> {
    Object.keys(query).forEach(key => query[key] === undefined ? 
      delete query[key] : {});

    if (query.hasOwnProperty('acCode')){ // to filter data if multiple acCodes are selected
      return this._http.post(
        `${ddsEnvironment.baseUrl}/deliverypoints/search`,query).toPromise();
    } else {
      return this._http.get(
        `${ddsEnvironment.baseUrl}/deliverypoints`, {
          params: query
        }).toPromise();
    }
  }
  fetchManyNew(query:any, limit:any=50, offset:any=0) : Promise <any> {
    Object.keys(query).forEach(key => query[key] === undefined ? 
      delete query[key] : {});

    if (query.hasOwnProperty('acCode')){ // to filter data if multiple acCodes are selected
       return this._http.post(
        `${ddsEnvironment.baseUrl}/deliverypoints/search`,query).toPromise();
    } else {
      query.limit=limit;
      query.offset=offset;
      return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints`, {
        params: query,
        observe: 'response'
      }).toPromise();
    }
  }

  downloadLockerDetails(query: any): Promise<any> {
    Object.keys(query).forEach(key => query[key] === undefined ? 
      delete query[key] : {});

    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints?output=csv-download`, {
        params: query,
      }).toPromise();
  }

  fetchwithId(id: any, query: any = {}) {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/` + id, {
        params: query
      }).toPromise();
  }

  getJobs(id, limit, offset) : Promise<any> {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/jobs?limit=${limit}&offset=${offset}`, {
        observe: 'response'
      }).toPromise();
  }

  createJob(id, options) : Promise<any> {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/jobs`, options).toPromise();
  }

  fetchAcCode() : Promise <any> {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/acCode`).toPromise();
  }

  updateLockerDetails(id: any, lockerDetails: any) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/` + id, lockerDetails);
  }

  fetchLockerSettings() {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/settings`);
  }

  updatelockerSettings(lockerSettings: object) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/settings`, lockerSettings);
  }

  updateCommand(id: any, command: object) {
    return this._http.post(
      `${losEnvironment.baseUrl}/deliverypoints/${id}/command`, command);
  }

  addLockerDetails(lockerDetails: any) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints`, lockerDetails);
  }

  startIoTCommissioning(id: any) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/connectivity`,
      {
        technology: 'aws-iot'
      });
  }

  fetchDashBoard() {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints`, {
        params: {
          summary: 'technology-iot-summary'
        }
      });
  }


  //uploadXml File

  uploadXml(xmlData: any) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/addlocker`, xmlData);
  }

  updateLockerSettings(id: any, lockerSettings: any) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/settings`, 
      lockerSettings).toPromise();
  }

  onDownloadApiCall(id: any,obj){

    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/jobs`,{
        params: obj}
    );

  }

  onurlApiCall(id: any,obj){

    return this._http.get(
      `${ddsEnvironment.baseUrl}/deliverypoints/${id}/images`,{
        params: obj}
    );

  }


}
