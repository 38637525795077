<app-header></app-header>
<img src="/assets/img/bg2.jpg" class="bg2" alt="background image">
<div>
  <form [formGroup]="userDetailsForm" class="px-4 mt-5">
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mx-auto">
      <div class="card shadow p-3 mb-5 bg-white rounded">
        
        <div class="card-body p-3">
          <div>
            <div class="col text-right">
              <h2 class=" card-title col text-center " >{{'EDIT USER DETAILS'|translate}}</h2>
            </div>

          </div>
          <div class="col-12 text-secondary">
            <div class="row mt-3 ">
              <div class="col-4">{{'First name'|translate}}</div>
              <div class="col-8">
                <input type="text" class="form-control" formControlName="firstName" id="firstName" placeholder="{{'enter first name'|translate}}">
                <span class="help-block">
                  <span *ngIf="submitted && userDetailsForm.controls.firstName.errors">
                      <div *ngIf="userDetailsForm.controls.firstName.errors.required"
                          class="error-text text-danger mt-1">{{'FirstName is required'|translate}}</div>
                  </span>
              </span>
              </div>
            </div>


            <div class="row mt-3 ">
              <div class="col-4">{{'Last name'|translate}}</div>
              <div class="col-8">
                <input type="text" class="form-control" formControlName="lastName" id="lastName"  placeholder="{{'enter last name'|translate}}">
                <span class="help-block">
                  <span *ngIf="submitted && userDetailsForm.controls.lastName.errors">
                      <div *ngIf="userDetailsForm.controls.lastName.errors.required"
                          class="error-text text-danger mt-1">{{'LastName is required'|translate}}</div>
                  </span>
              </span>
              </div>
            </div>
            
            <div class="row mt-3 ">
              <div class="col-4">E-mail</div>
              <div class="col-8">
                <input type="text" class="form-control" formControlName="Email" id="Email" placeholder="{{'Email'|translate}}" readonly>
                
              </div>
            </div>
            <span class="help-block">
              <span *ngIf="submitted && userDetailsForm.controls.Email.errors">
                  <div *ngIf="userDetailsForm.controls.Email.errors.required"
                      class="error-text text-danger mt-1">{{'email is required'|translate}}</div>
                  <div *ngIf="userDetailsForm.controls.Email.errors.pattern"
                      class="error-text text-danger mt-1">{{'please enter a valid email address'|translate}}</div>
              </span>
            </span>
            <div class="row mt-3 ">
              <div class="col-4">{{'Mobile number'|translate}}</div>
              <div class="col-8">
                <input type="text" class="form-control" formControlName="mobileNumber" id="mobileNumber"
                  placeholder="{{'Mobile number'|translate}}"  maxlength="15" (keypress)="restrictAlphabets($event)">
                  <span class="help-block">
                    <span *ngIf="submitted && userDetailsForm.controls.mobileNumber.errors">
                        <div *ngIf="userDetailsForm.controls.mobileNumber.errors.pattern"
                            class="error-text text-danger mt-1">{{'please enter a valid mobile number'|translate}}</div>
                    </span>
                  </span>
               </div>
            </div>
            
            <div class="row mt-3">
              <div class="col-4">{{'Groups'|translate}}</div>
              <div class="col-8">
                <ng-multiselect-dropdown formControlName="Groups" id="Groups" [placeholder]="'-All-'|translate"
                  [settings]="userGroupdropdownSettings" [data]="userGroupdropdownList"
                  [(ngModel)]="userGroupselectedItems">
                </ng-multiselect-dropdown>
                <span class="help-block">
                  <span *ngIf="submitted && userDetailsForm.controls.Groups.errors">
                      <div *ngIf="userDetailsForm.controls.Groups.errors.required"
                          class="error-text text-danger mt-1">
                          {{'Group is required'|translate}}
                      </div>
                  </span>
              </span>
              </div>
            </div>
            
            <div class="row mt-3">
              <div class="col-4">{{'Partner'|translate}}</div>
              <div class="col-8">
                <input type="text" class="form-control" formControlName="partner" id="partner" placeholder="{{'Partner'|translate}}" readonly>
              </div>
            </div>
            <div class="row mt-3 ">
              <div class="col-4">{{'Active'|translate}}</div>
              <div class="col-8">
                <input class="custom-checkbox"  type="checkbox" formControlName="active" id="active" >
               </div>
            </div>
            <div class="text-center mt-4">
              <button  type="button" class="btn btn-danger mx-2" id="myBtn" (click)="formFieldsValidate()">{{'Save'|translate}}</button>
              <button class="btn btn-outline-danger" (click)="cancel()">{{'Cancel'|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="modal fade" tabindex="-1" id="editUserModal" role="dialog" aria-labelledby="editUserModal"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{'EDIT USER'|translate}}</h5>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <p>{{'Are you sure you want to edit the user details'|translate}}?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary mx-2" data-bs-dismiss="modal">{{'Cancel'|translate}}</button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="onSave()">{{'Confirm'|translate}}</button>
        </div>
      </div>
    </div>
  </div>

</div>
