import {
  AuthorizationConfig,
  AuthorizationConfigExternal,
  GeneralEnvironmentInfo
} from '../app/Service/authorization_config';

export const environment: GeneralEnvironmentInfo = {
  basic_env: 'ac1',
  production: true,
  baseUrl: 'https://parcellockers-ac1.bpost.cloud/',
};

export const authEnvironment: AuthorizationConfig = {
  issuer_uri: 'https://wiam-np.bpost.cloud', //need to modify
  client_id: 'lockerinternal',
  redirect_uri: 'https://parcellockers-ac1.bpost.cloud/login/bpost/callback',
  pingRegistrationUrl: 'https://wiam-np.bpost.cloud/pf-ws/authn/flows',
  pingAuthorizationUrl: 
  'https://wiam-np.bpost.cloud/as/authorization.oauth2',
  pingLogout: 'https://wiam-np.bpost.cloud/idp/startSLO.ping',
  pingTokenUrl: "https://wiam-np.bpost.cloud/as/token.oauth2",
  pingUserProfileUrl: "https://wiam-np.bpost.cloud/idp/userinfo.openid",
};

export const authEnvironmentExternal: AuthorizationConfigExternal = {
  issuer_uri: 'https://ssociam-np.bpost.cloud', //need to modify
  client_id: 'nlp_web_ciam_np',
  redirect_uri: 'https://parcellockers-ac1.bpost.cloud/login/external/callback',
  scope: 'openid email profile',
  pingRegistrationUrl: 'https://ssociam-np.bpost.cloud/pf-ws/authn/flows',
  pingAuthorizationUrl: 'https://ssociam-np.bpost.cloud/as/authorization.oauth2',
  pingLogout: 'https://ssociam-np.bpost.cloud/idp/startSLO.ping',
  pingTokenUrl: "https://ssociam-np.bpost.cloud/as/token.oauth2",
  pingUserProfileUrl: "https://ssociam-np.bpost.cloud/idp/userinfo.openid",
};

export const ddsEnvironment = {
  production: true,
  apiUrl: 'https://api.dds-ac1.bpost.cloud',
  baseUrl: 'https://api.dds-ac1.bpost.cloud',
  apiKey: 'XxoeUPFFwL3p4gtaEJtFs8F3NiOECHns7hHiXr8F'
};

export const lrsEnvironment = {
  production: true,
  baseUrl: 'https://api.lrs-ac1.bpost.cloud',
  apiKey: 'XxoeUPFFwL3p4gtaEJtFs8F3NiOECHns7hHiXr8F'
};

export const losEnvironment = {
  production: true,
  baseUrl: 'https://api.los-ac1.bpost.cloud',
  apiKey: 'XxoeUPFFwL3p4gtaEJtFs8F3NiOECHns7hHiXr8F'
};

export const etrEnvironment={
  production: true,
  basic_env:'ac1',
  baseUrl: "https://etracker-ac2.bpost.cloud/internal/shipment?barcode=",
};