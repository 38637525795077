import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ddsEnvironment, authEnvironment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public subject = new BehaviorSubject<any>(false);
  partnerData$= new BehaviorSubject({})
  checklistData = [{
    deliveryPoints: {
      showList: false,
      exportList: false,
      showDetails: false,
      restartButton: false,
      takeOver: false,
      updateLockerDetails: false,
      showLogs: false,
      showDowntime: false,
      showTerminals: false,
      allowCommand: false,
      allowAction: false,
      displayLayout: false,
      showOnboard: false,
      enableCamera:false
    }
  },
  {
    parcels: {
      list: false,
      exportList: false,
      showDetails: false,
      changeParcelstatus: false,
      assignCompartment: false,
      optionQrPickup: false,
      optionChangeExpirationDate: false,
      optionProcessManually: false,
      optionMarkFraud: false,
      optionCancelParcel: false,
      optionResendMail: false,
      editTimeline: false,
      reserveLastmile: false,
      reserveFirstmile: false
    }
  }, {
    users: {
      showList: false,
      exportList: false,
      editUser: false,
      deleteUser: false,
      addUser: false,
      showGroup: false,
      createGroup: false,
      modifyGroup: false,
      deleteGroup: false,
    },
  }, {
    partner: {
      showList: false,
      addPartner: false,
      editPartner: false
    }
  },
  {
    staff: {
      postmanCode: false,
      maintenanceStaff: false,
      createStaff: false,
      editStaff: false,
      deleteStaff: false,
    },
  },
  {
    report: {
      downTimeReport: false,
      firstmileFailed: false,
      userOpsReport: false,
      cameraActions: false
    }
  },
  {
    partnerTool: {
      viewPartnerTool: false
    }
  }
  ];
  apiBaseUrl = `${ddsEnvironment.baseUrl}/user`;
  
  constructor(
    private readonly _http: HttpClient,
  ) {

  }

  viewApicalled()
  {
    this.subject.next(true);
  }

  /**
   * Given a feature and an operation, check if the current user has the
   * necessary permission.
   *
   * This can also be used to check for permissions in a dotted notation,
   * for example: `checkPermission('feature.operation')`.
   *
   * @param feature
   * @param operation
   * @returns
   */
  checkPermission(currentuser: any, feature: string, operation: string=''):boolean {
    // if (currentuser) {
    //   const isDotted = feature.includes('.') && !operation;
    //   if (isDotted) {
    //     const [featureName, operationName] = feature.split('.');
    //     feature = featureName;
    //     operation = operationName;
    //   }
    //   if (currentuser && !currentuser.permissions[feature]){ return false};
    //   return currentuser.permissions[feature][operation];
    // }
    if(!currentuser || !currentuser.permissions){
      return false
    }
    if(feature.includes('.') && !operation){
      const [featureName, operationName]=feature.split('.');
      feature = featureName;
      operation = operationName;
    }
    const featurePermissions = currentuser.permissions[feature]
    if(!featurePermissions){
      return false
    }
    return operation ? featurePermissions[operation] : true

  }

  viewApicalledstop()
  {
    this.subject.next(false);
  }

  me() {
    return this._http.get(`${this.apiBaseUrl}/me`);
  }

  fetchMany(query: any) {
    Object.keys(query).forEach(key => query[key] === undefined ? delete query[key] : {});
    return this._http.post(
      `${this.apiBaseUrl}/search?limit=500&offset=0`,query );
  }
  
  fetchManyUsers(body:object,limit,offset): Promise <any> {
    return this._http.post(
      `${this.apiBaseUrl}/search?limit=${limit}&offset=${offset}&output=json`,body,{
        observe: 'response'
      }).toPromise();
  }
  fetchGroups() {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/user/groups`);
  }
  addUser(body: any) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/user`,body);
  }

  addUsergroup(body: any) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/user/groups/createusergroup`,body);
  }

  getUsergroup()
  {
    return this._http.get(`${ddsEnvironment.baseUrl}/user/groups`);
  }

  deleteUser(id:any){
    return this._http.delete(
      `${ddsEnvironment.baseUrl}/user/${id}`);
  }
  deleteUsergroup(num){
    const obj={id:num};
    return this._http.delete(
      `${ddsEnvironment.baseUrl}/user/groups/modifyusergroup`,{params:obj});
  }
  fetchById(id:any){
    return  this._http.get(
      `${ddsEnvironment.baseUrl}/user/${id}`);
  }
  updateUser(body:any,id:any){

    return this._http.post(
      `${ddsEnvironment.baseUrl}/user/${id}`,body);
  }

  getFlowId()
  {
    const headers= new HttpHeaders()
      .set('Sec-Fetch-Mode','navigate');
    return this._http.get(`${authEnvironment.pingAuthorizationUrl  }?client_id=NLP_reg_np&response_type=token&scope=openid&redirect_uri=${  authEnvironment.redirect_uri }&response_mode=pi.flow`,{headers});
  }

  intiateUserRegestration(id:any,pluginType:any)
  {
    const headers= new HttpHeaders()
      .set('X-XSRF-Header','Test')
      .set('Cookie',`PF=${pluginType}`);
    return this._http.post( authEnvironment.pingRegistrationUrl +  `/${id}?action=initiateRegistration`,null,{headers});
  }

  registerPingUser(id:any,body:any)
  {
    const headers= new HttpHeaders()
      .set('X-XSRF-Header','Test');
    return this._http.post( authEnvironment.pingRegistrationUrl + `/${id}?action=registerUser`,body,{headers});

  }
}
