import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { UserService } from '../../../Service/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from 'src/app/Service/app.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
declare let $: any;
@Component({
  selector: 'app-user-details-update',
  templateUrl: './user-details-update.component.html',
  styleUrls: ['./user-details-update.component.scss']
})
export class UserDetailsUpdateComponent implements OnInit {
  userId:any;
  userdetails:any;
  deleteId:any;
  groups:any;
  id:any;
  submitted: boolean;
  parcelLockerNamedropdownList = [];
  userGroupdropdownList = [];
  userGroupselectedItems = [];
  orderTypedropdownSettings: IDropdownSettings;
  userGroupdropdownSettings: IDropdownSettings;
  public userDetailsForm;
  langKey: any;
  mobnumPattern = '^[- +()]*[0-9][- +()0-9]*$';
  constructor(
    public _activatedRoute: ActivatedRoute,
    private readonly _userService: UserService,
    public _router: Router,
    private readonly _toastr: ToastrService,
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
    private readonly _spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.langKey = data;
    });
    this.fetchUserGroups();
    this.userDetailsForm = new FormGroup({
      id: new FormControl('', []),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('',  [Validators.required]),
      Email: new FormControl('',[]),
      Groups: new FormControl('',  [Validators.required]),
      partner: new FormControl('', [Validators.required]),
      mobileNumber: new FormControl('', Validators.pattern(this.mobnumPattern)),
      active:new FormControl('',[])
    });

    this.userId = this._activatedRoute.snapshot.paramMap.get('id');
    this.fetchUserDetails(this.userId);

    this.userGroupdropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

  }
  get F() {
    return this.userDetailsForm.controls;
  }  updateUserDetails() {
    const body = {};
    const groups = [];
    body['email'] = this.userDetailsForm.value.Email;
    if (this.userDetailsForm.value.Groups.length !== 0) {
      for (const element of this.userDetailsForm.value.Groups) {
        groups.push(element?.id);
      }}
    body['groups'] = groups;
    body['firstName'] = this.userDetailsForm.value.firstName;
    body['lastName'] = this.userDetailsForm.value.lastName;
    const mobileNum = this.userDetailsForm.value.mobileNumber;
    body['mobilenumber'] = mobileNum ? mobileNum : '';
    body['active']=this.userDetailsForm.value.active;
    
    this._userService.updateUser(body, this.userDetailsForm.value.id).subscribe({
     next :res => {
        this.langaugeFetch(this.langKey, 'UserEditedSuccessfully', 'success');
        this._router.navigate(['/user/search']);
      },
      error:err => {
        this.langaugeFetch(this.langKey, 'EditingUserFailed', 'error');
        console.error(err);
      },
      complete:()=>{}
      });

  }

  onSave() {
    this.updateUserDetails();
  }
  fetchUserGroups() {
    this._spinner.show();
    this._userService.fetchGroups().subscribe(
      res => {
        this._spinner.hide();
        this.groups = res;
        this.userGroupdropdownList = this.groups;
      }
    );
  }
  
  fetchUserDetails(userId) {
    this._spinner.show();
    this._userService.fetchById(userId).subscribe({
      next:res => {
        
        this._spinner.hide();
        this.userdetails = res;
        
        this.userDetailsForm.patchValue({
          'id': this.userdetails.id,
          'firstName': this.userdetails.firstName,
          'lastName': this.userdetails.lastName,
          'Email': this.userdetails.email,
          'Groups':  this.userdetails.groups ,
          'partner': this.userdetails.partner.name,
          'mobileNumber': this.userdetails.mobilenumber,
          'active':this.userdetails.active
        });
        this.userGroupselectedItems = this.userdetails.groups;
      },
      error:err => {
        this._spinner.hide();
        console.error(err);
      },
      complete:()=>{}
  });
  }
  langaugeFetch(lang, key, status) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (status === 'success') {
          this._toastr.success(res);
        }
        if (status === 'error') {
          this._toastr.error(res);
        }
      }
    );
  }
  cancel = () => {

    this._router.navigate(['/user/search']);
  };

  formFieldsValidate() {
    this.submitted = true;
    if (this.userDetailsForm.valid) {
      $('#editUserModal').modal('show');
    }
  }
  restrictAlphabets(e) {
    const x = e.which || e.keycode;
    if ((x >= 48 && x <= 57) || x === 43 || x === 40 || 
    x === 41 || x === 45 || x === 32 || x === 8 || x === 9 || 
    x === 27 || x === 127) {
      return true;
    }
    else {
      return false;
    }
  }
}



