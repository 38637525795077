<app-header></app-header>
<app-lockers-menu [id]="id"></app-lockers-menu>

<div class="container">
<div #myDiv [appViewController]="{deliveryPoints:'showLogs'}">
  <form [formGroup]="filterForm" >
    <div class="row my-3 py-3 shadow mb-5 bg-body rounded">
      <div class="d-flex justify-content-center px-4 pt-4">

     
      <div class="col-4 pe-3">
        <label for="acCode" class="form-label">{{'Event'|translate}}</label>
        <select class="form-select" formControlName="logEvent" name="logEvent">
          <option selected value="">{{'Choose Event'|translate}}</option>
          <option value="BARCODE_UNKNOWN">{{'BARCODE UNKNOWN'|translate}}</option>
          <option value="SWEEP_LOGIN">{{'SWEEP LOGIN'|translate}}</option>
          <option value="CLEAN_LOGIN">{{'CLEAN LOGIN'|translate}}</option>
          <option value="POSTMAN_LOGIN">{{'POSTMAN LOGIN'|translate}}</option>
          <option value="POSTMAN_FLOW">{{'POSTMAN FLOW'|translate}}</option>
          <option value="SCANNED">{{'SCANNER/SCREEN'|translate}}</option>
          <option value="DOOROPENED">{{'DOOR OPENED'|translate}}</option>
          <option value="CONNECTION">{{'CONNECTION'|translate}}</option>
        </select>
      </div>
      <div class="col-8">
        <label for="orderDate" class="form-label">{{'Log date'|translate}}</label>
        <div>
          <div class="row">
            <div class="input-group">
              <input
                type="text"
                placeholder="dd-mm-yyyy"
                class="form-control"
                formControlName="logDateFrom"
                #orderDateFromInput
                bsDatepicker
                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY',maxDate:maxDate}" />
                <span class="input-group-text">
                  <i class="fas fa-calendar-alt" id="fromDateBtn" (click)="openDatePicker('orderDateFromInput')"></i>
                </span>
              <input
                type="text"
                placeholder="dd-mm-yyyy"
                class="form-control ms-3"
                formControlName="logDateTo"
                #orderDateToInput
                bsDatepicker
                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY',maxDate:maxDate}" />
                <span class="input-group-text">
                  <i class="fas fa-calendar-alt" id="toDateBtn" (click)="openDatePicker('orderDateToInput')"></i>
                </span>
            </div>
          </div>
        </div>
        <span *ngIf="selectBothdates" class="text-danger">Please select both the dates</span>
        <span *ngIf="invalidDateRange" class="text-danger">{{'Please add a valid from and to date'|translate}}</span>
      </div>
    </div>
      <div class="row">
        <div class="col text-center mb-3">
          <button class="btn btn-danger me-3 mt-2" type="submit" id="submitBtn"  (click)="onSubmit()">{{'Filter'|translate}}</button>
          <button class="btn btn-outline-danger mt-2" type="submit" id="resetBtn"  (click)="onReset()">{{'Reset'|translate}}</button>
        </div>
      </div>
  </div>
  </form>
</div>

<div class="table-responsive pl-4 pr-4">
  <table datatable [dtOptions]="dtOptions" 
    class="table table-sm table-bordered table-hover row-border hover text-center" >
    <caption></caption>
    <thead  class="table-dark">
      <tr>
        <th scope="col" class="event text-center">{{'Event'|translate}}</th>
        <th scope="col" class="action text-center">{{'Action'|translate}}</th>
        <th scope="col" class="description text-center">{{'Description'|translate}}</th>
        <th scope="col" class="text-center">{{'Agent'|translate}}</th>
        <th scope="col" class="text-center">{{'Date'|translate}}</th>
        <th scope="col" class="column text-center">{{'Meta'|translate}}</th>
      </tr>
    </thead>
    <tbody *ngIf="logdetails?.length !== 0">

      <tr *ngFor="let res of logdetails;">
        <td class="event" *ngIf="!res.event"></td>
        <td class="event" *ngIf="res.event">{{res.event}}</td>

        <td class="action" *ngIf="res.action">{{res.action}}</td>
        <td class="action" *ngIf="!res.action"></td>

        <td class="description" *ngIf="res.description">{{res.description}}</td>
        <td class="description" *ngIf="!res.description"></td>
        <td *ngIf="!res.agent"></td>
        <td *ngIf="res.agent">{{res.agent}}</td>
        <td *ngIf="!res.eventDate"></td>
        <td *ngIf="res.eventDate"><span class="d-none">{{res.eventDate}}</span>{{res.eventDate | utctolocaldate:'DD/MM/YYYY HH:mm'}}</td>
        <td class="column" data-bs-toggle="tooltip"[attr.title]="getMeta(res.meta)">{{getMeta(res.meta)}}</td>
      </tr>
    </tbody>
    <tbody *ngIf="logdetails?.length == 0">
      <tr>
        <td colspan="6" class="no-data-available">{{'No data!' | translate}}</td>
      </tr>
      </tbody>
  </table>
</div>
</div>
<app-un-authorizedpage *ngIf="showUnauthorized"></app-un-authorizedpage>
