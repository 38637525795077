import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from 'src/app/Service/partner.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from 'src/app/Service/app.service';

@Component({
  selector: 'app-ironing-barcode',
  templateUrl: './ironing-barcode.component.html',
  styleUrls: ['./ironing-barcode.component.scss']
})
export class IroningBarcodeComponent implements OnInit {
  barcodeForm:FormGroup;
  submitted: boolean;
  translateData: any;
  constructor(private readonly _formBuilder: FormBuilder,
    public _router: Router,
    private readonly _partnerService: PartnerService,
    private readonly _toastr: ToastrService,
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
  ) {

    this.barcodeForm =this._formBuilder.group({
      barcode:['',[Validators.required]]
    });
  }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });
  }
  get formValidators() {
    return this.barcodeForm.controls;
  }
  submit(){
    this.submitted=true;
    if(this.barcodeForm.valid){
      this.extractValue(this.barcodeForm.controls.barcode.value);
    }
  }
  extractValue(barcode) {
    this._partnerService.getPartnerCustomer(barcode).subscribe({
     next: (
      res: any) => {
      if (res.length > 0) {
        this._partnerService.setEmail(res[0]?.Email);
        this._router.navigate(['partnertools/partnerOrder/', 'add', 
          this.barcodeForm.controls.barcode.value]);
      }
    },
     error:(err) => {
      if (err.error.code) {
        this.langaugeFetch(this.translateData, err.error.message);
        this._router.navigate(['partnertools/createBarcode']);
      }
    },  
    complete:()=>{}
  });
  }

  langaugeFetch(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        this._toastr.error(res);
      }
    );
  }
}
