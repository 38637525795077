import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {
  transform(customers:{firstName: string, lastName: string}): string {
    const fullName = `${customers?.firstName ?? ''} ${customers?.lastName ?? ''}`.trim()
   return fullName || 'Anonymous'
  }

}
