<app-header></app-header>
<img src="/assets/img/bg2.jpg" class="bg2" alt="background image">
<div class="container mt-5">
    <div class="row">
        <div class="col-2"></div>
        <div class="col-8 card">
            <div class="col-12 px-4 py-2">
                <h2 class="card-title text-center">{{header | translate}}</h2>
                <form [formGroup]="addUserForm">
                    <div class="form-group py-2">
                        <div class="row">
                            <label class="required col-3 col-form-label m-0" for="Name">{{'Name' | translate}}</label>
                            <div class="col-9">
                                <input type="text" formControlName="name" class="form-control col-sm-9" id="Name" aria-describedby="nameHelp" [placeholder]="'Enter Name'|translate" (keyup.enter)="input2.focus()" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3"></div>
                            <div *ngIf="submitted && formValidators.name.errors?.required" class="form-text text-muted col-9">{{'Please Fill Name Field' |translate}}
                            </div>
                        </div>

                    </div>
                    <div class="py-2">
                        <div class="row">
                            <label class="required col-3 col-form-label m-0" for="Name">{{'Login ID'| translate}}</label>
                            <div class="col-9">
                                <input type="text" formControlName="loginId" class="form-control custplaceholder text-transform" id="loginId" #loginId (input)="loginId.value = loginId.value.toUpperCase()" aria-describedby="nameHelp" [placeholder]="'Login ID'|translate" #input2 (keyup.enter)="input3.focus()" />
                                <div class="form-text col-12 bg-warning">
                                    {{'NoteKey'| translate}}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3"></div>
                            <div *ngIf="submitted && formValidators.loginId.errors?.required" class="form-text text-muted col-9">{{'Please fill Login field' | translate}}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3"></div>
                            <div *ngIf="loginIdValidateError" class="form-text text-muted col-9">{{'Login id should end with SWEEP or CLEAN' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="row">
                            <label class="required col-3 col-form-label m-0" for="Capabilities">{{'Capabilities'|translate}}</label>
                            <div class="col-9">
                                <select id="capabilities" class="form-select" formControlName="capabilities" required #input3 (change)="input4.focus()">
                                    <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                                    <option *ngFor="let list of list" [value]="list.name">
                                        {{list.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3"></div>
                            <div *ngIf="submitted && formValidators.capabilities.errors?.required" class="form-text text-muted col-9">{{'Please fill Capabilities field' | translate}}
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-3"></div>
                            <div *ngIf="showMissmatchError" class="form-text text-muted col-9">{{'LoginID and Capabilities Mismatch' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="row">
                            <label class="required col-3 col-form-label m-0" for="language">{{'Language'|translate}}</label>
                            <div class="col-9">
                                <select id="language" class="form-select text-capitalize" formControlName="language" required #input4 (change)="input5.focus()">
                                    <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                                    <option *ngFor="let list of languageList" [value]="list.value" class="text-capitalize">
                                        {{list.label}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3"></div>
                            <div *ngIf="submitted && formValidators.language.errors?.required" class="form-text text-muted col-9">{{'Please select language' | translate}}
                            </div>
                        </div>
                    </div>
                    <div class="py-2">
                        <div class="row">
                            <label class="required col-3" for="Name">{{'Password'|translate}}</label>
                            <div class="inner-addon right-addon w-75">
                                <div>
                                    <input name="password" formControlName="password" [type]="showPasswordOnPress ? 'text' : 'password'" class="form-control pwd-control" #password (input)="password.value = password.value.toUpperCase()" id="password-field" [placeholder]="'Password'|translate" #input5 (keyup.enter)="submit()" />
                                    <div (mousedown)="showPasswordOnPress = !showPasswordOnPress" (mouseup)="showPasswordOnPress = showPasswordOnPress">
                                        <i alt="show" class="fas fa-eye eye-show" [class.show]="showPasswordOnPress"></i>
                                        <i alt="hide" class="fas fa-eye-slash eye-hide" [class.hide]="!showPasswordOnPress"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3"></div>
                            <div *ngIf="submitted && formValidators.password.errors" class="form-text text-muted col-6">
                                {{'Please check Password field (alphanumeric, min 8 chars)' | translate}}</div>
                        </div>
                    </div>
                </form>
                <div class="text-center my-4">
                    <button class="btn btn-danger mx-2" type="submit" (click)="submit()">{{'Submit' | translate}}</button>
                    <button class="btn btn-outline-danger" (click)="cancel()">{{'Cancel'|translate}}</button>
                </div>
            </div>
        </div>