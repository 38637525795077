<nav class="navbar navbar-expand-lg navbar-light">
    <a class="navbar-brand my-0 mr-md-auto" [routerLink]="['/']">
        <img class="logo d-inline-block" src="/assets/img/Bpost_Logo.svg" alt="">
    </a>
    <app-langgtoggle></app-langgtoggle>
</nav>
<hr class="bg-danger p-0 m-0 text-danger">
<img src="/assets/img/bg2.jpg" class="bg2 col-sm-md-xl-12" alt="bg2.jpg">
<div class="box col-sm-md-xl-12">
    <div class="container">
        <div class="row">

            <div class="col-12">
                <div class="card shadow p-3 mb-5 bg-white rounded m-2">
                    <div class="card-body p-3">
                        <div class="container">
                            <div class="row">
                                <div class="col text-left">
                                    {{'Do you want login as Bpost user'| translate}}?
                                </div>
                                <div class="col text-center">
                                    <button *ngIf="!userInfo" class="btn btn-primary navbar-btn ms-5"
                                        id="handle-sign-in" (click)='loginReuseService()'>{{'SIGN IN'|translate}}</button>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div class="container">
                            <div class="row">
                                <div class="col text-left">
                                    {{'Do you want login as External user'|translate}}?
                                </div>
                                <div class="col text-center">
                                    <button *ngIf="!userInfo" class="btn btn-success navbar-btn ms-5"
                                        id="handle-sign-in" (click)='authorizeExternal()'>{{'SIGN IN'|translate}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<br>
