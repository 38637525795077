import { Directive, Input, Renderer2, ElementRef, OnInit } from '@angular/core';
import { UserService } from '../../src/app/Service/user.service';
import { AuthorizationService } from './Service/authorization.service';

@Directive({
  selector: '[appViewController]'
})
export class ViewControllerDirective implements OnInit{
  userInfo: any;
  userGroup: any;
  currentUserRoles: any;
  apiCalled = false;
  @Input() appViewController: any;

  constructor(
    private readonly _render: Renderer2,
    private readonly _el: ElementRef,
    private readonly _userService: UserService,
    private readonly _authorizationService: AuthorizationService
  ) {}

  ngOnInit() {
      this.findUserRoles();
  }

  findUserRoles() {
     this._authorizationService.currentDdsUser.subscribe(user => { 
      this.userInfo=user;
      if(this.userInfo){
    let visibilty = false;

    const permissionCheck = this.appViewController;

    // Check if the feature exists in the permissions object
    const featureName = Object.keys(permissionCheck)[0];
    const operationName = permissionCheck[featureName];
    const permission = this._userService.checkPermission(this.userInfo, featureName, operationName)
    if (permission) {
      visibilty = true;
    }

    if (!visibilty) {
      this._render.setStyle(this._el.nativeElement, 'display', 'none');
    }

    if (this.apiCalled) {
      this._userService.viewApicalled();
      this.apiCalled = false;
      this._userService.viewApicalledstop();
    }}
  });
  }
}
