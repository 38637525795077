<app-header></app-header>
<div [appViewController]="{users:'showList'}">
  <div class="container my-3">
    <div class="row">
      <div class="col-6 float-start ">
        <input
          type="image"
          src="/assets/img/filter.png" 
          name="submit"
          width="35"
          height="35"
          alt="submit"
          data-toggle="collapse"
          data-target="#collapseExpiryDate"
          aria-expanded="false"
          aria-controls="collapseExpiryDate" />
      </div>
      <div class="col-6 text-end ">
        <input
          type="image"
          src="/assets/img/redIcon.png" 
          name="submit"
          width="28"
          height="28"
          alt="submit"
          (click)="exportTable()"
          [appViewController]="{users:'exportList'}" />
      </div>
    </div>
  <div class="row">
    <div class="col">
      <form [formGroup]="filterForm" (ngSubmit)="onSubmit()" class="px-4">
        <div class="col-12 collapse" id="collapseExpiryDate">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="card shadow p-3 mx-auto bg-white rounded mt-0 mb-5">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-4">
                        <label for="Email">E-mail</label>
                        <input [ngClass]="Filter.Email.errors?.required  &&  Filter.Email.touched ? 'error':'' "
                          type="email" name="email" ngModel [email]="true" class="form-control" formControlName="Email"
                          id="Email" placeholder="eg: user@bpost.be">
                        <span class="help-block">
                          <span *ngIf="submitted && filterForm.controls.Email.errors">
                            <div *ngIf="filterForm.controls.Email.errors.required" class="error-text">
                              {{'email is mandatory'|translate}}</div>
                            <div *ngIf="filterForm.controls.Email.errors.pattern" class="error-text">
                              {{'please enter a valid email address'|translate}}</div>
                          </span>
                        </span>
                      </div>
                      <div class="col-4">
                        <label for="Name">{{'Name'|translate}}</label>
                        <input type="text" class="form-control" formControlName="Name" name="Name"
                          placeholder="{{'Name'|translate}}">
                      </div>
                      <div class="col-4">
                        <label for="groups">{{'Group'|translate}}</label>
                        <ng-multiselect-dropdown formControlName="userGroups" [placeholder]="'-All-'|translate"
                          [settings]="userGroupdropdownSettings" [data]="userGroupdropdownList"
                          [(ngModel)]="userGroupselectedItems">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>
                    <div class="container">
                      <div class="row">
                        <div class="col text-center">
                          <button class="btn btn-outline-danger  me-3 mt-4" type="submit">{{'Filter'|translate}}</button>
                          <button class="btn btn-outline-danger mt-4" type="submit"
                            (click)="onReset()">{{'Reset'|translate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    
  </div>
  <div class="row">
    <div class="col-12">
      <div class="table-responsive" id="userListTable">
        <table aria-describedby="userListTable"
          datatable
          [dtOptions]="dtOptions"
          class="table table-sm table-bordered table-hover row-border hover text-center">
          <thead class="table-dark ">
            <tr>
              <th scope="col" class="text-center">{{'Name'|translate}}</th>
              <th scope="col" class="text-center">{{'Email'|translate}}</th>
              <th scope="col" class="text-center">{{'Groups'|translate}}</th>
              <th scope="col" class="text-center">{{'Partners'|translate}}</th>
              <th scope="col" class="text-center">{{'Last logged-in time'|translate}}</th>
              <th scope="col" class="text-center">{{'Action'|translate}}</th>
            </tr>
          </thead>
          
          <tbody *ngIf="result?.length != 0">
            <tr *ngFor="let res of result;" (click)="getId(res.id)">
              <td *ngIf="res?.firstName">{{res.firstName+' '+res.lastName}}</td>
              <td *ngIf="!res.firstName"></td>
              <td *ngIf="res.email">{{res.email}}</td>
              <td *ngIf="!res.email"></td>
              <td>
                    <div 
                      *ngFor="let group of (res.groups||[]);"
                      [ngClass]="group.color == 'primary'?'':
                        group.color == 'secondary'?'':
                          group.color == 'success'?'':
                            group.color == 'info'?'':''"
                    >
                      {{group.name}}
                    </div>
              </td>
              <td *ngIf="!res.partner"></td>
              <td *ngIf="res.partner">{{res.partner.name}}</td>
              <td *ngIf="!res.lastLoginTime"></td>
              <td *ngIf="res.lastLoginTime">{{res.lastLoginTime}}</td>
              <td>
                <div (onblur)="getId(res.id)" class="button">
                  <button type="button" class="btn btn-sm me-2 btn-success px-4" (click)="update(res.id)"
                    [appViewController]="{users:'editUser'}">{{'Edit'| translate}}</button>
                  <button data-toggle="modal" data-target="#deleteUserModal" type="button"
                    class="btn btn-danger" data-toggle="modal" data-target="#deleteUserModal"
                    (click)="saveDeleteId(res.id)"
                    [appViewController]="{users:'deleteUser'}">{{'Delete'| translate}}</button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="result?.length == 0">
            <tr>
              <td colspan="5" class="no-data-available">{{'No data!' | translate}}</td>
            </tr>
            </tbody>
        </table>
        <br>
      </div>
    </div>
  </div>
  </div>
  <div class="modal fade" tabindex="-1" id="deleteUserModal" role="dialog" aria-labelledby="deleteUserModal"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title mb-0">{{'Delete'| translate}}</h3>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <p>{{'Are you sure you want to delete the user'| translate}}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="deleteUser()">Confirm</button>
        </div>
      </div>
    </div>
  </div>
</div>