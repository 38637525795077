import { Injectable } from '@angular/core';
import { losEnvironment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(private readonly _http: HttpClient) {
    
  }

  lockerDown(body:any):Observable<any> {
    return this._http.post<any>(
      `${losEnvironment.baseUrl}/reports/lockerdowntime`, body).pipe(
        catchError((err)=>{
          return throwError(()=> new Error('Error during locker downtime') )
        })
      )
  }
}