<app-loader *ngIf="showSpinner"></app-loader>
<app-header></app-header>
<div class="container">
    <br />
    <div class="row">
        <div class="col-3 mt-3">
            <app-partner-info-menu [partner]=partnerDetails></app-partner-info-menu>
        </div>
        
        <div class="col-9 shadow p-3 mb-5 bg-body rounded mt-3">
            <h2 class="card-title text-center py-2">{{'Edit Partner'|translate}}</h2>
            <form [formGroup]="partnerForm" (ngSubmit)="onSubmit()">
                <div class="px-3" >
                    <div class="row mb-3">
                        <label class="required col-4 col-form-label" for="partnerName">{{'Name'|translate}}</label>
                        <div class="col-8">
                        <input type="text" class="form-control" formControlName="partnerName" id="partner name"
                            placeholder="{{'partner name'|translate}}" readonly>
                        </div>
                    </div>

                    <small class="row">
                        <small class="col-5 ms-4"></small>
                        <small *ngIf="submitted &&  formValidators.partnerName.errors?.required"
                            class="form-text text-muted col-8">{{'Please fill partnerName' | translate}}
                        </small>
                    </small>
                </div>

                <div class="px-3">
                    <div class="row mb-3">
                        <label class="required col-4 col-form-label" for="country">{{'Country'|translate}}</label>
                        <div class="col-8">
                        <select class="form-select" formControlName="country" name="country" disabled>
                            <option selected value="">{{'Choose Option...'|translate}}</option>
                            <option value="belgium">Belgium</option>
                        </select>
                    </div>
                    </div>
                    <small class="row">
                        <small class="col-4 ms-4"></small>
                        <small *ngIf="submitted &&  formValidators.country.errors?.required"
                            class="form-text text-muted col-8">{{'Please select country' | translate}}
                        </small>
                    </small>

                </div>
                <div class="px-3" >
                    <div class="row mb-3">
                        <label class="required col-4 col-form-label" for="active">{{'Active'|translate}}</label>
                        <div class="col-8">
                        <select class="form-select" formControlName="active" name="active">
                            <option selected value="">{{'Choose Option...'|translate}}</option>
                            <option value="true">{{'Yes'|translate}}</option>
                            <option value="false">{{'No'|translate}}</option>
                        </select>
                    </div>
                    </div>
                    <small class="row">
                        <small class="col-4 ms-4"></small>
                        <small *ngIf="submitted &&  formValidators.active.errors?.required"
                            class="form-text text-muted col-8">{{'Please select active option' | translate}}
                        </small>
                    </small>

                </div>

                <div class="px-3">
                    <div class="row mb-3">
                        <label class="required col-4 col-form-label" for="partnerName">{{'PRS ID'|translate}}</label>
                        <div class="col-8">
                        <input type="text" class="form-control" formControlName="prsId" id="prsId"
                            placeholder="{{'PRS ID'|translate}}">
                        </div>    
                    </div>

                    <small class="row">
                        <small class="col-sm-3 ms-4"></small>
                        <small *ngIf="submitted &&  formValidators.prsId.errors?.pattern"
                            class="form-text text-muted col-sm-8">{{'Please enter numbers only' | translate}}
                        </small>
                    </small>
                </div>
                <div class="px-3">
                    <div class="row mb-3">
                        <label class="required col-4 col-form-label" for="allowOwnCustomers">{{'Allow Own Customer' | translate}}</label>
                        <div class="col-8">
                            <select class="form-control" formControlName="allowOwnCustomers" name="allowOwnCustomers">
                                <option selected value="">{{'Choose Option...'|translate}}</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                    </div>
                    <small class="row">
                        <small class="col-sm-3 ml-4"></small>
                        <small *ngIf="submitted &&  formValidators.allowOwnCustomers.errors?.required"
                            class="form-text text-muted col-sm-8">{{'Please select allow customer option' | translate}}
                        </small>
                    </small>
                </div>
                <div class="text-center pb-4">
                    <button class="btn btn-danger" type="Submit">{{'Submit'|translate}}</button>
                    <button class="btn btn-outline-danger ms-3" (click)="cancel()">{{'Cancel'|translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>