import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/Service/user.service';
import { AuthorizationService } from 'src/app/Service/authorization.service';

@Component({
  selector: 'app-partner-edit-postman',
  templateUrl: './partner-edit-postman.component.html',
  styleUrls: ['./partner-edit-postman.component.scss']
})
export class PartnerEditPostmanComponent implements OnInit {
  
  showSpinner = false;
  partnerDetails: any;
  partnerId: any;
  driverData: any;
  addPartnerPostmanForm: FormGroup;
  submitted: boolean;
  model: any = {};
  langKey: any;
  btnAction:string;
  selectedDriverId:number;
  currentPartnerId : number;
  languageList = [{language:'en',description:'english'},
    {language:'fr',description:'french'},
    {language:'nl',description:'dutch'}];
  @ViewChild('closePostmanModal') private readonly closePostmanModal: 
  ElementRef;
  list: any[];
  dropdownValue: any[];
  capabilityCheck = false;
  dropSuccess = true;
  dropdownList = [];
  selectedItems = [];
  selectAll: any;
  unselectAll: any;
  partnerDeleteDetails:any;
  searchText: any;
  capabilitiesDropdownSetting: IDropdownSettings;
  constructor(public _activatedRoute: ActivatedRoute,
    private readonly _toastr: ToastrService,
    private readonly _partnerService: PartnerService,
    private readonly _router: Router,
    private readonly _formBuilder: FormBuilder,
    private readonly _langtransService: LangtransService,
    private readonly _translate: TranslateService,
    private readonly _userService: UserService,
    private readonly _authorizationService: AuthorizationService
  ) {
    this.dropdownList = ['CAN_COLLECT', 'CAN_DELIVER'];
  }

  async ngOnInit(): Promise<void> {
    const partnerId = +this._activatedRoute.snapshot.params.id;
    this.partnerId = partnerId;
    this._authorizationService.currentDdsUser.subscribe(user => {
      this.currentPartnerId = user?.partner.id;
      this.buildPartnerForm();
      this.getPartnerDetails(partnerId);
      this.getDetails(partnerId);
    });
    this.capabilitiesDropdownSetting = {
      singleSelection: false,
      idField: '',
      textField: '',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 1,
      allowSearchFilter: false
    };
  }

  buildPartnerForm(){
    this.addPartnerPostmanForm = this._formBuilder.group({
      name: ['', Validators.required],
      username: ['', [Validators.required , 
        Validators.pattern('^[a-zA-Z0-9]+$')]],
      code: ['', [Validators.required , Validators.pattern('^[0-9]*$')]],
      active: [true, Validators.required],
      language: ['', Validators.required],
      capabilities: ['', Validators.required],
      generateSecret:[false],
      secretValidity:['' , Validators.required],
      expiresAt:['']
    });
  }
  get formValidators() {
    return this.addPartnerPostmanForm.controls;
  }
  //Fech partner details
  getPartnerDetails(partnerId) {
    this.showSpinner = true;
    this._partnerService.fetchPartnerDetails(partnerId).subscribe({
      next:res => {
        this.partnerDetails = res;
        this.showSpinner = false;
      },
      error:err => {
        this.showSpinner = false;
        this._toastr.error(this._translate.instant(
          'Failed to fetch partner details !!'), 
        this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
  });
  }
  getDetails(partnerId) {
    this.showSpinner = true;
    this._partnerService.fetchDriverDetails(partnerId).subscribe({
      next:res => {

        this.driverData = res;
        this.driverData.forEach(driver => {
          this.languageList.forEach(lang => {
            if (driver.language === lang.language){
              driver.langDesc = lang.description;
            }
          });
        });
        
        this.showSpinner = false;
      },
      error:err => {
        this.showSpinner = false;
        this._toastr.error(this._translate.instant(
          'Failed to fetch driver details !!'), 
        this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
  });
  }
  resetInputValues() {
    this.model = {};

    this.addPartnerPostmanForm.reset();
    this.submitted = false;
  }
  addPartnerPostman() {
    this.submitted = true;
    if (this.submitted && this.capabilityCheck) {
      this.dropSuccess = true;
    } else {
      this.dropSuccess = false;
    }
    if (this.addPartnerPostmanForm.valid) {

      this.addPartnerPostmanSchemas(this.addPartnerPostmanForm.value);
      this.closePostmanModal.nativeElement.click();
      this.resetInputValues();
    }
  }
  updatePartnerPostman() {
    this.submitted = true;
    if(Object.keys(this.addPartnerPostmanForm.value.capabilities).length === 0){
      this.addPartnerPostmanForm.controls.capabilities.setErrors(
        {required: true});
    }else{
      this.addPartnerPostmanForm.controls.capabilities.setErrors(null);
    }

    
    if (this.addPartnerPostmanForm.valid) {
      this.updatePartnerPostmanSchemas();
    }
  }
  /*
   * Functionality used to update partner deliverypoint schemas
   */
  addPartnerPostmanSchemas(reqBody) {
    this.showSpinner = true;
    reqBody.capabilities = this.selectedItems;
    if(reqBody.secretValidity.length === 0){
      delete reqBody.secretValidity;
    }
    this._partnerService.addPartnerPostmanSchemas(
      this.partnerId, reqBody).subscribe({
      next:(res: any) => {
        this.getDetails(this.partnerId);
        this.showSpinner = false;
        this.addPartnerPostmanForm.controls['code'].enable();
        this._toastr.success(this._translate.instant(
          'Postman Added Successfully!'), this._translate.instant('Success'));
      },
      error:err => {
        this.showSpinner = false;
        if (err.error.code === 'DUPLICATE_USERNAME') {
          this._toastr.error(this._translate.instant(
            'PostmanAlreadyExists'), this._translate.instant('Failed'));
        }
        else {
          this._toastr.error(this._translate.instant(
            'Pleasetryagain'),this._translate.instant('Failed'));
          console.error(err);
        }
      },
      complete:()=>{}
  });
  }
  updatePartnerPostmanSchemas() {
    this.showSpinner = true;
    this._partnerService.updatePartnerPostmanSchemas(this.partnerId, 
      this.addPartnerPostmanForm.value,this.selectedDriverId).subscribe({
      next:(res: any) => {
        this.closePostmanModal.nativeElement.click();
        this.getDetails(this.partnerId);
        this.showSpinner = false;
        this._toastr.success(this._translate.instant(
          'Postman Updated Successfully!'), this._translate.instant('Success'));
      },
      error:err => {
        this.showSpinner = false;
        if (err.error.code === 'DUPLICATE_USERNAME') {
          this._toastr.error(this._translate.instant(
            'PostmanAlreadyExists'), this._translate.instant('Failed'));
        }
        else {
          this._toastr.error(this._translate.instant(
            'Failed to update postman schemas'), 
          this._translate.instant('Failed'));
          this._toastr.error(this._translate.instant('Pleasetryagain'), 
            this._translate.instant('Failed'));
          console.error(err);
        }
        
      },
      complete:()=>{}
  });
  }
  editTableData(data,action:string) {
    this.btnAction = action;
    this.selectedDriverId = data.id;
    this.addPartnerPostmanForm.controls['code'].clearValidators();
    this.addPartnerPostmanForm.controls['code'].setValue('');
    this.addPartnerPostmanForm.controls['code'].updateValueAndValidity();
    this.addPartnerPostmanForm.patchValue(data);
    this.selectedItems = data.capabilities;
    this.addPartnerPostmanForm.controls['secretValidity'].clearValidators();
    this.addPartnerPostmanForm.controls['secretValidity'].setValue('');
    this.addPartnerPostmanForm.controls['secretValidity'].disable();
    this.addPartnerPostmanForm.controls['code'].enable();
  }
  resetTableData() {
    this.addPartnerPostmanForm.controls['code'].setValidators(
      [Validators.required]);
    this.addPartnerPostmanForm.controls['code'].updateValueAndValidity();
  }

  partnerToDelete(data){
    this.partnerDeleteDetails=data;    
  }
  removeTableData() {
    this.showSpinner = true;
    this._partnerService.deletePartnerPostmanSchemas(this.partnerId, 
      this.partnerDeleteDetails).subscribe({
      next:(res: any) => {
        this.getDetails(this.partnerId);
        this.showSpinner = false;
        this._toastr.success(this._translate.instant(
          'Postman Deleted Successfully!'), 
        this._translate.instant('Success'));
      },
      error:err => {
        this.showSpinner = false;
        this._toastr.error(this._translate.instant(
          'Failed to delete postman schemas'), 
        this._translate.instant('Failed'));
        this._toastr.error(this._translate.instant(
          'Pleasetryagain'), this._translate.instant('Failed'));
        console.error(err);

      },
      complete:()=>{}
  });
  }


  translator(lang, key, status) {
    this._langtransService.TranslateData(lang, key).subscribe((res) => {
      if (status) {
        this._toastr.success(res);
      } else {
        this._toastr.error(res);
      }
    });
  }
  goToPartnerDetails() {
    this._router.navigate(['/partners/', this.partnerId]);
  }

  addPostman(action:string){
    this.addPartnerPostmanForm.controls['code'].enable();
    this.buildPartnerForm();
    this.btnAction = action;
  }

  onCheckboxChange(){
    const checkedValue = 
    this.addPartnerPostmanForm.get('generateSecret').value; 
    if(checkedValue === true){
      this.addPartnerPostmanForm.controls['code'].setValue('');
      this.addPartnerPostmanForm.controls['code'].disable();
      this.addPartnerPostmanForm.controls['secretValidity'].setValidators(
        [Validators.required]);
      this.addPartnerPostmanForm.controls['secretValidity'].enable();
      
    }else{
      this.addPartnerPostmanForm.controls['secretValidity'].disable();
      this.addPartnerPostmanForm.controls['code'].enable();
    }
  }

  onCheckboxChangeAddOption(){
    const checkedValue = 
    this.addPartnerPostmanForm.get('generateSecret').value; 
    if(checkedValue){
      this.addPartnerPostmanForm.controls['code'].setValue('');
      this.addPartnerPostmanForm.get('code').disable();
    }else{
      this.addPartnerPostmanForm.controls['code'].setValidators(
        [Validators.required,Validators.pattern(/^\d+$/)]);
      this.addPartnerPostmanForm.get('code').enable();
    }
  }

  onInputChange(){
    const codeValue = this.addPartnerPostmanForm.get('code').value; 
    if(typeof(codeValue) == 'string'){
      this.addPartnerPostmanForm.controls['code'].setValidators(
        [Validators.pattern('^[0-9]*$')]);
    }
    const slectcontrl =  this.addPartnerPostmanForm.get('secretValidity');
    if(codeValue){
      this.addPartnerPostmanForm.controls['secretValidity'].setValidators(
        [Validators.required]);
      slectcontrl?.enable();
    }else{
      slectcontrl?.disable();
    }
  }
}
