import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../../../Service/user.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from '../../../Service/app.service';
import { HttpClient } from '@angular/common/http';
import { PartnerService } from 'src/app/Service/partner.service';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  userEmail: any;
  userGroups: any;
  partner: any;
  hasErrors: boolean;
  groups: any;
  partners: any;
  parcelLockerNamedropdownList = [];
  userGroupdropdownList = [];
  userGroupselectedItems = [];
  partnersList = [];
  partnerSelectedItems = [];
  orderTypedropdownSettings: IDropdownSettings;
  userGroupdropdownSettings: IDropdownSettings;
  partnerDropdownSettings: IDropdownSettings;
  lockerFetchfail: any;
  translateData: any;
  public addUserForm;
  selectAll: any;
  unselectAll: any;
  searchText: string;
  selectedUserType: any = 'internal';
  courierType: any;
  courierList: any;
  courierSelectedItem = [];
  showLoader = false;
  mobnumPattern = '^[- +()]*[0-9][- +()0-9]*$';
  constructor(private readonly _http: HttpClient, 
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService, 
    private readonly _userService: UserService, 
    private readonly _partnerService: PartnerService,
    public _router: Router, 
    private readonly _toastr: ToastrService,
    private readonly _translate: TranslateService,
    private readonly _spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.courierType = [{ id: 1, type: 'DELIVERY' }, { id: 2, type: 'OWNER' }];
    this.courierList = this.courierType.map(obj => obj.type);
    this.fetchPartnerList();
    this.fetchUserGroups();
    this._appService.languagetoggle$.subscribe((lang: any) => {
      this.translateData = lang;
      this._http.get(`/assets/i18n/${lang}.json`).subscribe({
        next:(data: any) => {
        this.selectAll = data.selectAll;
        this.unselectAll = data.UnSelectAll;
        this.searchText = data.Search;
        this.userGroupdropdownSettings = {
          singleSelection: false,
          idField: 'id',
          textField: 'type',
          selectAllText: this.selectAll,
          unSelectAllText: this.unselectAll,
          itemsShowLimit: 1,
          allowSearchFilter: true,
          searchPlaceholderText: this.searchText
        };
        this.partnerDropdownSettings = {
          singleSelection: true,
          idField: 'id',
          textField: 'type',
          closeDropDownOnSelection: false,
          searchPlaceholderText: this.searchText
        };
      },
      error:(err)=>{
      },
      complete:()=>{}
    });
    });

    this.addUserForm = new FormGroup({
      userType: new FormControl('', []),
      userEmail: new FormControl('', [Validators.required, Validators.email]),
      userfirstName: new FormControl('', [Validators.required]),
      userlastName: new FormControl('', [Validators.required]),

      userGroups: new FormControl(''),
      partner: new FormControl('', [Validators.required]),
      usermobileNumber: new FormControl('', 
        Validators.pattern(this.mobnumPattern)),
    });
  }

  get F() {
    return this.addUserForm.controls;
  }

  postUserDetails() {
    const body = {};
    const groups = [];
    const partnerId = [];
    body['email'] = this.addUserForm.value.userEmail;

    for (const element of this.addUserForm.value.userGroups) {
      groups.push(element.id);
    }
    for (const element of this.addUserForm.value.partner) {
      partnerId.push(element.id);
    }
    body['groups'] = groups;
    body['partner'] = partnerId[0]; // As selecting only one partner at a time
    body['userType'] = 'Internal';
    body['name'] = this.addUserForm.value.userfirstName;
    body['firstName'] = this.addUserForm.value.userfirstName;
    body['lastName'] = this.addUserForm.value.userlastName;
    body['mobilenumber'] = this.addUserForm.value.usermobileNumber;
    this.setUser(body);
  }

  setUser(body: any) {
    this.showLoader = true;
    this._userService.addUser(body).subscribe({
     next :(res) => {
        this.showLoader = false;
        this.langaugeFetch(this.translateData, 
          'UserAddedSuccessfully', 'success');
        this._router.navigate(['/user/search']);
      },
      error:(err) => {
        this.showLoader = false;
        this._toastr.error(this._translate.instant(
          err?.error?.message), this._translate.instant('failed'));
      },
      complete:()=>{}
  });

  }

  onTypeChange() {
    if (this.selectedUserType === 'external' 
      || this.selectedUserType === 'internal') {
      this.addUserForm.controls['userfirstName'].setValidators(
        [Validators.required]);
      this.addUserForm.controls['userfirstName'].updateValueAndValidity();
      this.addUserForm.controls['userlastName'].setValidators(
        [Validators.required]);
      this.addUserForm.controls['userlastName'].updateValueAndValidity();
    }
  }

  fetchUserGroups() {
    this._spinner.show();
    const userGroupList = [];
    this._userService.fetchGroups().subscribe({
      next:res => {
        this._spinner.hide();
        this.groups = res;
        for (let i = 0; i < this.groups.length; i++) {
          userGroupList[i] = 
          { id: this.groups[i].id, type: this.groups[i].name };
        }
        this.userGroupdropdownList = userGroupList;
      }, 
      error:(err) => {
        this._spinner.hide();
      },
     complete:()=>{}
  });
  }

  fetchPartnerList() {
    this._spinner.show();
    this._partnerService.fetchPartnersList().subscribe({
      next:res => {
        this._spinner.hide();
        this.partners = res;
        const test = [];
        for (let i = 0; i < this.partners.length; i++) {
          test[i] = { id: this.partners[i].id, type: this.partners[i].name };
        }
        this.partnersList = test;
      }, error:err => {
        this._spinner.hide();
      },
      complete:()=>{}
  });
  }

  onSubmit() {
    if (this.addUserForm.valid) {
      this.postUserDetails();
    }
    else {
      this.hasErrors = true;
    }
  }
  
  langaugeFetch(lang, key, status) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (status === 'success') {
          this._toastr.success(res);
        }
        if (status === 'error') {
          this._toastr.error(res);
        }
      }
    );
  }
  
  cancel = () => {
    this._router.navigate(['/user/search']);
  };

  restrictAlphabets(e) {
    const x = e.which || e.keycode;
    if ((x >= 48 && x <= 57) || x === 43 || x === 40 || 
    x === 41 || x === 45 || x === 32 || x === 8 || x === 9 || 
    x === 27 || x === 127) {
      return true;
    }
    else {
      return false;
    }
  }
}
