import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LogsService } from '../../../Service/logs.service';
import { ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { AppService } from '../../../Service/app.service';
import { LangtransService } from '../../../Service/langtrans.service';
import { Config } from 'datatables.net';
@Component({
  selector: 'app-logs',
  templateUrl: './connection-logs.component.html'
})
export class ConnectivityLogsComponent implements OnInit, AfterViewInit, 
OnDestroy{
  id: any;
  queryStringEvent: any;
  logdetails: any;
  dtOptions:Config ={};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;
  startDownData: any[];
  endDownData: any[];
  startDown: any[];
  endDown: any[];
  showSpinner = true;
  differenceArray: number[] = [];
  duration: any[];
  mergedArray: string[][];
  showUnauthorized = false;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;

  displayArr: any = [];
  lang: any;

  constructor(
    public _activatedRoute: ActivatedRoute,
    private readonly _logservice: LogsService,
    private readonly _toastr: ToastrService,
    private readonly _spinner: SpinnerService,
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
  ) {
  }

  ngOnInit() {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.lang = data;
    });
    this.displayArr = [];
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 50,
      stateSave: true,
      order: [],
    };

    this.id = this._activatedRoute.snapshot.paramMap.get('id');
    this.queryStringEvent = 'CONNECTION';
    const params = {
      event: this.queryStringEvent || undefined
    };
    this._spinner.show();
    this._logservice.filterLogs(this.id, params).subscribe({
      next:(res: any) => {
        if (res) {
          this._spinner.hide();

          this.displayArr = res.map(e => {
            return {
              ...e,
              startDown: e.disconnected_at ? 
                moment(e.disconnected_at).format('YYYY-MM-DD HH:mm:ss') : null,
              endDown: e.reconnected_at ? 
                moment(e.reconnected_at).format('YYYY-MM-DD HH:mm:ss') : null,
              duration: e.downtime_seconds ? 
                this.convertSeconds(e.downtime_seconds) : null
            };
          });
        
        } else {
          this.langaugeFetch(this.lang,'No Matching Data Found !!','info');
          this._spinner.hide();
        }
      },
      error:(err) => {
        console.error(err);
        this._spinner.hide();
      },
      complete:()=>{}
  });
  }

  ngAfterViewInit() {
    const divElement = this.myDiv.nativeElement;
    const divstyle = window.getComputedStyle(divElement);
    const display = divstyle?.display;
    if (display === 'none') {
      this.showUnauthorized = true;
    }
  }

  convertSeconds(seconds: number): string {
    let days = null;
    if (seconds > 86400) {
      days = Math.floor(seconds / 86400);
      days = days + ' days,';
    }
    const date = new Date(null);
    date.setSeconds(seconds);
    if (days) {
      return `${days} ${date.toISOString().substring(11, 19)}`;
    } else {
      return date.toISOString().substring(11, 19);
    }
  }

  convert(): string[] {
    return this.differenceArray.map((seconds => this.convertSeconds(seconds)));
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  langaugeFetch(lang, key, toastrstatus) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus === 'info') {
          this._toastr.info(res);
        }
      }
    );
  }
}
