import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';

@Component({
  selector: 'app-lockers-menu',
  templateUrl: './lockers-menu.component.html',
  styleUrls:['./lockers-menu.component.scss']

})

export class LockersMenuComponent implements OnInit {
  @Input() id: any;
  lockerdetails: any;
  hardwareType:any;
  isActive = 'goToTerminals';
  targetRoute='/deliverypoints/';
  constructor(public _router: Router,
    private readonly _deliverypointsService: DeliverypointsService) { }

  ngOnInit(): void {
    if(this._router.url.includes("connectivity")){
      this.isActive = 'filterConnectionLogs';
    }else  if(this._router.url.includes("logs")){
      this.isActive = 'goToLogs';
    } else  if(this._router.url.includes("details")){
      this.isActive = 'goToDetails';
    } else  if(this._router.url.includes("settings")){
      this.isActive = 'goToSettings';
    } else  if(this._router.url.includes("camera")){
      this.isActive = 'goToCamera';
    } else {
        this.isActive = 'goToTerminals';
          }
    this.fetchLockerDetails();
  }

  async fetchLockerDetails() {
    try {
      this.lockerdetails = 
      await this._deliverypointsService.fetchwithId(this.id);
      this.hardwareType = this.lockerdetails?.hardwareType;
    } catch (error) {
      console.error('Error fetching locker details:', error);
    }
  }

  goToDetails() {
    this._router.navigate([this.targetRoute, this.id, 'details']);
  }

  goToLogs() {
    this._router.navigate([this.targetRoute, this.id, 'logs']);
  }

  goToTerminals() {
    this._router.navigate([this.targetRoute, this.id]);
  }

  filterConnectionLogs() {
    this._router.navigate([this.targetRoute, this.id, 'connectivity']);
  }

  goToSettings() {
    this._router.navigate([this.targetRoute, this.id, 'settings']);
  }
  

  goToCamera() {
    if (this.hardwareType === 'KEBA') {
      this._router.navigate([`/deliverypoints/${this.id}/camera`]);
    } else {
      console.log('Camera not available for this hardware type');
    }
  }
}

