<div class="mx-5 mt-5" [appViewController]="{partnerTool:'viewPartnerTool'}">
  <h2 class="text-danger"> {{'Overview' | translate}}</h2>

  <div class="row">
    <div class="col col-md-6">

      <div *ngIf="!loadingData && !lockerNotFree" class="px-3 py-3 bg-success">
        <h4 class="fw-bold">{{'Lockers Available'|translate}}</h4>
        <p class="mb-0">{{'Lockers are available for new orders. Click on one of the buttons below to create or place orders.'|translate}}</p>
      </div>

      <div *ngIf="!loadingData && lockerNotFree" class="px-3 py-3 bg-danger text-white">
        <h4>{{'Sorry! lockers are not available'|translate}}</h4>
        <p class="mb-0">{{'Currently lockers are not available, try again later'|translate}}</p>
      </div>

      <div *ngIf="loadingData" class="px-3 py-3 bg-secondary text-white">
        <h4>{{'checking For locker Availability'|translate}}</h4>
        <p class="mb-0">{{'Please wait we are checking on the availibility of locker'|translate}}</p>
      </div>
      <div class="mt-5">
        <table class="table table-bordered">
          <caption></caption>
          <thead>
            <tr>
              <th scope="col" colspan="2">{{'New Orders'|translate}}</th>

            </tr>
          </thead>
          <thead>
            <tr class="table-info">
              <th scope="col">{{'Date Order'|translate}}</th>
              <th scope="col">{{'Name'|translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let value of orderResult; let i = index">
              <td>{{value.reservePeriodFrom| date:'dd-MM-yyyy'}} - {{value.reservePeriodTo| date:'dd-MM-yyyy'}}</td>
              <td>
                <div  class="d-flex">
                  {{value.customers | fullName | translate }},
                <span class="ml-2"> {{value.customers?.email ||'-'}}</span>
                </div>
                </td>
            </tr>
            <tr>
              <td colspan="2">
                <button class="btn btn-danger float-end" (click)="goToNewOrder()">{{'See More'|translate}}</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class=" col col-md-6">
      <table class="table table-bordered">
        <caption></caption>
        <thead>
          <tr>
            <th scope="col" colspan="3">{{'toBeProcessed'|translate}}</th>

          </tr>
        </thead>
        <thead>
          <tr class="table-info">

            <th class="date-column" scope="col">{{'Name'|translate}}</th>
            <th scope="col">{{'Status'|translate}}</th>
            <th scope="col">{{'whenToPickUp'|translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let value of orderToProcess; let i = index">
            <td>
              <div class="d-flex">
                {{value.customers | fullName | translate }},
                <span class="ml-2"> {{value.customers?.email ||'-'}}</span>
              </div>
            </td>
            <td>{{value.currentStatus}}</td>
            <td>{{value.reservePeriodFrom| date:'dd-MM-yyyy'}} - {{value.reservePeriodTo| date:'dd-MM-yyyy'}}</td>
          </tr>
          <tr>
            <td colspan="3">
              <button class="btn btn-danger float-end" (click)="goToPlacedOrder()">{{'See More'|translate}}</button>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="table table-bordered mt-5">
        <caption></caption>
        <thead>
          <tr>
            <th scope="col" colspan="3">{{'placedinLocker'|translate}}</th>
          </tr>
        </thead>
        <thead>
          <tr class="table-info">
            <th class="date-column" scope="col">{{'Name'|translate}}</th>
            <th scope="col">{{'Status'|translate}}</th>
            <th scope="col">{{'whenToPickUp'|translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let value of vaultResponse; let i = index">
            <td>
              <div class="d-flex">
                {{value.customers | fullName | translate }},
                <span class="ml-2"> {{value.customers?.email ||'-'}}</span>
              </div>
            </td>
            <td>{{value.currentStatus}}</td>
            <td>{{value.reservePeriodFrom| date:'dd-MM-yyyy'}} - {{value.reservePeriodTo| date:'dd-MM-yyyy'}}</td>
          </tr>
          <tr>
            <td colspan="3">
              <button class="btn btn-danger float-end" (click)="goToVault()">{{'See More'|translate}}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
