import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location, DatePipe } from '@angular/common';
import { ParcelService } from '../../../Service/parcel.service';
import { DeliverypointsService } from '../../../Service/deliverypoints.service';
import * as $ from 'jquery';
import { Options, ChangeContext } from '@angular-slider/ngx-slider';
import * as moment from 'moment';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { utcFormatter } from '../../timeFormatter';
import { SpinnerService } from 'src/app/Service/spinner.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
export class NgxQrCode {
  text: string;
}

@Component({
  selector: 'app-parcel-details',
  templateUrl: './parcel-details.component.html',
  styleUrls: ['./parcel-details.component.scss']
})
export class ParcelDetailsComponent implements OnInit, AfterViewInit {

  loading: boolean;
  id: any;
  body: any;
  data: any;
  result: any;
  activity: Array < any > = [];
  barcode: any;
  href: any;
  type: boolean;
  showSpinner: boolean;

  changeParcelStatuses: any;
  bdeStatus: any;
  druStatus: any;
  changeParcelStatus: any;

  minimunExpirationDate: string;

  // Variables for changing compartments
  changeParcelCompartmentReadyness: boolean;
  cpcDpd: any;
  currentLocker: any; // This is the full information of where this parcel is
  // currently phisically availableç
  currentCompartment: any;
  currentTerminal: any;
  changeCompartmentOpts: any;
  currentParcelStatus: any;


  public qrdata: string = null;
  public parcelForm;
  public parcelDetailsForm;

  public expiryRequestForm;
  public showPickupInstruction = false;

  dateRange: Date[];
  lowValue: number;
  highValue: number;
  historicalDetails: any = [];
  isEligibleForExpiryUpdate = false;
  iscancelled = false;
  eligibleForAssignComparment = false;
  isEligibleForProcessManually = true;
  startDate: number;
  stepArrayData: any;
  isEligibleForChangeCompartment = false;
  sliderOptions: Options = {};

  parcelType: any;
  isFraud: boolean;
  fraudbutton: boolean;
  postmanDroppedItem: any;
  postmanDroppedItemCode: any;
  activityName: any;
  pickupCode: any;
  parcelCurrentFlow: any;
  fmandlmCheckCode: any;
  fmandlmSubCode: any;
  public copyBtn = 'Copy';
  maxchar = 150;
  isLockerEmpty = false;
  delivery: any;
  collected: any;
  isReserved = false;
  isSoftReserved=false;
  isFirstMileReserved = false;
  showUnauthorized = false;
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;
  @ViewChild('qr', { static: false }) qr: ElementRef;
  @ViewChild('changeExpiry', { static: false }) changeExpiry: ElementRef;
  @ViewChild('processManual', { static: false }) processManual: ElementRef;
  @ViewChild('triggerFraud', { static: false }) triggerFraud: ElementRef;
  @ViewChild('resendEmail', { static: false }) resendEmail: ElementRef;
  @ViewChild('optionCancel', { static: false }) optionCancel: ElementRef;
  bde23Status: boolean;
  bdu1Status: boolean;
  ace2Status: boolean;
  dru1Status: boolean;
  nde12Status: boolean;
  toolTipval: any;
  newDate: any;
  lockerFetchfail: any;
  translateData: any;
  Postmanexpiredpickup: any;
  Pickedupbypostman: any;
  Pickedupbycustomer: any;
  isEligible: boolean;
  histDetails: any;
  showOption = true;
  partnerName: any;
  dateFailed: string;
  targetFormattedDate='yyyy-MM-dd HH:mm:ss';
  textMsg='This action requires a delivered status';
  greyClass='grey-class';
  whiteClass ='white-class';
  fraudBarcode='Fraud barcode';
  lmReservationReq ='LM Reservation required';
  alreadyPickedUp='Already picked up by postman'
  constructor(
    private readonly _langtransService: LangtransService,
    public _activatedRoute: ActivatedRoute,
    private readonly _toastr: ToastrService,
    private readonly _ParcelService: ParcelService,
    private readonly _DeliverypointsService: DeliverypointsService,
    private readonly _datePipe: DatePipe,
    private readonly _spinner: SpinnerService,
    private readonly _translate: TranslateService,
    private readonly _router: Router,
    private readonly _location: Location
  ) {
    this.bdeStatus = {
      flow: 'LM',
      eventCode: 'BDE',
      eventSubCode: '23',
      description: 'Delivered by postman'
    };
    this.druStatus = {
      flow: 'FM',
      eventCode: 'DRU',
      eventSubCode: '1',
      description: 'Delivered by customer'
    };
  }

  loadCurrentLocker(deliverypoint: any, itemBarcode: any): Promise < void > {
    return this._DeliverypointsService.fetchwithId(deliverypoint.id, {
      includeTerminals: true,
      includeCompartments: true
    })
      .then(currentDds => {
        this.currentLocker = currentDds;

        this.currentLocker.terminals.map(t => {
          this.currentCompartment = t.compartments.find(c => {
            return c.itemBarcode === itemBarcode;
          });

          if (this.currentCompartment) {
            this.currentTerminal = t;
          }
        });
      });
  }

  langaugeFetch(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (key === 'Postmanexpiredpickup') {
          this.Postmanexpiredpickup = res;
        }
        if (key === 'Pickedupbypostman') {
          this.Pickedupbypostman = res;
        }
        if (key === 'Pickedupbycustomer') {
          this.Pickedupbycustomer = res;
        }
        this.changeParcelStatuses = [{
          flow: 'LM',
          eventCode: 'NDE',
          eventSubCode: '12',
          description: this.Postmanexpiredpickup
        },
        {
          flow: 'LM',
          eventCode: 'BDU',
          eventSubCode: '1',
          description: this.Pickedupbycustomer
        },
        {
          flow: 'FM',
          eventCode: 'ACE',
          eventSubCode: '2',
          description: this.Pickedupbypostman
        },
        ];
      }
    );
  }

  changeParcelCompartment(): void {
    // Check the parcel is currently assigned to a compartment:
    if (!this.result.dds) { return; }

    this.loadCurrentLocker(this.result.dds.deliverypoint, this.result.barcode)
      .then(() => {
        this.changeCompartmentOpts = this.currentLocker.terminals.map(t => {

          let label = 
          `TERMINAL ${t.id} (${t.externalId || 'no external id'})`;
          t.compartments.sort((a, b) =>
            a.number - b.number);
          return {
            label: label,
            disabled: !t.active,
            compartments: t.compartments.map(c => {
               label = `COMPARTMENT N. ${c.number}  (${c.size})`;

              if (c.itemBarcode) { label += ` (${c.itemBarcode})`; }
              if (!c.active) { label += ' (inactive)'; }
              if (c.itemBarcode === this.result.barcode) {
                label += ' (this same parcel)';
              }
              return {
                value: c.id,
                label: label,
                disabled: !c.active || c.itemBarcode === this.result.barcode
              };
            })
          };
        });
      });
  }

  changeParcelCompartmentTrigger(compartmentId: any): void {
    compartmentId = parseInt(compartmentId);
    const selectedTerminal = this.currentLocker.terminals.find(t => {
      return t.compartments.find(c => parseInt(c.id) === compartmentId);
    });

  
   

    if (!selectedTerminal) { return; }

    const terminalId = Number(selectedTerminal.id);
    const status = this.currentCompartment.status;

    this.setLoading(true);
    this._ParcelService.changeCompartment(this.id, {
      terminalId,
      compartmentId,
      status
    })
      .then(res => {
        this._toastr.success(this._translate.instant(
          'Compartment changed correctly'), this._translate.instant('Success'));
        this.ngOnInit();
      })
      .catch(err => {
        this._toastr.error(this._translate.instant(
          'Error changing the parcel compartment'), 
        this._translate.instant('Failed'));
        console.error(err);
      })
      .finally(() => {
        this.setLoading(false);
        this.loadCurrentLocker(this.result.dds.deliverypoint, 
          this.result.barcode);
      });
  }

  setLoading(loading: boolean): void {
    this.loading = loading;
    if (loading === false) {
      this._spinner.hide();
    } else {
      this._spinner.show();
    }
  }

  ngAfterViewInit() {
    this._translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.changeParcelStatuses = [{
        flow: 'LM',
        eventCode: 'NDE',
        eventSubCode: '12',
        description: this._translate.instant('Postman expired pick up')
      },
      {
        flow: 'LM',
        eventCode: 'BDU',
        eventSubCode: '1',
        description: this._translate.instant('Picked up by customer')
      },
      {
        flow: 'FM',
        eventCode: 'ACE',
        eventSubCode: '2',
        description: this._translate.instant('Picked up by postman')
      },
      ];
      this.checkValidChangeParcelStatusDropdwon();
      this.computeEligibilityForExpiryUpdate();
    });

    const divElement = this.myDiv.nativeElement;
    const divstyle = window.getComputedStyle(divElement);
    const display = divstyle?.display;
    if (display === 'none') {
      this.showUnauthorized = true;
    }

    const qr = this.qr.nativeElement;
    const qrliststyle = window.getComputedStyle(qr);
    const qrdisplay = qrliststyle?.display;

    const changeExpiry = this.changeExpiry.nativeElement;
    const changeExpiryliststyle = window.getComputedStyle(changeExpiry);
    const changeExpirydisplay = changeExpiryliststyle?.display;

    const processManual = this.processManual.nativeElement;
    const processManualliststyle = window.getComputedStyle(processManual);
    const processManualdisplay = processManualliststyle?.display;

    const triggerFraud = this.triggerFraud.nativeElement;
    const triggerFraudliststyle = window.getComputedStyle(triggerFraud);
    const triggerFrauddisplay = triggerFraudliststyle?.display;

    const resendEmail = this.resendEmail.nativeElement;
    const resendEmailliststyle = window.getComputedStyle(resendEmail);
    const resendEmaildisplay = resendEmailliststyle?.display;

    const optionCancel = this.optionCancel.nativeElement;
    const optionCancelliststyle = window.getComputedStyle(optionCancel);
    const optionCanceldisplay = optionCancelliststyle?.display;

    if (optionCanceldisplay === 'none' && resendEmaildisplay === 'none' && 
      triggerFrauddisplay === 'none' && processManualdisplay === 'none' && 
      qrdisplay === 'none' && changeExpirydisplay === 'none') {
      this.showOption = false;
    }
  }

  ngOnInit(): void {
    this.dateFailed ='Updating Expiration Date Failed'
    this.changeParcelStatuses = [{
      flow: 'LM',
      eventCode: 'NDE',
      eventSubCode: '12',
      description: this._translate.instant('Postman expired pick up')
    },
    {
      flow: 'LM',
      eventCode: 'BDU',
      eventSubCode: '1',
      description: this._translate.instant('Picked up by customer')
    },
    {
      flow: 'FM',
      eventCode: 'ACE',
      eventSubCode: '2',
      description: this._translate.instant('Picked up by postman')
    },
    ];
    $('.test').click(function () {
      $(this).find('i').toggleClass('fa fa-plus fa fa-minus');
    });
    $('.details').click(function () {
      $(this).find('i').toggleClass('fa fa-chevron-down fa fa-chevron-up');
    });
    this.id = this._activatedRoute.snapshot.paramMap.get('id');
     this.getParcelInformation(this.id);

    this.parcelForm = new FormGroup({
      comments: new FormControl('', [])
    });

    this.parcelDetailsForm = new FormGroup({
      dateOfExpirationFrom: new FormControl('', []),
      dateOfExpirationTo: new FormControl('', []),
    });

    this.expiryRequestForm = new FormGroup({
      expiryHours: new FormControl(''),
      pickupInstruction: new FormControl(''),
      comment: new FormControl('')
    });
    this.showPickupInstruction = false;

    setTimeout(() => {
      if (!this.result?.reservation) { return; }

      const {
        from,
        to
      } = this.result.reservation;

      if (!from || !to) { return; }

      this.parcelDetailsForm.patchValue({
        'dateOfExpirationFrom': from.substring(0, from.length - 8),
        'dateOfExpirationTo': to.substring(0, to.length - 8)
      });
      
    }, 3000);
    this.checkValidChangeParcelStatusDropdwon();
    this.minimunExpirationDate = new Date().toISOString().slice(0, 16); 
  }

  changeParcelStatusSet(status: any) {
    this.changeParcelStatus = status;
  }


  changeParcelStatusTrigger(status: any) {
    this.setLoading(true);
    this._ParcelService.changeStatus(this.id, status)
      .then(res => {
        this.activityCall(this.id);
        this._toastr.success(`Status changed to ${status.description}`, 
          this._translate.instant('Success'));
      })
      .catch(err => {
        this._toastr.error(this._translate.instant(
          'Error changing the parcel status'), 
        this._translate.instant('Failed'));
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  async getParcelInformation(reservationId) {
    let expiryDate = null;
    this.setLoading(true);
    this._ParcelService.getOne(reservationId)
      .subscribe(async res => {
        this.isFraud = false;
        this.bdu1Status = false;
        this.bde23Status = false;
        this.ace2Status = false;
        this.dru1Status = false;
        this.nde12Status = false;
        this.activityCall(reservationId);
        this.setLoading(false);
        this.type = true;
        this.result = res;
        this.partnerName = this.result?.dds?.partner?.name;
        //date formatting new
        if (this.result.date) {
          const dateval = moment(this.result.date).format('YYYY-MM-DD');
          this.result.date = dateval;
        }
        if (this.result.firstmileDate) {
          const dateval = this.result.firstmileDate.split('Z').join('');
          this.result.firstmileDate = dateval;
        }
        
        if (this.result.reservation && this.result.reservation.isReserved) {
          this.isReserved = true;
        }
        if (this.result.reservation && 
          this.result.reservation.isReservedFirstMile) {
          this.isFirstMileReserved = true;
        }
        this.parcelType = this.result.deliveryPointOriginal.provider;
        if (this.parcelType === 'bpost') {
          this.isEligibleForProcessManually = false;
        }
        const fetchColumn = 'parcelPickUpCode,currentStatus,expiryDate,allPreviousStatus,currentFlow,deliveredBy,collectedBy,parcelPickUpCode';
        const parcelInfo: any = await this._ParcelService.getParcelInfo(this.result.parcelId, fetchColumn);
        if (parcelInfo?.parcelPickUpCode) {
          this.pickupCode = parcelInfo.parcelPickUpCode
        }
        if (parcelInfo?.expiryDate) {
          expiryDate = parcelInfo.expiryDate;
        }
        if (parcelInfo?.currentStatus) {
          this.currentParcelStatus = parcelInfo.currentStatus;
        }
        if(this.currentParcelStatus==='SOFT_RESERVED'){
          this.isSoftReserved =true;
        }
        if (this.result.deliveryPointOriginal.provider === 'Lean' || 
          this.result.deliveryPointOriginal.provider === '') {
          this.type = false;
        }
        this.checkValidChangeParcelStatusDropdwon();
        this._ParcelService.getHistoricalData(
          this.result.id
        ).subscribe({
          next:(result: any) => {
          this.histDetails = result;
          if (this.histDetails.historicalData.length > 0) {
            this.historicalDetails = this.histDetails.historicalData;
            this.computeEligibilityForExpiryUpdate();
            this.setExiprationdata(this.historicalDetails);
            this.checkBdeAndBduEligibility();
            this.computeProcessEligibility();
            this.checkValidChangeParcelStatusDropdwon();
          }
        },
        error:err=>{},
        complete:()=>{}
      });

        if (expiryDate) {
          const hDate = new Date(new Date(expiryDate).toDateString());
          this.highValue = hDate.getTime();
          this.result.expirationDate = expiryDate;
        } else {
          const dbDate = new Date(this.lowValue);
          const intialExipiration = dbDate.setDate(dbDate.getDate() + 5);
          const highValueNew = new Date(intialExipiration).getTime();
          this.highValue = highValueNew;
          this.result.expirationDate = expiryDate;
        }

        if (this.result.parcelLockerRetrievalCode) {
          this.qrdata = this.result.parcelLockerRetrievalCode;
        }
        if (parcelInfo?.currentFlow) {
          this.parcelCurrentFlow = parcelInfo.currentFlow;
        }
        if (parcelInfo) {
          this.delivery = parcelInfo.deliveredBy;
          this.collected = parcelInfo.collectedBy;
        }
      }, (error) => this._toastr.error(this._translate.instant(
        'failedToFetch'), this._translate.instant('Failed')));
  }
  activityCall(reservationId) {
    this._ParcelService.getActivityDetails(reservationId)
      .subscribe(async (res: any) => {
        this.activity = res
        this.checkLockerEmpty();
        this.activity = this.activity.map(obj => {
          if (obj.eventCode === 'BDE' && 
            obj.eventSubCode === '23' && this.delivery) {
            return {
              ...obj,
              actor: this.delivery
            };
          } else if ((obj.eventCode === 'ACE' && obj.eventSubCode === '2') || 
          (obj.eventCode === 'NDE' && obj.eventSubCode === '12')) {
            return {
              ...obj,
              actor: this.collected
            };
          }
          return obj;
        });
        //replacement logic for strange code bwlow
        if (this.activity && this.activity.length) {
          for (const activObj of this.activity) {
            if (activObj.action === 'CANCEL') {
              this.iscancelled = true;
            }
            if (activObj.action === 'FRAUD') {
              this.isFraud = true;
            }
          }

        } else {
          this.iscancelled = false;
          this.isFraud = false;
        }

        for (const element of this.activity) {
          if (element.eventDate) {
            //Assuming that date is in UTC, so converting to local time
            const utcEventDate = element.eventDate.replace('Z', '');
            const localEventDate = moment.utc(utcEventDate).toDate();
            element.eventDate = 
            moment(localEventDate).format('YYYY-MM-DD HH:mm:ss');
          }
        }
      }, (error) => {
        this._toastr.error(this._translate.instant(
          'failedToFetch'), this._translate.instant('Failed'));
        console.error(error);
      });

  }

  checkLockerEmpty = () => {
    for (const activityObj of this.activity) {
      if (activityObj.action === 'LOCKER_IS_EMPTY') {
        this.isLockerEmpty = true;
      }
    }
  };

  copytoClipboardPickupCode() {
    try {
      const text = document.getElementById('copyPickupCode').innerText;
      const f = navigator.userAgent.search('Firefox');
      if (f > -1) {
        alert('not compatable with firefox please try with any other broswer');
      } else {
        navigator.clipboard.writeText(text);
        this.copyBtn = 'Copied';
      }
    } catch {
      alert('Sorry not able to copy Please retry or try download by right click from browser');
    }
  }

  copy() {
    document.execCommand('copy');
  }

  sendComments() {
    this.body = {
      text: this.parcelForm.value.comments
    };
    this._ParcelService.insertCommentsToDB(this.id, this.body).subscribe({
      next:res => {
        this._toastr.success(this._translate.instant(
          'Comment Added'), this._translate.instant('Success'));
        this.ngOnInit();
      },
      error:err => {
        this._toastr.error(this._translate.instant(
          'Adding Comment Failed'), this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
  });
  }

  cancelOrder() {
    const cancelOrderBody = {
      action: 'CANCEL'
    };
    this._ParcelService.postRetrievalCode(this.id, cancelOrderBody).subscribe({
      next:res => {
        this.activityCall(this.id);
        this._toastr.success(this._translate.instant(
          'Parcel Cancelled'), this._translate.instant('Success'));
      },
      error:err => {
        this._toastr.error(this._translate.instant(
          'Cancelling The Order Failed'), this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
  });
  }

  flagFraud() {
    const flagFraudBody = {
      action: 'FRAUD'
    };
    this._ParcelService.postRetrievalCode(this.id, flagFraudBody).subscribe({
      next:res => {
        this.fraudbutton = true;
        this.activityCall(this.id);
        this._toastr.success(this._translate.instant(
          'Parcel Flagged As Fraud'), this._translate.instant('Success'));
      },
      error:err => {
        this.fraudbutton = false;
        this._toastr.error(this._translate.instant(
          'Flagging As Fraud Failed'), this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
  });
  }

  processManually() {
    const processManuallyBody = {
      action: 'CANCEL'
    };
    this._ParcelService.postRetrievalCode(
      this.id, processManuallyBody).subscribe({
      next:res => {
        this.activityCall(this.id);
        this._toastr.success(this._translate.instant(
          'Process Manually Updated'), this._translate.instant('Success'));
      },
      error:err => {
        this._toastr.error(this._translate.instant(
          'Processing Manually Failed'), this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
  });
  }

  updateExpirationDateNew = () => {
    this.setLoading(true);
    const timehours = moment();
    this.newDate = 
    timehours.add(Number(this.expiryRequestForm.value.expiryHours), 
      'hours').format('YYYY-MM-DD HH:mm:ss');
    const updateExpirationRequest = {
      expirationBarcode: this.result.barcode,
      expiryHours: this.expiryRequestForm.value.expiryHours,
      pickupInstruction: this.expiryRequestForm.value.pickupInstruction,
      comment: this.expiryRequestForm.value.comment
    };
    this._ParcelService.postRetrievalCode(
      this.id, updateExpirationRequest).subscribe({
      next:(res: any) => {
        this.setLoading(false);
        this.setLoading(false);
        if (res.expiryDate) {
          const expDate = 
          this._datePipe.transform(res.expiryDate, this.targetFormattedDate);
          this.result.expirationDate = expDate;
          this.activityCall(this.id);
          this._toastr.success(this._translate.instant(
            'Expiration Date Updated'), this._translate.instant('Success'));
        } else {
          this._toastr.error(res.message, this._translate.instant('Failed'));
        }
      },
      error:err => {
        this.setLoading(false);
        this._toastr.error(this._translate.instant(
          this.dateFailed), 
        this._translate.instant('Failed'));
        console.error(err);
        this.setLoading(false);
      }
  });

  };

  updateExpirationDate() {
    this.setLoading(true);
    const updateExpirationDateBody = {
      barcode: this.result.barcode,
      lmMaxAllowance: 
      this.parcelDetailsForm.value.dateOfExpirationTo + ':00.000Z'
    };
    this._ParcelService.postRetrievalCode(
      this.id, updateExpirationDateBody).subscribe({
      next:(res: any) => {
        this.setLoading(false);
        if (res.expiryDate) {
          const expDate = 
          this._datePipe.transform(res.expiryDate, this.targetFormattedDate);
          this.result.expirationDate = expDate;
          this._toastr.success(this._translate.instant(
            'Expiration Date Updated to') + expDate, 
          this._translate.instant('Success'));
        } else {
          this._toastr.error(res.message, this._translate.instant('Failed'));
        }
      },
      error:err => {
        this._toastr.error(this._translate.instant(
          this.dateFailed), 
        this._translate.instant('Failed'));
        console.error(err);
        this.setLoading(false);
      },
      complete:()=>{}
  });
  }

  checkDisableExpirationUpdate = () => {
    const currentDateString = new Date().toISOString();
    const expirationDateString = this.result?.expirationDate;
    if (expirationDateString) {
      const expirationDate = new Date(expirationDateString.split('T')[0]);
      const currentDate = new Date(currentDateString.split('T')[0]);
      const diff = expirationDate.getTime() - currentDate.getTime();
      const daydiff = diff / (1000 * 60 * 60 * 24);
      if (daydiff >= 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  updateEmail(barcode) {
    this.data = {
      purpose: 'LASTMILE_DELIVERED'
    };
    this._ParcelService.sendEmail(this.data, barcode).subscribe({
      next:res => {
        this._toastr.success(this._translate.instant('Data Send Successfuly'), 
          this._translate.instant('Success'));
      },
      error:err => {
        this._toastr.error(this._translate.instant('data sending failed !!'), 
          this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
  });
  }

  onRadioSelect = (val) => {
    if (val === 0) {
      this.showPickupInstruction = true;
    } else {
      this.showPickupInstruction = false;
    }
  };
  shouldDisplayPickupInstruction = () => {
    return this.showPickupInstruction;
  };

  setExiprationdata(data) {
    for (let i = 0; i < data.length; i++) {
      const elem: any = this.historicalDetails[i];
      if (elem.eventCode === 'BDE' && elem.eventSubCode === '23') {
        const startDatenew = new Date(elem.eventTime).getTime();
        this.startDate = startDatenew;
        this.customDateRange();
      }

    }
  }

  customDateRange() {
    this.lowValue = this.startDate;
    const dates: Date[] = [];
    let startingDate = new Date(this.lowValue);
    startingDate = this.getFormattedDate(startingDate);
    dates.push(startingDate);
    for (let i = 1; i <= 30; i++) {
      let date = new Date(this.startDate);
      date = this.getFormattedDate(date);
      date.setDate(date.getDate() + i);
      dates.push(date);
    }
    this.dateRange = dates;
    this.sliderOptions.showSelectionBar = false;

    this.sliderOptions.stepsArray = dates.map((date: Date) => {
      return {
        value: date.getTime()
      };
    });
    this.sliderOptions.translate = (value: number): 
    string => {
      return new Date(value).toDateString();
    };
  }

  sliderValChange = (changeContext: ChangeContext) => {
    const firstDateRangevalue = this.dateRange[0].toLocaleDateString();
    const firstLowValue = new Date(this.lowValue).toLocaleDateString();

    if (firstLowValue !== firstDateRangevalue) {
      this._toastr.error(this._translate.instant(
        'cannot update start date !!'), this._translate.instant('Failed'));
      this.resetStartExp();
      return;
    }
    let updateToDate = new Date(this.highValue);
    updateToDate = this.getFormattedDate(updateToDate);
    this.updateClassicExpiration(updateToDate);

  };
  resetStartExp = () => {
    setTimeout(() => {
      this.lowValue = this.dateRange[0]?.getTime();
    }, 500);
  };


  updateClassicExpiration = (isodate) => {

    const updateExpirationRequest = {
      expirationBarcodeClassic: this.result.barcode,
      expirationDate: this._datePipe.transform(isodate, this.targetFormattedDate),
      deliverypointId: this.result.dds.deliverypoint?
        (this.result.dds.deliverypoint.id).toString() : null
    };
    this._ParcelService.postRetrievalCode(
      this.id, updateExpirationRequest).subscribe({
      next:(res: any) => {
        this.setLoading(false);
        this.setLoading(false);
        if (res.expiryDate) {
          const expDate = this._datePipe.transform(
            res.expiryDate, this.targetFormattedDate);
          this.result.expirationDate = expDate;
          this._toastr.success(this._translate.instant(
            'Expiration Date Updated'), this._translate.instant('Success'));
        } else {
          this._toastr.error(res.message, this._translate.instant('Failed'));
        }
      },
      error:err => {
        this.setLoading(false);
        this._toastr.error(this._translate.instant(
          this.dateFailed), 
        this._translate.instant('Failed'));
        console.error(err);
        this.setLoading(false);
      },
      complete:()=>{}
  });
  };

  getFormattedDate(date: Date) {
    const d = date;
    d.setHours(0, 0, 0, 0); // previous midnight day
    return d;
  };

  computeEligibilityForExpiryUpdate() {
    const hasBDE23 = 
    this.historicalDetails.some((elem: any) => elem.eventCode === 'BDE' && 
    elem.eventSubCode === '23');
    const hasNDE12 = 
    this.historicalDetails.some((elem: any) => elem.eventCode === 'NDE' && 
    elem.eventSubCode === '12');
    const hasACE2 = 
    this.historicalDetails.some((elem: any) => elem.eventCode === 'ACE' && 
    elem.eventSubCode === '2');
    const hasDRU1 = 
    this.historicalDetails.some((elem: any) => elem.eventCode === 'DRU' && 
    elem.eventSubCode === '1');
    const hasBDU1 = 
    this.historicalDetails.some((elem: any) => elem.eventCode === 'BDU' && 
    elem.eventSubCode === '1');

    this.isEligibleForExpiryUpdate = 
    hasBDE23 && !hasNDE12 && !hasACE2 && !this.isFraud && !this.iscancelled;

    if (!this.isFraud && !this.iscancelled) {
      if (hasBDE23 && hasDRU1) {
        if (!hasBDU1 && !hasNDE12) {
          this.isEligibleForChangeCompartment = true;
        }
      } else {
        this.toolTipval = this._translate.instant(
          this.textMsg);
        if (hasDRU1 && !hasACE2) {
          this.isEligibleForChangeCompartment = true;
        }
        if (hasBDE23 && !hasBDU1 && !hasNDE12) {
          this.isEligibleForChangeCompartment = true;
        }
      }
    }
  }

  onTextAreaInput(event: Event): void {
    const target = event.target as HTMLTextAreaElement;
    if (target.value.length > this.maxchar) {
      target.value = target.value.substring(0, 150);
    }
  }

  checkBdeAndBduEligibility() {
    const ind = this.historicalDetails.length;
    const elem: any = this.historicalDetails[ind - 1];
    const {
      eventCode,
      eventSubCode
    } = elem;

    this.nde12Status = eventCode === 'NDE' && eventSubCode === '12';  // LM expired postman pick up
    this.bde23Status = eventCode === 'BDE' && eventSubCode === '23';  // LM delivered by postman
    this.bdu1Status = eventCode === 'BDU' && eventSubCode === '1';    // LM collected by customer

    this.ace2Status = eventCode === 'ACE' && eventSubCode === '2';    // FM pickedup by postman
    this.dru1Status = eventCode === 'DRU' && eventSubCode === '1';    // FM delivered by customer
  }
  checkValidChangeParcelStatusDropdwon() {
    const newValidChangeParcelStatusForClassic = [];
    const chnageSatusOptions = this.changeParcelStatuses;
    for (const element of chnageSatusOptions) {
      const elementValue = element;

      const {
        eventCode,
        eventSubCode
      } = elementValue;

      if (this.isFraud === true) {
        elementValue.valClass = this.greyClass;
        elementValue.toolTipval = this._translate.instant(this.fraudBarcode);
      } 
      else {
        elementValue.valClass = this.whiteClass;
        if (eventCode === 'ACE' && eventSubCode === '2') { // FM pickedup by postman

        if(!this.isFirstMileReserved){
          elementValue.valClass = this.greyClass;
        }else if(
          this.isFirstMileReserved &&
          !this.dru1Status &&
          !this.ace2Status &&
          this.isSoftReserved === true
        ){
          elementValue.valClass=this.greyClass
        }else{
          elementValue.valClass=this.whiteClass
        }
         
          if( !this.isFirstMileReserved){
            elementValue.toolTipval =  this._translate.instant('FM Reservation required')
          }else if(!this.dru1Status &&
            !this.ace2Status
          ){
            elementValue.toolTipval =  this._translate.instant(this.textMsg) 
          }else if(this.ace2Status){
            elementValue.toolTipval= this._translate.instant(this.alreadyPickedUp)
          }else{
            elementValue.toolTipval=null;
          }
        }

        if (eventCode === 'BDU' && eventSubCode === '1') {
          if (this.result.deliveryPointAssigned.provider == 'Cubee') {
            elementValue.valClass=this.greyClass;
            
            if( !this.isReserved){
              elementValue.toolTipval =  this._translate.instant(this.lmReservationReq)
            }else if(!this.bdu1Status){
              elementValue.toolTipval =  this._translate.instant(this.textMsg) 
            }else if(this.bdu1Status){
              elementValue.toolTipval= this._translate.instant(this.alreadyPickedUp)
            }else{
              elementValue.toolTipval=null;
            }
          }else{
            if(!this.isReserved){
              elementValue.valClass = this.greyClass;
            }else if(
              this.isReserved &&
              !this.bdu1Status &&
              this.isSoftReserved 
            ){
              elementValue.valClass=this.greyClass
            }else{
              elementValue.valClass=this.whiteClass
            }
            
            if( !this.isReserved){
              elementValue.toolTipval =  this._translate.instant(this.lmReservationReq)
            }else if(!this.bdu1Status){
              elementValue.toolTipval =  this._translate.instant(this.textMsg) 
            }else if(this.bdu1Status){
              elementValue.toolTipval= this._translate.instant(this.alreadyPickedUp)
            }else{
              elementValue.toolTipval=null;
            }
          }
       
      }
        if (eventCode === 'NDE' && eventSubCode === '12') {
          if (this.result.deliveryPointAssigned.provider == 'Cubee') {
           
              elementValue.valClass = this.greyClass;
           
            if( !this.isReserved){
              elementValue.toolTipval =  this._translate.instant(this.lmReservationReq)
            }else if(this.bdu1Status){
              elementValue.toolTipval =  this._translate.instant('Already picked up by customer') 
            }else{
              elementValue.toolTipval=null;
            }
          }else{
            if(!this.isReserved || this.bdu1Status && this.isSoftReserved){
              elementValue.valClass = this.greyClass;
            }
            else{
              elementValue.valClass=this.whiteClass
            }
  
            if( !this.isReserved){
              elementValue.toolTipval =  this._translate.instant(this.lmReservationReq)
            }else if(this.bdu1Status){
              elementValue.toolTipval =  this._translate.instant('Already picked up by customer') 
            }else{
              elementValue.toolTipval=null;
            }
          }
        

        }
      }
      newValidChangeParcelStatusForClassic.push(elementValue);
    }
    this.changeParcelStatuses = newValidChangeParcelStatusForClassic;
  }
  public getTooltipTextLM(): string {
    if (this.isFraud === true) {
      return this._translate.instant(this.fraudBarcode);
    } else if (this.isReserved === true) {
      if (this.bde23Status === true) {
        return this._translate.instant('Already delivered by postman');
      }
      if (this.bdu1Status === true) {
        if (this.nde12Status === true) {
          return this._translate.instant('Postman pick up expired');
        } else {
          return this._translate.instant('Already picked up by customer');
        }
      }

      return null;
    } else {
      return this._translate.instant(this.lmReservationReq);
    }
  }

  public getDropdownClsName(): string {
    if (this.isReserved === true) {
      if (this.bdu1Status === true || this.isFraud === true || 
        this.nde12Status === true || this.isSoftReserved===true) {
        return this.greyClass;
      }
      if (this.bdu1Status === false && this.isFraud === false) {
        return this.whiteClass;   
      }
    } else {
      return this.greyClass;
    }
    return this.whiteClass
  }

  public getTooltipTextForFM(): string {
    if (this.isFraud === true) {
      return this._translate.instant(this.fraudBarcode);
    } else if (this.isFirstMileReserved === true) {
      if (this.dru1Status === true) {
        return this._translate.instant('Already delivered by customer');
      }
      if (this.ace2Status === true) {
        if (this.nde12Status === true) {
          return this._translate.instant('Postman pick up expired');
        } else {
          return this._translate.instant(this.alreadyPickedUp);
        }
      }
      return null;
    } else {
      return this._translate.instant('FM Reservation required');
    }
  }

  public getDropdownClsNameForFM(): string {
    if (this.isFirstMileReserved === true) {
      if (this.ace2Status === true || this.isFraud === true || 
        this.nde12Status === true || this.isSoftReserved===true) {
        return this.greyClass;
      }
      if (this.ace2Status === false && this.isFraud === false) {
        return this.whiteClass;
      }
    } else {
      return this.greyClass;
    }
    return this.whiteClass;
  }

  checkforInactive(value: any) {
    return value.includes('inactive');
  }
  computeProcessEligibility() {
    this.isEligible = this.historicalDetails.some(
      item => item.eventCode === 'BDE' && item.eventSubCode === '23');
  }

  timeFormatter(date) {
    const formattedDateTime = utcFormatter(date);
    if (!formattedDateTime) { return null; }
    return moment(formattedDateTime).format('DD-MM-YYYY');
  }

  goToParcelList() {
    this._location.back();  }
}
