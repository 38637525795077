import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AbstractControl, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import { ParcelService } from '../../Service/parcel.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { AppService } from '../../Service/app.service';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-useroperations',
  templateUrl: './useroperations.component.html',
  styleUrls: ['./useroperations.component.scss']
})

export class UseroperationsComponent implements OnInit {
  eventTypedropdownList = [];
  eventTypedropdownSettings: IDropdownSettings;
  showSpinner = true;
  eventTypeSelected = [];

  public userOperationForm;
  result: any;
  list: any[];
  dropdownValue: any[];
  dataExport: any;
  operation: any;
  translateData: any;
  noFound: string;
  selectFields: string;
  isFromDate = false;
  isToDate = false;
  @ViewChild('dateFromInput') dateFromInput: ElementRef;
  @ViewChild('dateToInput') dateToInput: ElementRef;
  maxDate = new Date();
  constructor(
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
    private readonly _toastr: ToastrService,
    private readonly _ParcelService: ParcelService,
    private readonly _renderer: Renderer2,
    private readonly _datePipe: DatePipe,
    private readonly _translate: TranslateService
  ) { }

  ngOnInit(): void {
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });

    this.userOperationForm = new FormGroup({
      reportDate: new FormGroup({
        dateFrom: new FormControl(''),
        dateTo: new FormControl(''),
       }, [this.dateRangeValidator]),
      
      
      eventType: new FormControl('', [])
    });
  }
  get formValidators() {
    return this.userOperationForm.controls
  }

  loading(state: boolean): Promise<any> {
    this.showSpinner = state;
    return Promise.resolve(state);
  }
  onExportApiCall(obj: any) {
    if (this.userOperationForm.invalid) {
      return;
    }
    const newArray = [];
    const translations = this.getTranslations();
    this._ParcelService.fetchUserOperation(obj).subscribe((res: any) => {
      res.forEach(element => {
        const newObj = this.mapUserOperationData(element, translations);
        if (newObj) {
          newArray.push(newObj);
        }
      });
      if (newArray.length > 0) {
        const datevar = moment().tz('Europe/Berlin').format('DD-MM-YYYY');
        this.exportToExcel(newArray, 'user_operation_report_' + datevar);
        this.noFound = '';
      } else {
        this.handleNoRecordsFound();
      }
    });
   }
   getTranslations() {
    return {
      lockerLocation: this._translate.instant('Locker location'),
      imageCaptureTimestamp: this._translate.instant('Image capture Timestamp'),
      downloadTimestamp: this._translate.instant('Download Timestamp'),
      userAccount: this._translate.instant('User Account'),
      Date: this._translate.instant('Date'),
      UserEmail: this._translate.instant('Email'),
      Name: this._translate.instant('Name'),
      Location: this._translate.instant('Location'),
      AcCode: this._translate.instant('AcCodes'),
      Barcode: this._translate.instant('Barcode'),
      Event_Type: this._translate.instant('Event Type')
    };
   }
  
   mapUserOperationData(element: any, translations: any) {
    const newObj: any = {};
    if (this.userOperationForm.controls.eventType.value === 'CAMERA') {
      newObj[translations.lockerLocation] = element.meta?.lockerName;
      newObj[translations.imageCaptureTimestamp] = element?.eventDate;
      newObj[translations.downloadTimestamp] = moment.utc(element.date).local().format('YYYY-MM-DD HH:mm:ss');
      newObj[translations.userAccount] = `${element.meta?.email},${element.meta?.uid}`;
    }
    if (this.userOperationForm.controls.eventType.value === 'LOCKER OPENED') {
      newObj[translations.Date] = element?.eventDate
        ? moment.utc(element.eventDate).local().format('YYYY-MM-DD HH:mm:ss')
        : '';
      newObj[translations.UserEmail] = element.meta?.email;
      newObj[translations.Name] = element.meta?.name;
      newObj[translations.Location] = element.meta?.lockerName;
      newObj[translations.AcCode] = element.meta?.lockerAcCode;
      newObj[translations.Barcode] = element.meta?.barcode;
      newObj[translations.Event_Type] = element?.event;
    }
    return Object.keys(newObj).length > 0 ? newObj : null;
   }
 
   handleNoRecordsFound() {
    this.noFound = 'NorecordsFound';
    this.selectFields = '';
    this.langaugeFetching(this.translateData, this.noFound);
   }
  onExport() {
    this.resetState();
    const obj = this.constructRequestObject();
    if (this.shouldCallApi(obj)) {
      this.onExportApiCall(obj);
    } else if (this.shouldShowSelectFields(obj)) {
      this.handleNoDateAndEventType();
    } else {
      this.handlePartialDateLogic(obj);
    }
   }

   resetState() {
    this.selectFields = '';
    this.noFound = '';
    this.isFromDate = false;
    this.isToDate = false;
   }
   constructRequestObject() {
    return {
      eventType: this.userOperationForm.controls.eventType.value,
      dateFrom: this._datePipe.transform(this.userOperationForm.controls['reportDate'].value.dateFrom, 'yyyy-MM-dd'),
      dateTo: this._datePipe.transform(this.userOperationForm.controls['reportDate'].value.dateTo, 'yyyy-MM-dd'),
    };
   }
   shouldCallApi(obj: any): boolean {
    return (obj.dateFrom && obj.dateTo) || obj.eventType;
   }

   shouldShowSelectFields(obj: any): boolean {
    return !obj.dateFrom && !obj.dateTo && !obj.eventType;
   }
   handlePartialDateLogic(obj: any) {
    if (!obj.dateFrom) {
      this.isFromDate = true;
      this.isToDate = false;
    } else if (!obj.dateTo) {
      this.isFromDate = false;
      this.isToDate = true;
    }
   }
 
   handleNoDateAndEventType() {
    this.isFromDate = false;
    this.isToDate = false;
    this.selectFields = 'selectFieldUserOperation';
    this.langaugeFetching(this.translateData, this.selectFields);
   }

  exportToExcel(data: any[], filename: string) {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, `${filename}.xlsx`);

  }

  getToday(): string {
    return new Date().toISOString().split('T')[0];
  }

  onReset() {
    this.isFromDate = false;
    this.isToDate = false;
    this.noFound ='';
    this.selectFields='';
    this.userOperationForm.reset();
    this.userOperationForm.controls['eventType'].setValue('');
    this.userOperationForm.updateValueAndValidity();
  }
  langaugeFetching(lang, key) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (this.noFound === 'NorecordsFound' && 
          this.selectFields !== 'selectFieldUserOperation') {
          this._toastr.warning(res);
          this.selectFields='';
        }
        if (this.noFound !== 'NorecordsFound' && 
          this.selectFields === 'selectFieldUserOperation') {
          this.noFound ='';
          this._toastr.error(res);
        }
      }
    );
  }
  openDatePicker(v) {
    if (v === 'dateFromInput'){
      this.isFromDate = false;
      this._renderer.selectRootElement(
        this.dateFromInput.nativeElement).click();
    }
    if (v === 'dateToInput'){
      this.isToDate = false;
      this._renderer.selectRootElement(this.dateToInput.nativeElement).click();
    }
  }
   dateRangeValidator: ValidatorFn = (control: AbstractControl): {
    [key: string]: any;
  } | null => {
  
    if(!this.userOperationForm)
      {
        return null;
      }
    let invalid = false;
    const from =control.value.dateFrom;
    const to =control.value.dateTo;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
}

