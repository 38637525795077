<app-loader *ngIf="showSpinner"></app-loader>
<app-header></app-header>
<div class="container">
    <br />
    <div class="row mt-3">
        <div class="col-3">
            <app-partner-info-menu [partner]=partnerDetails></app-partner-info-menu>
        </div>
        
        <div class="col-9 shadow p-3 mb-5 bg-body rounded px-5">
            <h2>Postman codes generation</h2>
            <form [formGroup]="settingForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                  <label for="exampleFormControlSelect1">Method to generate codes</label>
                  <select
                    formControlName="method"
                    class="form-select">
                        <option value="standard">Standard: each postman have unique credentials.</option>
                        <option value="bh-postalcode">B&H: credentials are based on postal code</option>
                  </select>
                </div>
                <div class="py-3">
                    <button class="btn btn-secondary  my-2" (click)="goToPartnerDetails()">{{'Back'|translate}}</button>&nbsp;
                <button type="submit" class="btn btn-danger my-2">Submit</button>
                </div>
              </form>
        </div>
    </div>
</div>