<app-header></app-header>
<app-loader *ngIf="showSpinner"></app-loader>
<app-lockers-menu [id]="id"></app-lockers-menu>
<br>
<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      
   
      <form class="px-4" [formGroup]="DpSettingsForm" (ngSubmit)="onSubmit()">
        <div class="flex items-center justify-start">
          <input type="checkbox" id="checkbox" class="mx-2 checkbox" formControlName="lidl_image">
          <label for="checkbox" class="h5">Lidl_image</label>
          <br>
            <button type="submit" class="btn btn-danger m-2" >{{'SAVE'|translate}}</button>
        </div>
      </form>
</div>
</div>
</div>
