<app-header></app-header>
<img src="/assets/img/bg2.jpg" class="bg2" alt="background image">
<br>
<div *ngIf="showLoader" class="text-center">
    <div class="text-center">
        <app-loader></app-loader>
    </div>
</div>

<form [formGroup]="addUserForm" *ngIf="groups" class="px-4 mt-4" [appViewController]="{users:'addUser'}">
   
    <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mx-auto">
        <div class="card shadow p-3 mb-5 bg-white rounded w-100 m-2">
            <div class="card-body p-3">
                <div>
                    <div class="col text-right">
                        <h2 class="card-title col text-center">{{'Add User'|translate}}</h2>
                    </div>
                </div>
                <div class="col-12 text-secondary">
                    <div class="row mb-3">
                        <div class="col-3">
                            <label class="form-label" for="userType">{{'Type'|translate}}</label>
                        </div>
                        <div class="col-9">
                            <select class="form-control form-select" name="type" formControlName="userType" [(ngModel)]="selectedUserType" (change)="onTypeChange()">
                                <option value="internal">Internal</option>
                                <option value="external">External</option>
                            </select>
                        </div>
                    </div>
                <div >
                    <div class="row mb-3">
                        <div class="col-3">
                            <label class="form-label required" for="userfirstName">{{'First name'|translate}}</label>
                        </div>
                        <div class="col-9">
                            <input 
                                type="text" name="userfirstName" class="form-control" formControlName="userfirstName"  id="userfirstName"
                                placeholder="{{'enter first name'|translate}}">
                            <span class="help-block">
                                <span *ngIf="hasErrors && addUserForm.controls.userfirstName.errors">
                                    <div *ngIf="addUserForm.controls.userfirstName.errors.required"
                                        class="error-text text-danger mt-1 text-start">{{'FirstName is required'|translate}}</div>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                <div >
                    <div class="row mb-3">
                        <div class="col-3">
                            <label class="form-label required" for="userlastName">{{'Last name'|translate}}</label>
                        </div>
                        <div class="col-9">
                            <input 
                                type="text" name="userlastName" class="form-control"  id="userlastName"
                                placeholder="{{'enter last name'|translate}}" formControlName="userlastName">
                            <span class="help-block">
                                <span *ngIf="hasErrors && addUserForm.controls.userlastName.errors">
                                    <div *ngIf="addUserForm.controls.userlastName.errors.required"
                                        class="error-text text-danger mt-1 text-start">{{'LastName is required'|translate}}</div>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
                    <div class="row mb-3">
                        <div class="col-3">
                            <label class="form-label required" for="userlastName">{{'Email'|translate}}</label>
                        </div>
                        <div class="col-9">
                            <input [ngClass]="F.userEmail.errors?.required  &&  F.userEmail.touched ? 'error':'' "
                                type="email" name="email" ngModel [email]="true" class="form-control"
                                formControlName="userEmail" id="userEmail" placeholder="eg: user@bpost.be">
                            <span class="help-block">
                                <span *ngIf="hasErrors && addUserForm.controls.userEmail.errors">
                                    <div *ngIf="addUserForm.controls.userEmail.errors.required"
                                        class="error-text text-danger mt-1 text-start">{{'email is required'|translate}}</div>
                                    <div *ngIf="addUserForm.controls.userEmail.errors.pattern"
                                        class="error-text text-danger mt-1 text-start">{{'please enter a valid email address'|translate}}</div>
                                </span>
                            </span>
                        </div>
                    </div>
                   
                <div class="row mb-3">
                    <div class="col-3">
                        <label class="form-label" for="usermobileNumber">{{'Mobile number'|translate}}</label>
                    </div>
                    <div class="col-9">
                        <input 
                            type="text" name="usermobileNumber"  class="form-control"
                            formControlName="usermobileNumber" id="usermobileNumber" placeholder="enter mobile number" maxlength="15" (keypress)="restrictAlphabets($event)">
                        <span class="help-block">
                            <span *ngIf="hasErrors && addUserForm.controls.usermobileNumber.errors">
                                <div *ngIf="addUserForm.controls.usermobileNumber.errors.pattern"
                                    class="error-text text-danger mt-1 text-start">{{'please enter a valid mobile number'|translate}}</div>
                            </span>
                        </span>
                    </div>
                </div>
                    <div class="row mb-3">
                        <div class="col-3">
                            <label class="form-label required" for="partner">{{'Partner'|translate}}</label>
                        </div>
                        <div class="col-9">
                            <ng-multiselect-dropdown formControlName="partner" id="partner" class="radio-check"
                            [settings]="partnerDropdownSettings" [placeholder]="'-All-'|translate" [data]="partnersList"
                                [(ngModel)]="partnerSelectedItems" 
                                ></ng-multiselect-dropdown>
                            <span class="help-block">
                                <span *ngIf="hasErrors && addUserForm.controls.partner.errors">
                                    <div *ngIf="addUserForm.controls.partner.errors.required"
                                        class="error-text text-danger mt-1 text-start">
                                        {{'partnerRequired'|translate}}
                                    </div>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-3">
                            <label class="form-label required" for="userGroups">{{'Groups'|translate}}</label>
                        </div>
                        <div class="col-9">
                            <ng-multiselect-dropdown
                                formControlName="userGroups" id="userGroups"
                                [placeholder]="'-All-'|translate"
                                [settings]="userGroupdropdownSettings" [data]="userGroupdropdownList"
                                [(ngModel)]="userGroupselectedItems">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <div class="text-center">
                        <button class="btn btn-danger mx-2" type="submit"
                            (click)="onSubmit()">{{'Add'|translate}}</button>
                            <button class="btn btn-outline-danger ms-3" (click)="cancel()">{{'Cancel'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>