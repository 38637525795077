import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartnerService } from 'src/app/Service/partner.service';
import { ParcelService } from 'src/app/Service/parcel.service';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LangtransService } from 'src/app/Service/langtrans.service';
import { AppService } from 'src/app/Service/app.service';
import { SpinnerService } from 'src/app/Service/spinner.service';
import * as moment from 'moment';

@Component({
  selector: 'app-partner-orderto-process',
  templateUrl: './partner-orderto-process.component.html'
})
export class PartnerOrdertoProcessComponent implements OnInit {
  orderToProcess: any;
  translateData: any;
  orderFound = true;
  constructor(
    private readonly _partnerservice: PartnerService,
    private readonly _router: Router,
    private readonly _ParcelService: ParcelService,
    private readonly _toastr: ToastrService,
    private readonly _langtransService: LangtransService,
    private readonly _appService: AppService,
    private readonly _spinner: SpinnerService

  ) { }

  ngOnInit(): void {
    this.getpartnerOrderToProcess();
    this._appService.languagetoggle$.subscribe((data: any) => {
      this.translateData = data;
    });
  }

  getpartnerOrderToProcess() {
    this._spinner.show();
    this._partnerservice.getPartnerOrderToProcess()
      .pipe(map((orders: Array<any>) => {
        this._spinner.hide();
        return orders.map((order: any) => {
          const retObj = { ...order };
          retObj.reservePeriodFrom = order.reservePeriodFrom ? 
            order.reservePeriodFrom.split('Z').join('') : '';
          retObj.reservePeriodTo = order.reservePeriodTo ? 
            order.reservePeriodTo.split('Z').join('') : '';
            const loadedAt = order.loadedAt ?
            moment(order.loadedAt.split('Z').join('')).format('MM-DD-YYYY HH:mm:ss') : '';
          retObj.loadedAt = loadedAt !== ''? moment.utc(loadedAt).local().format('DD-MM-YYYY HH:mm:ss') : '';
          return retObj;
        });
      }))
      .subscribe({
        next:(res) => {
        if (res.length === 0) {
          this.orderFound = false;
        }
        else {
          this.orderFound = true;
        }
        this.orderToProcess = res;
      },
       error:(err) => {
        console.error(err);
        this._spinner.hide();
      },
      complete:()=>{

      }
    });
  }
  viewPage(obj) {
    this._partnerservice.setEmail(obj.email);
    this._partnerservice.setEdit(obj);
    this._router.navigate(["/partnertools/partnerOrder/process", +obj.id]);

  }
  removeOrder(val) {
    const cancelOrderBody = {
      action: 'CANCEL'
    };
    this._spinner.show();
    this._ParcelService.postRetrievalCode(
      val.reserveid, cancelOrderBody).subscribe({
     next: (res) => {
        this._spinner.hide();
        if (res) {
          const index = this.orderToProcess.indexOf(val);
          if (index !== -1) {
            this.orderToProcess.splice(index, 1);
          }
          this.langaugeFetch(this.translateData, 'ParcelRemoved', 'success');
        }
      },
      error:(err) => {
        this._spinner.hide();
        console.error(err);
        this.langaugeFetch(this.translateData, 'FailedRemove', 'error');

      },
      complete:()=>{}
    }
    );
  }
  langaugeFetch(lang, key, toastrstatus) {
    this._langtransService.TranslateData(lang, key).subscribe(
      res => {
        if (toastrstatus === 'success') {
          this._toastr.success(res);
        }
        if (toastrstatus === 'error') {
          this._toastr.error(res);
        }
      }
    );
  }
}
