import { Injectable } from '@angular/core';
import { ddsEnvironment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  public emailSubject = new BehaviorSubject<any>('');
  public editSubject = new BehaviorSubject<any>('');
  constructor(private readonly _http: HttpClient) {


  }

  downloadAll(query) {
    if (query.partnerId && query.partnerId.length > 0) {
      return this._http.get(`${ddsEnvironment.baseUrl}/partners?output=csv-download&partnerId=${query.partnerId}`);
    } else {
      return this._http.get(`${ddsEnvironment.baseUrl}/partners?output=csv-download`);
    }
  }

  setEmail(email: any) {
    this.emailSubject.next(email);
  }

  setEdit(res: any) {
    this.editSubject.next(res);
  }

  fetchPartnersList() {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/partners`);
  }

  fetchAndFilterPartnersList(query) {
    if (query.partnerId && query.partnerId.length > 0) {
      return this._http.get(
        `${ddsEnvironment.baseUrl}/partners?partnerId=${query.partnerId}`);
    } else {
      return this._http.get(
        `${ddsEnvironment.baseUrl}/partners`);
    }
  }

  fetchPartnerDetails(id) {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/partners/${id}`);
  }

  fetchPartnerDeliverypointDetails(id) {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/partners/${id}/deliverypoints`);
  }

  saveNewpartnerOrder(body,orderType) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/partnertool/${orderType}`, body);
  }
  saveSettingInfo(body) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/partnertool/savepartnercustomerdefaults`, body);
  }

  getPartnerInfo() {
    return this._http.get(`${ddsEnvironment.baseUrl}/partnertool/getpartnerorders`);
  }

  getsettingInfo() {
    return this._http.get(`${ddsEnvironment.baseUrl}/partnertool/getpartnercustomerdefaults`);
  }

  getPartnerVault() {
    const obj = { currentStatus: 'vault' };
    return this._http.get(`${ddsEnvironment.baseUrl}/partnertool/getpartnerorders`, { params: obj });
  }

  getPartnerOrderToProcess() {
    const obj = { currentStatus: 'process' };
    return this._http.get(`${ddsEnvironment.baseUrl}/partnertool/getpartnerorders`, {params: obj });
  }

  getPartnerOrderHistory() {
    const obj = { currentStatus: 'history' };
    return this._http.get(`${ddsEnvironment.baseUrl}/partnertool/getpartnerorders`, {params: obj });
  }

  updatePartnerDeliveryPoints(id, body) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/partners/${  id  }/deliverypoints`, body);
  }
  fetchMaintenanceUser(limit,offset) {
    return this._http.get(`${ddsEnvironment.baseUrl}/maintenance/users?limit=${limit}&offset=${offset}&output=json`,{
      observe: 'response'
    });
  }
  fetchMaintenanceUserById(id) {
    return this._http.get(`${ddsEnvironment.baseUrl}/maintenance/users/${id}`);
  }
  createMaintenanceUser(data) {
    return this._http.post(`${ddsEnvironment.baseUrl}/maintenance/createuser`, data);
  }
  deleteMaintenanceUser(data) {
    return this._http.delete(`${ddsEnvironment.baseUrl}/maintenance/deleteuser`, { params: data });
  }
  updateMaintenanceUser(data) {
    return this._http.patch(`${ddsEnvironment.baseUrl}/maintenance/createuser`, data);
  }

  addNewPartner(data) {
    return this._http.post(`${ddsEnvironment.baseUrl}/partners/add`, data);
  }

  editPartnerSetting(id, setting, data) {
    return this._http.post(`${ddsEnvironment.baseUrl}/partners/${id}/setting/${setting}`, data);
  }

  editPartnerSettings(id, settings) {
    return this._http.post(`${ddsEnvironment.baseUrl}/partners/${id}/setting`, settings);
  }

  editPartner(data) {
    return this._http.post(`${ddsEnvironment.baseUrl}/partners/edit`, data);
  }
  addCustomer(data) {
    return this._http.post(`${ddsEnvironment.baseUrl}/partnertool/customer/addcustomers`, data);
  }
  getCustomer(){
    return this._http.get(`${ddsEnvironment.baseUrl}/partnertool/customer/getcustomer`);
  }
  
  fetchDriverDetails(id) {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/partners/${id}/drivers`);
  }
  addPartnerPostmanSchemas(id, body) {
    return this._http.put(
      `${ddsEnvironment.baseUrl}/partners/${  id  }/drivers`, body);
  }
  updatePartnerPostmanSchemas(id, body,driverId) {
    return this._http.post(
      `${ddsEnvironment.baseUrl}/partners/${  id  }/drivers/${  driverId}`, body);
  }
  deletePartnerPostmanSchemas(id,body) {
    return this._http.delete(
      `${ddsEnvironment.baseUrl}/partners/${  id  }/drivers/${  body.id}`, body);
  }
  getPartnerCustomer(barcode){
    const obj = {barcode:barcode};
    return this._http.get(`${ddsEnvironment.baseUrl}/partnertool/customer/getcustomer`,{params:obj});
  }
  
  fetchPartnerDeliverypointDetailswithPartnerId(partnerId, params) {
    return this._http.get(
      `${ddsEnvironment.baseUrl}/partners/${partnerId}/deliverypoints`,{params:params});
  }
}