import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appLengthLimit]'
})
export class LengthLimitDirective {
  constructor(private readonly _el: ElementRef) { }
  
  @HostListener('input', ['$event']) onInput(event: any): void {
    const initialValue: string = this._el.nativeElement.value;
    const cleanValue: string = initialValue.replace(/\D/g, '').slice(0, 3);
    this._el.nativeElement.value = cleanValue;
    event.preventDefault();
  }
}
