<app-header></app-header>
<div class="container mb-2 mt-2">
<div
  class="col-12 d-flex justify-content-end">
    <button
      type="button"
      class="btn btn-danger me-3 mt-2"
      [appViewController]="{staff:'createStaff'}"
      (click)="addMaintenanceUser()">{{'Add' |translate}}</button>
</div>
</div>
<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="table-responsive" id="staffListTable">
        <table aria-describedby="staffListTable" datatable  [dtOptions]="dtOptions" class="table table-sm table-bordered table-hover row-border hover text-center" >
          <thead class="table-dark">
            <tr>
              <th scope="col">{{'Name' |translate}}</th>
              <th scope="col">{{'Login ID' |translate}}</th>
              <th scope="col">{{'Last logged-in time' |translate}}</th>
              <th scope="col">{{'Capabilities' |translate}}</th>
              <th scope="col">{{'Language' |translate}}</th>
              <th scope="col">{{'Action' | translate}}</th>
            </tr>
          </thead>
          <tbody *ngIf="partnerList?.length != 0">
            <tr *ngFor="let partner of partnerList">
              <td>{{partner.name}}</td>
              <td>{{partner.loginId}}</td>
              <td *ngIf="partner.lastLoginTime">{{partner.lastLoginTime | date:'YYYY-MM-dd HH:mm:ss'}}</td>
              <td *ngIf ="!partner.lastLoginTime" class="text-center"></td>
              <td>{{partner.capabilities}}</td>
              <td  class="text-capitalize">{{partner.langDesc}}</td>
              <td>
                <button [appViewController]="{staff:'editStaff'}" type="button" class="btn btn-success mr-2 px-4" (click)="onEdit(partner)">{{'Edit' |translate}}</button>
                <button [appViewController]="{staff:'deleteStaff'}" data-toggle="modal" data-target="#deleteUserModal" type="button"
                class="btn btn-danger" data-toggle="modal"
                  (click)="getModalId(partner.id,partner.name)">{{'Delete' |translate}}</button>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="partnerList?.length == 0">
            <tr>
              <td colspan="6" class="no-data-available">{{'No data!' | translate}}</td>
            </tr>
            </tbody>
        </table>
      </div>
    </div>

    <div class="modal fade" tabindex="-1" id="deleteUserModal" role="dialog" aria-labelledby="deleteUserModal"
      aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title mb-0">{{'Delete' |translate}}</h3>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div class="modal-body">
            <p>{{'Are you sure you want to delete the staff user name' |translate}} {{partnerName}} ?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{'Cancel' |translate}}</button>
            <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="onDelete()">{{'Confirm' |translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
 