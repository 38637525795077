<app-header></app-header>
<img src="/assets/img/bg2.jpg" class="bg2" alt="background image">

<div class="container pt-5">
    <div class="row">
        <div class="col-2"></div> 
        <div class="col-8 card" [appViewController]="{staff:'postmanCode'}">
    <form [formGroup]="postmanLoginForm" class="container mb-6 mt-4">
        <h2 class="card-title text-center">{{'Staff Login Code'|translate}}</h2>

        <div class="form-group ">
            <div class="row mb-2">
                <label class="required col-3" for="Capabilities">{{'Capabilities'|translate}}</label>
                <div class="col-9">
                    <app-multi-select-dropdown [list]="list"
                        (shareCheckedList)="shareCheckedList($event)">
                    </app-multi-select-dropdown>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3"></div>
                <div *ngIf="!dropSuccess" class="form-text text-danger col-9">{{'Please select Capabilities'|translate}}</div>
            </div>
        </div>
            <div class="row mb-2">
                <label class="required col-3 form-label" for="Name">{{'Name'|translate}}</label>
                <div class="col-9">
                    <input type="text" formControlName="name" class="form-control" id="Name"
                        aria-describedby="nameHelp" placeholder="{{'Enter Name'|translate}}" />
                </div>
            </div>
        <div class="row mb-2">
                <div class="col-3"></div>
                <div *ngIf="submitted &&  formValidators.name.errors?.required"
                    class="form-text text-danger col-9">{{'Please Fill Name Field'|translate}}</div>
        </div>
            <div class="row mb-2">
                <label class="required col-3 form-label" for="matriculeID">{{'Matricule ID'|translate}}</label>
                <div class="col-9">
                    <input type="text" formControlName="matriculeID" class="form-control" 
                    id="matriculeID" placeholder="{{'Matricule ID'|translate}}"
                    #matriculeID (input) ="matriculeID.value = matriculeID.value.toUpperCase()"
                    pattern="[A-Za-z0-9]*">
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3"></div>
                <div *ngIf="submitted &&  formValidators.matriculeID.errors?.required"
                    class="form-text text-danger col-9">{{'Please Fill Matricule ID'|translate}}</div>
                <div *ngIf="postmanLoginForm.controls.matriculeID.errors?.pattern"class="form-text text-muted col-sm-8 text-danger">
                {{ 'Matricule ID must be alphanumeric' | translate }}</div>
            </div>
            <div class="row mb-2">
                <label class="required col-3 form-label" >{{'Language'|translate}}</label>
                <div class="col-9">
                    <select name="language" class="form-select" formControlName='language'>
                        <option value="" disabled selected>{{'Choose an option' | translate}}</option>
                        <option *ngFor="let item of languageList"
                            [value]="item.value"
                            class="text-capitalize">{{item.label}}</option>
                    </select>
                </div>
                <div class="row mb-2">
                    <div class="col-3"></div>
                    <div *ngIf="submitted && formValidators.language.errors?.required"
                     class="form-text text-danger col-9">{{'Please select'|translate}}</div>
                </div>
            </div>
            <div class="row mb-2">
                <label class="required col-3" for="dpId">{{'Delivery Points'|translate}}</label>
                <div class="col-9">
                    <ng-multiselect-dropdown
                        class="form-control"
                        formControlName="dpId"
                        [placeholder]="'-All-'|translate"
                        [settings]="lockerDropdownSettings"
                        [data]="dpList"
                        [(ngModel)]="lockerSelectedItems">
                    </ng-multiselect-dropdown>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-3"></div>
                <div
                    *ngIf="submitted && formValidators.dpId.errors?.required"
                    class="form-text text-danger col-9">{{'Please select Delivery points'|translate}}</div>
            </div>
        <div class="py-2"></div>
        <div class="text-center">
            <button class="btn btn-danger mb-4" (click)="submitData()">{{'Submit'|translate}}</button>
        </div>
    </form>
    <div *ngIf="tableData && tableData.length > 0" class="container">
        <table class="table">
            <caption></caption>
            <thead>
            <tr>
                <th scope="col">{{'AC Code'|translate}}</th>
                <th scope="col">{{'Code'|translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of tableData">
                <td>{{data.acCode}}</td>
                <td>{{data.code || ''}}</td>
            </tr>
            </tbody>
        </table>
    </div>
        </div> 
    </div>
</div>
<div>
