<div class="mx-5 mt-5"  [appViewController]="{partnerTool:'viewPartnerTool'}">
    <h3 class="text-danger text-center">{{'createNewOrder'|translate}}</h3>

    <div class="mt-5 mx-auto card w-75">
        <div class="card-body" >
            <form [formGroup]="newOrderForm">

                  <div class="form-group row mb-2">
                    <label for="staticEmail" class="col-3 col-form-label">{{'Email'|translate}}</label>
                    <div class="col-9">
                      <input type="text"  class="form-control" id="E-mail" formControlName="email">
                      <small *ngIf="submitted &&  formValidators.email.errors?.required"
                        class="form-text text-danger">{{'Email is a required field'|translate}}</small>
                        <small *ngIf="submitted &&  formValidators.email.errors?.email"
                        class="form-text text-danger">{{'Email format incorrect'|translate}}</small>
                    </div>
                  </div>
                  <div class="form-group row mb-2">
                      <label for="staticEmail" class="col-3 col-form-label">{{'Confirm email address'|translate}}</label>
                      <div class="col-9">
                        <input type="text"  class="form-control" id="Confirm E-mail" formControlName="emailConfirm">
                        <small *ngIf="submitted &&  formValidators.emailConfirm.errors?.required"
                        class="form-text text-danger">{{'Confirm email is a required field'|translate}}</small>
                        <small *ngIf="submitted &&  formValidators.emailConfirm.errors?.email"
                        class="form-text text-danger">{{'Email format incorrect'|translate}}</small>
                      </div>
                    </div>
            <div class="text-center">
              <button type="submit" class="btn btn-danger" (click)="partnerOrder()">{{'saveData'|translate}}</button>
          </div>
            </form>
        </div>

    </div>
</div>
