<app-header></app-header>
<img src="/assets/img/bg2.jpg" class="bg2" alt="background image">
<div [appViewController]="{report:'downTimeReport'}">

  <form [formGroup]="lockerdownForm" class="container mat-card mb-6 mt-4 p-3 shadow bg-white rounded">
    <p class="h4 text-center">{{'Downtime Report'|translate}}</p>
    <div class="row px-4">
      <div class="col-4">
        <label class="form-label">{{'Location'|translate}}</label>
        <ng-multiselect-dropdown class="form-control-sm" formControlName="dropDown" [placeholder]="'-All-'|translate"
          [settings]="locationDropdownSetttings" [data]="locationdropdownList" [(ngModel)]="locationSelectedItems"
          (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
      </div>
      <div formGroupName="logDates" class="col-8">
        <div class="row">
          <div class="col">
            <label for="orderDateFrom" class="form-label">{{'Log date from'|translate}}</label>
            <div class="input-group">
              <input type="text" placeholder="dd-mm-yyyy" class="form-control" formControlName="logDateFrom"
                [maxDate]="maxDate" #logDateFromInput bsDatepicker (ngModelChange)="openDatePicker('logDateFromInput')"
                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
              <span class="input-group-text">
                <i class="fas fa-calendar-alt" id="fromDate" (click)="openDatePicker('logDateFromInput')"></i>
              </span>
            </div>
            <div class="col-12 text-center">
              <small *ngIf="isFromDate" class="form-text text-danger" style="padding-left: 0px;">{{'Please Fill To
                Date'|translate}}</small>
            </div>
          </div>


          <div class="col">

            <label for="orderDateTo" class="form-label">{{'Log date to'|translate}}</label>
            <div class="input-group">
              <input type="text" placeholder="dd-mm-yyyy" class="form-control" formControlName="logDateTo"
                [maxDate]="maxDate" #logDateToInput bsDatepicker (ngModelChange)="openDatePicker('logDateToInput')"
                [bsConfig]="{ isAnimated: true,showWeekNumbers:false,dateInputFormat:'DD-MM-YYYY'}">
              <span class="input-group-text">
                <i class="fas fa-calendar-alt" id="toDate" (click)="openDatePicker('logDateToInput')"></i>
              </span>
            </div>

            <div class="col-12 text-center">
              <small *ngIf="isToDate" class="form-text text-danger" style="padding-left: 0px;">{{'Please Fill To
                Date'|translate}}</small>
            </div>
            <small *ngIf="formValidators.logDates && formValidators.logDates.invalid"
              class="form-text text-muted text-danger">{{'Please add a valid from and to date'|translate}}</small>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col text-center py-2">
        <button class="btn btn-danger me-3" type="submit" id="exportBtn"
          (click)="onExport()">{{'Export'|translate}}</button>
        <button class="btn btn-outline-danger" id="resetBtn" (click)="onReset()">{{'Reset'|translate}}</button>
      </div>
    </div>
  </form>
</div>