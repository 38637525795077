<app-loader *ngIf="showSpinner"></app-loader>
<app-header></app-header>
<div class="container">
  <br />
  <div class="row">
    <div class="col-3">
      <app-partner-info-menu [partner]=partnerDetails></app-partner-info-menu>
    </div>
 
    <div class="col-9 shadow p-3 mb-5 bg-body rounded px-5">
      <h2>{{'Times' | translate}}</h2>
      <form [formGroup]="settingForm" (ngSubmit)="onSubmit()">
        <div class="row mb-2">
          <div class="form-group">
            <label for="dwell">{{'DWELL time' | translate}} ({{'minutes' | translate}})</label>
            <input formControlName="dwell" class="form-control" />
          </div>
          <small class="row">
            <small *ngIf="submitted &&  formValidators.dwell.errors?.onlyNumber"
              class="form-text text-muted col-sm-8">{{'Please provide valid number' | translate}}
            </small>
          </small>
        </div>
        <div class="row mb-2">
          <div class="form-group">
            <label for="FM_RESERVATION_LIMIT">{{'FirstMile reservation limit' | translate}} ({{'minutes' |
              translate}})</label>
            <input formControlName="FM_RESERVATION_LIMIT" class="form-control" />
          </div>
          <small class="row">
            <small *ngIf="submitted &&  formValidators.FM_RESERVATION_LIMIT.errors?.onlyNumber"
              class="form-text text-muted col-sm-8">{{'Please provide valid number' | translate}}
            </small>
          </small>
        </div>
        <div class="row mb-4">
          <div class="form-group">
            <label for="LM_RESERVATION_LIMIT">{{'LastMile reservation limit' | translate}} ({{'minutes' |
              translate}})</label>
            <input formControlName="LM_RESERVATION_LIMIT" class="form-control" />
          </div>
          <small class="row">
            <small *ngIf="submitted &&  formValidators.LM_RESERVATION_LIMIT.errors?.onlyNumber"
              class="form-text text-muted col-sm-8">{{'Please provide valid number' | translate}}
            </small>
          </small>
        </div>
       <div class="pb-2">
        <button class="btn btn-secondary me-3" (click)="goToPartnerDetails()">{{'Back'|translate}}</button>

        <button type="submit" class="btn btn-danger">Submit</button>
       </div>
      </form>
    </div>
  </div>
</div>