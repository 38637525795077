import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PartnerService } from '../../../Service/partner.service';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from 'src/app/Service/spinner.service';

@Component({
  selector: 'app-partner-edit-times',
  templateUrl: './partner-edit-times.component.html',
  styleUrls: ['./partner-edit-times.component.scss']
})
export class PartnerEditTimesComponent implements OnInit {

  showSpinner = false;
  partnerDetails: any;
  partnerId: any;
  settingForm: FormGroup;
  settingName = 'postmans-codes-method';
  submitted = false;
  constructor(public _activatedRoute: ActivatedRoute,
    private readonly _toastr: ToastrService,
    private readonly _partnerService: PartnerService,
    private readonly _router: Router,
    private readonly _formBuilder: FormBuilder,
    private readonly _translate: TranslateService,
    private readonly _spinnerService: SpinnerService
  ) {
    this.settingForm = this._formBuilder.group({
      dwell: [null, [this.validateNumberOnly]],
      FM_RESERVATION_LIMIT: [null, [this.validateNumberOnly]],
      LM_RESERVATION_LIMIT: [null, [this.validateNumberOnly]]
    })
  }

  ngOnInit(): void {
    const partnerId = this._activatedRoute.snapshot.params.id;
    this.partnerId = partnerId;
    this.getPartnerDetails(partnerId);
  }

  //Fech partner details
  getPartnerDetails(partnerId) {
    this.showSpinner = true;
    this._partnerService.fetchPartnerDetails(partnerId).subscribe({
      next:res => {
        this.partnerDetails = res;

        // Current setting value
        const dwell = this.partnerDetails.settings.find(
          s => s.key === 'DWELL_TIME');
        const fmRes = this.partnerDetails.settings.find(
          s => s.key === 'FM_RESERVATION_LIMIT');
        const lmRes = this.partnerDetails.settings.find(
          s => s.key === 'LM_RESERVATION_LIMIT');
        this.settingForm.patchValue({
          dwell: dwell?.value || null,
          FM_RESERVATION_LIMIT: fmRes?.value || null,
          LM_RESERVATION_LIMIT: lmRes?.value || null
        });

        this.showSpinner = false;
      },
     error: err => {
        this.showSpinner = false;
        this._toastr.error(this._translate.instant(
          'Failed to fetch partner details !!'),
        this._translate.instant('Failed'));
        console.error(err);
      },
      complete:()=>{}
  });
  }
  
  validateNumberOnly(ctrl: AbstractControl): ValidationErrors | null {
    const val = ctrl.value;
    if(/[^0-9]/.test(val) === true && val!==null) {
      return {
        onlyNumber: true
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.settingForm.valid) {
      this._spinnerService.show();
      this._partnerService.editPartnerSettings(this.partnerId, {
        DWELL_TIME: this.settingForm.controls.dwell.value,
        FM_RESERVATION_LIMIT: 
        this.settingForm.controls.FM_RESERVATION_LIMIT.value,
        LM_RESERVATION_LIMIT: 
        this.settingForm.controls.LM_RESERVATION_LIMIT.value
      }).subscribe({next:(res) => {
        this._spinnerService.hide();
        if (res) {
          this._toastr.success(this._translate.instant(
            'Partner Updated Successfully!'), 
          this._translate.instant('Success'));
        }
      }, error:(err: any) => {
        this._spinnerService.hide();
        console.error(err);
        this._toastr.info(err?.error?.error?.message, 'Failed');
      },
      complete:()=>{}
      });
    }
  }

  goToPartnerDetails() {
    this._router.navigate(['/partners/', this.partnerId]);
  }

  get formValidators() {
    return this.settingForm.controls;
  }
}
